import React from "react";
import ques from "../../images/question.png";
import "./Tooltip.css";

export const NatureBreezeTooltip = () => (
  <div className="tooltip-wrapper">
    <img className="tooltipIcon" src={ques} alt="" />
    <div className="tooltip-content">
      <p>
        The Envac nature breeze series is a low cost but effective ventilation
        and nature cooling system for residential, commercial and industrial
        applications. The system leverages on the fact that nighttime outdoor
        temperature is cooler than indoors night-time temperature. The system
        works by drawing cold filtered outside air into the house to replace the
        stale indoors air. The system consists of layers of filters to sort out
        outdoor pollutants like dust, black sooth, insects etc.
      </p>
    </div>
  </div>
);

export const EnvacWaterTooltip = () => (
  <div className="tooltip-wrapper">
    <img className="tooltipIcon" src={ques} alt="" />
    <div className="tooltip-content envac-water-heater">
      <p>
        The Envac Water Heater is a patented Thermal Energy Storage System
        (TESS) leveraging on the huge latent heat capacity of water to store
        energy. The System utilises waste heat of condensation from the AC or
        exhaust heat of fossil fueled generators to heat large quantities of hot
        water. The system consists of a hot water storage system and associated
        piping. Read More…
        <br />
        <br />
        <a
          target="_blank"
          href="https://enchillnigeria.com/envac-water-heater/"
          rel="noreferrer"
        >
          https://enchillnigeria.com/envac-water-heater/
        </a>
      </p>
    </div>
  </div>
);

export const EnvaStorezTooltip = () => (
  <div className="tooltip-wrapper">
    <img className="tooltipIcon" src={ques} alt="" />
    <div className="tooltip-content">
      <p>
        The Envac Storez is a patented Thermal Energy Storage System (TESS)
        leveraging on the huge latent heat capacity of water to store cold
        energy. The system consists of an outdoor ice storage system (Ice
        battery) and an Indoor air conditioner(s). Read More…
        <br />
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://enchillnigeria.com/envac-storez-ice-battery-air-conditioning-system/"
        >
          https://enchillnigeria.com/envac-storez-ice-battery-air-conditioning-system/
        </a>
      </p>
    </div>
  </div>
);

const Tooltip = ({ tipContent }) => (
  <div className="tooltip-wrapper">
    <p>Icon</p>
    <div className="tooltip-content">
      <p>{tipContent}</p>
    </div>
  </div>
);

export default Tooltip;
