import React from "react";
import { useForm } from "react-hook-form";
import Header from "../../Components/Header/Header";
import RightGrid from "../../Components/RightGrid/RightGrid";

const ForgotPassword = ({ role, notify, setLoading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const onSubmitEmail = formData => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/forgotPassword`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
        role
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          notify("success", data.message);
          setLoading(false);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "An error occured. Unable to reach server");
        setLoading(false);
      });
  };

  return (
    <div className="FirstPage LoginPage">
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div className="login-header">
            <p className="login-header-text">Forgot Password</p>
          </div>
          <form
            className="form-container"
            onSubmit={handleSubmit(onSubmitEmail)}
          >
            <div className="form-input-wrapper">
              <div className="container">
                <input
                  className={
                    errors.email ? "form-input-error" : "form-input-tile"
                  }
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  {...register("email", {
                    required: true
                  })}
                />
              </div>
              <br />
              {errors.email && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="form-submit-btn">
              <button type="submit" style={{ marginLeft: 0 }}>
                Submit
              </button>
            </div>
            <div className="form-gotologin">
              <a href="/login">Go to Login</a>
            </div>
          </form>
        </div>
        <RightGrid />
      </main>
    </div>
  );
};

export default ForgotPassword;
