import React, { useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";

function AddLead({ setLoading, notify, refreshPage }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onRegister = formData => {
    setLoading(true);

    return fetch(`${process.env.REACT_APP_API_URL}/user/preleads`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(formData)
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ paddingTop: "0px" }} className="RegisterPage">
      <main className="fp-grid">
        <div style={{ paddingTop: "0px" }} className="grid1">
          <Form onSubmit={handleSubmit(onRegister)} className="form-container">
            <h2 className="form-header">Add Lead</h2>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <input
                    className={
                      errors.firstname ? "form-input-error" : "form-input-tile"
                    }
                    name="firstname"
                    type="text"
                    {...register("firstname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.firstname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <input
                    className={
                      errors.lastname ? "form-input-error" : "form-input-tile"
                    }
                    name="lastname"
                    type="text"
                    {...register("lastname", {
                      required: false
                    })}
                  />
                  <br />
                  {errors.lastname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <input
                    className={
                      errors.email ? "form-input-error" : "form-input-tile"
                    }
                    name="email"
                    type="email"
                    {...register("email")}
                  />
                  <br />
                  {errors.email && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="phone_number">Phone Number</Label>
                  <input
                    className={
                      errors.phone_number
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="phone_number"
                    type="tel"
                    {...register("phone_number", {
                      minLength: 6,
                      maxLength: 12
                    })}
                  />
                  <br />
                  {errors.phone_number && (
                    <p className="formfeedback">Enter a valid phone number</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <input
                    className={
                      errors.address ? "form-input-error" : "form-input-tile"
                    }
                    name="address"
                    type="text"
                    {...register("address")}
                  />
                  <br />
                  {errors.address && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="awareness_means">
                    How did you get this customer?
                  </Label>
                  <select
                    className={
                      errors.awareness_means
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="awareness_means"
                    {...register("awareness_means", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Twitter">Twitter</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="LinkedIn">Google</option>
                    <option value="LinkedIn">Flyers</option>
                    <option value="Friend">Friend</option>
                    <option value="Phone Call">Phone Call</option>
                  </select>
                  <br />
                  {errors.awareness_means && (
                    <p className="formfeedback">Select an option</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="note">Add a note about this lead</Label>
                  <textarea
                    className={
                      errors.note ? "form-textarea-error" : "form-textarea-tile"
                    }
                    name="note"
                    {...register("note")}
                  />
                  <br />
                  {errors.note && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="form-submit-btn">
              <button type="submit">Complete</button>
            </div>
          </Form>
        </div>
      </main>
    </div>
  );
}

export default AddLead;
