import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./FirstPage.css";
import Header from "../../Components/Header/Header";
import NewExisting from "../../Components/NewExisting/NewExisting";
import RightGrid from "../../Components/RightGrid/RightGrid";
import RefineSelection from "../../Components/RefineSelection/RefineSelection";

function FirstPage({
  loggedIn,
  setLoading,
  pricebook,
  setPricebook,
  appliances,
  setAppliances,
  basicRecommended,
  setBasicRecommended,
  selectedPlan,
  setSelectedPlan,
  customFeedback,
  setCustomFeedback,
  setRecommendedSystem
}) {
  const [introPage, setIntroPage] = useState(true);

  const [showRefine, setShowRefine] = useState(false);

  const history = useHistory();

  const onCloseRefine = path => {
    setShowRefine(false);
    if (path) history.push("/custom-preview");
  };

  const onDesignSolution = () => {
    setLoading(true);
    setSelectedPlan("CUSTOM");
    setCustomFeedback({
      battery_replacement_package: "Not Included",
      quarterly_maintenance_package: "Not Included",
      extended_warranty_package: "Not Included",
      solar_panels: "No Solar"
    });
    fetch(`${process.env.REACT_APP_API_URL}/solution/customSolution`)
      .then(res => res.json())
      .then(data => {
        setPricebook(data.responseData.pricebook);

        const selectedAppliances = data.responseData.appliances.map(
          appliance => ({
            ...appliance,
            checked: Boolean(appliance.custom_quantity)
          })
        );

        setAppliances(selectedAppliances);

        setBasicRecommended(data.responseData.basicRecommendationProducts);

        window.gtag("event", "view_solution_engine", {
          event_category: "solution"
        });

        setShowRefine(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIntroPage(!sessionStorage.getItem("noIntroPage"));
    if (loggedIn && history.location.type !== "edit") {
      history.push("/landing-dashboard");
    }
  }, []);

  return (
    <div className={introPage ? "introPage" : "FirstPage"}>
      {showRefine && (
        <RefineSelection
          onClose={onCloseRefine}
          userCustomFeedback={customFeedback}
          setUserCustomFeedback={setCustomFeedback}
          setRecommendedSystem={setRecommendedSystem}
          appliances={appliances}
          setAppliances={setAppliances}
          pricebook={pricebook}
          basicRecommended={basicRecommended}
          selectedPlan={selectedPlan}
        />
      )}
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div>{!loggedIn && <NewExisting selected="new" />}</div>
          <div className="fp-solution">
            <div className="fp-custom-solution">
              <h3 className="Design-Custom-Solution">Design Custom Solution</h3>
              <span onClick={onDesignSolution} className="fp-select">
                Select
              </span>
            </div>
            <div className="fp-standard-solution">
              <h3 className="Standard-Solution">Standard Solution</h3>
              <a href="/standard-solution" className="fp-select">
                Select
              </a>
            </div>
          </div>
          <div className="get-started-video">
            <iframe
              width="420"
              height="315"
              title="Getting Started"
              src="https://icevolt.ca/wp-content/uploads/2022/01/IceVolt-Intro-Video.mp4"
            />
          </div>
        </div>
        <RightGrid setIntroPage={setIntroPage} />
      </main>
    </div>
  );
}
export default FirstPage;
