import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const AdminSettings = ({ adminDetails, notify, setLoading, refreshPage }) => {
  const [profilepic, setProfilepic] = useState({
    string: adminDetails.profilepic,
    file: null
  });

  const [visiblePassword, setVisiblePassword] = useState({
    old: false,
    new: false,
    confirm: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 }
  } = useForm();

  const password = useRef({});
  password.current = watch("new_password", "");

  const onSelectProfilepic = e => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProfilepic({
        string: reader.result,
        file
      });
    };
  };

  const setPasswordVisibility = passClicked => {
    setVisiblePassword({
      ...visiblePassword,
      [passClicked]: !visiblePassword[passClicked]
    });
  };

  const updateProfile = formData => {
    setLoading(true);
    const applicationData = {
      ...formData,
      email: adminDetails.email,
      profilepic: profilepic.file
    };

    const form_data = new FormData();

    Object.keys(applicationData).forEach(key => {
      form_data.append(key, applicationData[key]);
    });

    fetch(`${process.env.REACT_APP_API_URL}/admin/profile`, {
      method: "put",
      body: form_data
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  const onSetPassword = formData => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/admin/changePassword`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: adminDetails.email,
        oldPassword: formData.old_password,
        newPassword: formData.new_password,
        confirmPassword: formData.confirm_password
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  return (
    <div className="customer-info">
      <div className="landing-dashboard-user-personal">
        <div
          className="landing-dashboard-user-img"
          style={{
            backgroundImage: `url(${profilepic.string})`,
            backgroundSize: "cover"
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={onSelectProfilepic}
            className="landing-dashboard-user-img-icon"
          />
        </div>
        <div className="landing-dashboard-user-info">
          <h3 className="landing-dashboard-user-name">
            {`${adminDetails.firstname} ${adminDetails.lastname}`}
          </h3>
          <p className="landing-dashboard-user-email">{adminDetails.email}</p>
        </div>
      </div>
      <form
        className="settings-form-container"
        onSubmit={handleSubmit2(updateProfile)}
      >
        <div className="settings-form-input-wrapper">
          <p className="settings-form-input-label">First Name</p>
          <input
            className={
              errors2.firstname
                ? "settings-form-input-error"
                : "settings-form-input-tile"
            }
            name="firstname"
            type="text"
            defaultValue={adminDetails.firstname}
            {...register2("firstname")}
          />
          <br />
          {errors2.firstname && (
            <p className="settings-formfeedback">{errors2.firstname.message}</p>
          )}
        </div>
        <div className="settings-form-input-wrapper">
          <p className="settings-form-input-label">Last Name</p>
          <input
            className={
              errors2.lastname
                ? "settings-form-input-error"
                : "settings-form-input-tile"
            }
            name="lastname"
            type="text"
            defaultValue={adminDetails.lastname}
            {...register2("lastname")}
          />
          <br />
          {errors2.lastname && (
            <p className="settings-formfeedback">{errors2.lastname.message}</p>
          )}
        </div>
        <div className="settings-form-submit-btn">
          <button type="submit">Save</button>
        </div>
      </form>
      <h4 className="customer-info-setion-header">Change Password</h4>
      <form
        className="settings-form-container"
        onSubmit={handleSubmit(onSetPassword)}
      >
        <div className="settings-form-input-wrapper">
          <p className="settings-form-input-label">Old Password</p>
          <input
            className={
              errors.old_password
                ? "settings-form-input-error"
                : "settings-form-input-tile"
            }
            name="old_password"
            type={visiblePassword.old ? "text" : "password"}
            {...register("old_password", {
              required: "Enter your old password"
            })}
          />
          <br />
          {errors.old_password && (
            <p className="settings-formfeedback">
              {errors.old_password.message}
            </p>
          )}
          <div
            className="settings-form-input-eye"
            onClick={() => setPasswordVisibility("old")}
          >
            {visiblePassword.old ? <AiFillEye /> : <AiFillEyeInvisible />}
          </div>
        </div>
        <div className="settings-form-input-wrapper">
          <p className="settings-form-input-label">New Password</p>
          <input
            className={
              errors.new_password
                ? "settings-form-input-error"
                : "settings-form-input-tile"
            }
            name="new_password"
            type={visiblePassword.new ? "text" : "password"}
            {...register("new_password", {
              required: "Enter a new password",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters"
              },
              pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                message:
                  "Passwords have to contain at least one uppercase and one lower case character and a number"
              }
            })}
          />
          <br />
          {errors.new_password && (
            <p className="settings-formfeedback">
              {errors.new_password.message}
            </p>
          )}
          <div
            className="settings-form-input-eye"
            onClick={() => setPasswordVisibility("new")}
          >
            {visiblePassword.new ? <AiFillEye /> : <AiFillEyeInvisible />}
          </div>
        </div>
        <div className="settings-form-input-wrapper">
          <p className="settings-form-input-label">Confirm Password</p>
          <input
            className={
              errors.confirm_password
                ? "settings-form-input-error"
                : "settings-form-input-tile"
            }
            name="confirm_password"
            type={visiblePassword.confirm ? "text" : "password"}
            {...register("confirm_password", {
              required: "You must re-enter your new password",
              validate: value =>
                value === password.current || "The passwords do not match"
            })}
          />
          <br />
          {errors.confirm_password && (
            <p className="settings-formfeedback">
              {errors.confirm_password.message}
            </p>
          )}
          <div
            className="settings-form-input-eye"
            onClick={() => setPasswordVisibility("confirm")}
          >
            {visiblePassword.confirm ? <AiFillEye /> : <AiFillEyeInvisible />}
          </div>
        </div>
        <div className="settings-form-submit-btn">
          <button type="submit">Save</button>
        </div>
      </form>
    </div>
  );
};

export default AdminSettings;
