import React, { useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";

function AddSalesRep({ setLoading, notify, refreshPage }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onRegister = formData => {
    setLoading(true);

    return fetch(`${process.env.REACT_APP_API_URL}/salesRep/addSalesRep`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(formData)
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ paddingTop: "0px" }} className="RegisterPage">
      <main className="fp-grid">
        <div style={{ paddingTop: "0px" }} className="grid1">
          <Form onSubmit={handleSubmit(onRegister)} className="form-container">
            <h2 className="form-header">Add a Sales Representative</h2>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <input
                    className={
                      errors.firstname ? "form-input-error" : "form-input-tile"
                    }
                    name="firstname"
                    type="text"
                    {...register("firstname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.firstname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <input
                    className={
                      errors.lastname ? "form-input-error" : "form-input-tile"
                    }
                    name="lastname"
                    type="text"
                    {...register("lastname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.lastname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <input
                    className={
                      errors.email ? "form-input-error" : "form-input-tile"
                    }
                    name="email"
                    type="email"
                    {...register("email", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.email && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="form-submit-btn">
              <button type="submit">Complete</button>
            </div>
          </Form>
        </div>
      </main>
    </div>
  );
}

export default AddSalesRep;
