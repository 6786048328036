import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "../../Components/Header/Header";

const ResetPassword = ({ role, notify, setLoading }) => {
  const [status, setStatus] = useState("VERIFYING");
  const [userEmail, setUserEmail] = useState();
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();

  const history = useHistory();

  const password = useRef({});
  password.current = watch("password", "");

  const onSetPassword = formData => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/setPassword`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: userEmail,
        password: formData.password,
        role
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          if (data.responseData.type === "Agent") {
            history.push("/agent");
          } else {
            history.push("/login");
          }
          notify("success", data.message);
        } else {
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/verifyPasswordToken`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({ token })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setStatus("SUCCESS");
          setUserEmail(data.responseData.email);
        } else {
          setStatus("ERROR");
        }
      })
      .catch(() => {
        setStatus("ERROR");
      });
  }, []);

  return (
    <div className="AccountVerificationPage">
      <Header />
      <div className="AccountVerification-container">
        {status === "VERIFYING" && (
          <p className="AccountVerification-message">Verifying Link...</p>
        )}
        {status === "ERROR" && (
          <p className="AccountVerification-message">
            There seems to be an error with the link
          </p>
        )}
        {status === "SUCCESS" && (
          <>
            <div className="login-header">
              <p className="login-header-text">Create a new Password</p>
            </div>
            <form
              className="form-container"
              onSubmit={handleSubmit(onSetPassword)}
            >
              <div className="form-input-wrapper">
                <p className="select-finance-input-label">Enter Password</p>
                <input
                  className={
                    errors.password ? "form-input-error" : "form-input-tile"
                  }
                  name="password"
                  type="password"
                  {...register("password", {
                    required: "Provide a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters"
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                      message:
                        "Passwords have to contain at least one uppercase and one lower case character and a number"
                    }
                  })}
                />
                <br />
                {errors.password && (
                  <p className="formfeedback">{errors.password.message}</p>
                )}
              </div>
              <div className="form-input-wrapper">
                <p className="select-finance-input-label">Confirm Password</p>
                <input
                  className={
                    errors.confirm_password
                      ? "form-input-error"
                      : "form-input-tile"
                  }
                  name="confirm_password"
                  type="password"
                  placeholder="Password"
                  {...register("confirm_password", {
                    required: "You must re-enter your password",
                    validate: value =>
                      value === password.current || "The passwords do not match"
                  })}
                />
                <br />
                {errors.confirm_password && (
                  <p className="formfeedback">
                    {errors.confirm_password.message}
                  </p>
                )}
              </div>
              <div className="form-submit-btn">
                <button type="submit" style={{ marginLeft: 0 }}>
                  Save
                </button>
              </div>
              <div className="form-gotologin">
                <a href="/login">Go to Login</a>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
