import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import vector1 from "../../images/Ellipse36.png";
import UserDetails from "../../Components/UserDetails/UserDetails";

const Overview = ({
  agentDetails,
  allClients,
  setLoading,
  notify,
  refreshPage
}) => {
  const [page, setPage] = useState("ANALYTICS");
  const [selectedUser, setSelectedUser] = useState({});
  const [addLead, setAddLead] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSelectUser = user => {
    setSelectedUser(user);
    setPage("USERDETAILS");
  };

  const onInviteClient = formData => {
    setLoading(true);

    return fetch(`${process.env.REACT_APP_API_URL}/agent/clientinvite`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
        agentId: agentDetails.id
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return page === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedUser}
      notify={notify}
      setPage={setPage}
      refreshPage={refreshPage}
      basePage="Dashboard"
      role="Agent"
    />
  ) : (
    <div className="analytics">
      <h4>Analytics</h4>
      <div className="analytics-visitors">
        <div className="single">
          <img src={vector1} alt="" />
          <div style={{ marginLeft: "20px" }}>
            <span className="title">
              {allClients.length}
              &nbsp;Total Clients
            </span>
          </div>
        </div>
        <div className="single">
          <div>
            Agent Code:&nbsp;
            <span className="title">{agentDetails.id.split("-")[0]}</span>
          </div>
        </div>
        <div
          style={{
            color: "white",
            fontWeight: "500",
            fontSize: "14px",
            background: "#4C9DE8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "40px",
            width: "100px",
            cursor: "pointer"
          }}
          onClick={() => {
            setAddLead(!addLead);
          }}
        >
          {addLead ? "Close" : " Invite Client"}
        </div>
      </div>
      {addLead && (
        <div style={{ paddingTop: "0px" }} className="RegisterPage">
          <main className="fp-grid">
            <div style={{ paddingTop: "0px" }} className="grid1">
              <Form
                onSubmit={handleSubmit(onInviteClient)}
                className="form-container"
              >
                <h2 className="form-header">Invite Client</h2>
                <Row className="form-row">
                  <Col md={12}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <input
                        className={
                          errors.name ? "form-input-error" : "form-input-tile"
                        }
                        name="name"
                        type="text"
                        {...register("name", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.name && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={12}>
                    <FormGroup>
                      <Label for="email">Email Address</Label>
                      <input
                        className={
                          errors.email ? "form-input-error" : "form-input-tile"
                        }
                        name="email"
                        type="email"
                        {...register("email")}
                      />
                      <br />
                      {errors.email && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="form-submit-btn">
                  <button type="submit">Send Invitation mail</button>
                </div>
              </Form>
            </div>
          </main>
        </div>
      )}
      <div className="analytics-table-card">
        <div className="analytics-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {allClients.map(user => (
                <tr key={user.user_id}>
                  <td
                    style={{ cursor: "pointer", color: "gray" }}
                    onClick={() => onSelectUser(user)}
                  >
                    {`${user.firstname} ${user.lastname}`}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>{new Date(user.date).toLocaleDateString("en-GB")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Overview;
