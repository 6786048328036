const getFinancing = (
  selectedFinance,
  recommendedSystem,
  setSelectedFinance
) => {
  const { downPayment, loanPeriod, startDateOfLoan } = selectedFinance;
  let {
    monthlyPayment,
    amountOfDownPayment,
    numberOfPayments,
    loanAmount,
    totalInterest,
    totalCostOfLoan,
    selectedFinanceTable,
    selectedGraphTable
  } = selectedFinance;

  const interestLookup = {
    low: {
      12: 0.215,
      24: 0.2175,
      36: 0.22,
      48: 0.2225,
      60: 0.225,
      72: 0.2275,
      84: 0.23,
      96: 0.2325,
      108: 0.235,
      120: 0.2375
    },
    medium: {
      12: 0.2125,
      24: 0.215,
      36: 0.2175,
      48: 0.22,
      60: 0.2225,
      72: 0.225,
      84: 0.2275,
      96: 0.23,
      108: 0.2325,
      120: 0.235
    },
    high: {
      12: 0.21,
      24: 0.2125,
      36: 0.215,
      48: 0.2175,
      60: 0.22,
      72: 0.2225,
      84: 0.225,
      96: 0.2275,
      108: 0.23,
      120: 0.2325
    }
  };

  const getInterestRate = (rFee, duration) => {
    if (!duration) {
      return 0;
    }
    const months = Number(duration) * 12;
    if (rFee === "0.05") {
      return interestLookup.low[months];
    }

    if (rFee === "0.1") {
      return interestLookup.medium[months];
    }

    if (rFee === "0.15") {
      return interestLookup.high[months];
    }

    return 0;
  };

  function pmt(
    rate_per_period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    // Return payment
    let result;
    if (rate_per_period === 0) {
      result = (present_value + future_value) / periods;
    } else {
      const term = (1 + rate_per_period) ** periods;
      if (type === 1) {
        result =
          ((future_value * rate_per_period) / (term - 1) +
            (present_value * rate_per_period) / (1 - 1 / term)) /
          (1 + rate_per_period);
      } else {
        result =
          (future_value * rate_per_period) / (term - 1) +
          (present_value * rate_per_period) / (1 - 1 / term);
      }
    }
    return -result;
  }

  function fv(rate_per_period, period, payment, present_value, type = 0) {
    // Return future value
    let result;
    if (rate_per_period === 0) {
      result = present_value + payment * period;
    } else {
      const term = (1 + rate_per_period) ** period;
      if (type === 1) {
        result =
          present_value * term +
          (payment * (1 + rate_per_period) * (term - 1)) / rate_per_period;
      } else {
        result =
          present_value * term + (payment * (term - 1)) / rate_per_period;
      }
    }
    return -result;
  }

  function ipmt(
    rate_per_period,
    period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    // Compute payment
    const payment = pmt(
      rate_per_period,
      periods,
      present_value,
      future_value,
      type
    );

    // Compute interest
    let interest;
    if (period === 1) {
      if (type === 1) {
        interest = 0;
      } else {
        interest = -present_value;
      }
    } else if (type === 1) {
      interest =
        fv(rate_per_period, period - 2, payment, present_value, 1) - payment;
    } else {
      interest = fv(rate_per_period, period - 1, payment, present_value, 0);
    }

    // Return interest
    return interest * rate_per_period;
  }

  function ppmt(
    rate_per_period,
    period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    return (
      pmt(rate_per_period, periods, present_value, future_value, type) -
      ipmt(rate_per_period, period, periods, present_value, future_value, type)
    );
  }

  const selectedMonthlyPayment = -pmt(
    getInterestRate(downPayment, loanPeriod) / 12,
    12 * Number(loanPeriod),
    recommendedSystem.price * (1 - Number(downPayment))
  );

  const selectedLoanAmount = recommendedSystem.price * (1 - downPayment);

  monthlyPayment = selectedMonthlyPayment;

  amountOfDownPayment = recommendedSystem.price * downPayment;

  loanAmount = selectedLoanAmount;

  totalCostOfLoan = Number(selectedMonthlyPayment) * loanPeriod * 12;

  totalInterest =
    Number(selectedMonthlyPayment) * Number(loanPeriod) * 12 -
    Number(selectedLoanAmount);

  numberOfPayments = loanPeriod * 12;

  const financeTable = [];
  const graphTable = [];
  const dateObj = new Date(startDateOfLoan);
  let beginningBalance = selectedLoanAmount;
  const payment = Number(selectedMonthlyPayment);
  const firstEndingBalance = -fv(
    getInterestRate(downPayment, loanPeriod) / 12,
    1,
    -selectedMonthlyPayment,
    selectedLoanAmount
  );
  let icet = Number(recommendedSystem.totalKilowatts);
  const firstIcevoltEquity = 1 - downPayment;

  let principalPaid = 0;
  let interestPaid = 0;
  let yearlyIcet = 0;
  let ownerPayments = 0;

  for (let i = 1; i <= 12 * Number(loanPeriod); i += 1) {
    const date = new Date(
      dateObj.getFullYear(),
      dateObj.getMonth() + i,
      dateObj.getDate()
    );

    const principal = -ppmt(
      getInterestRate(downPayment, loanPeriod) / 12,
      i,
      12 * Number(loanPeriod),
      selectedLoanAmount
    );

    const interest = -ipmt(
      getInterestRate(downPayment, loanPeriod) / 12,
      i,
      12 * Number(loanPeriod),
      selectedLoanAmount
    );

    const endingBalance = -fv(
      getInterestRate(downPayment, loanPeriod) / 12,
      i,
      -selectedMonthlyPayment,
      selectedLoanAmount
    );

    const icevoltEquity =
      endingBalance / (firstEndingBalance / firstIcevoltEquity);

    const ownerEquity = 1 - icevoltEquity;

    financeTable.push({
      paymentDate: date.toLocaleDateString(),
      beginningBalance: Number(beginningBalance.toFixed(2)),
      payment: Number(payment.toFixed(2)),
      principal: Number(principal.toFixed(2)),
      interest: Number(interest.toFixed(2)),
      endingBalance: Number(endingBalance.toFixed(2)),
      year: date.getFullYear(),
      icet: Number(icet.toFixed()),
      icevoltEquity: Number(icevoltEquity.toFixed(4)),
      ownerEquity: Number(ownerEquity.toFixed(4))
    });

    principalPaid += principal;
    interestPaid += interest;
    yearlyIcet += icet;
    ownerPayments += payment;

    if (date.getMonth() + 1 > 11 || i === 12 * Number(loanPeriod)) {
      graphTable.push({
        year: date.getFullYear(),
        principalPaid: Number(principalPaid.toFixed(2)),
        interestPaid: Number(interestPaid.toFixed(2)),
        loanBalance: Number(endingBalance.toFixed(2)),
        yearlyIcet: Number(yearlyIcet.toFixed()),
        yearlyIcevoltEquity: Number(icevoltEquity.toFixed(4)),
        yearlyOwnerEquity: Number(ownerEquity.toFixed(4)),
        ownerPayments: Number(ownerPayments.toFixed(2))
      });

      principalPaid = 0;
      interestPaid = 0;
      yearlyIcet = 0;
      ownerPayments = 0;
    }

    icet = Number(icet * (1 - 0.000833));
    beginningBalance = endingBalance;
  }

  selectedGraphTable = graphTable;
  selectedFinanceTable = financeTable;

  setSelectedFinance({
    plan: "CUSTOM",
    downPayment,
    loanPeriod,
    startDateOfLoan,
    monthlyPayment: Number(monthlyPayment.toFixed(2)),
    amountOfDownPayment: Number(amountOfDownPayment.toFixed(2)),
    numberOfPayments,
    loanAmount: Number(loanAmount.toFixed(2)),
    annualInterestRate: getInterestRate(downPayment, loanPeriod),
    totalInterest: Number(totalInterest.toFixed(2)),
    totalCostOfLoan: Number(totalCostOfLoan.toFixed(2)),
    selectedFinanceTable,
    selectedGraphTable
  });
};

export default getFinancing;
