import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "./SelectFinancing.css";

function SelectFinancing({
  selectedPlan,
  recommendedSystem,
  selectedFinance,
  setSelectedFinance,
  setLoading,
  notify,
  loggedIn
}) {
  const [downPayment, setDownPayment] = useState(
    selectedFinance.downPayment || ""
  );
  const [loanPeriod, setLoanPeriod] = useState(
    selectedFinance.loanPeriod || ""
  );
  const [startDateOfLoan, setStartDateOfLoan] = useState(
    selectedFinance.startDateOfLoan || ""
  );
  const [monthlyPayment, setMonthlyPayment] = useState(
    selectedFinance.monthlyPayment || ""
  );
  const [amountOfDownPayment, setAmountOfDownPayment] = useState(
    selectedFinance.amountOfDownPayment || ""
  );
  const [numberOfPayments, setNumberOfPayments] = useState(
    selectedFinance.numberOfPayments || ""
  );
  const [loanAmount, setLoanAmount] = useState(
    selectedFinance.loanAmount || ""
  );
  const [totalInterest, setTotalInterest] = useState(
    selectedFinance.totalInterest || ""
  );
  const [totalCostOfLoan, setTotalCostOfLoan] = useState(
    selectedFinance.totalCostOfLoan || ""
  );
  const [selectedFinanceTable, setSelectedFinanceTable] = useState(
    selectedFinance.selectedFinanceTable || []
  );
  const [selectedGraphTable, setSelectedGraphTable] = useState(
    selectedFinance.selectedGraphTable || []
  );

  const [graphBarChart, setGraphBarChart] = useState(
    selectedGraphTable.map(row => [row.year.toString(), row.ownerPayments])
  );

  const [areaChart, setAreaChart] = useState(
    selectedGraphTable.map(row => [
      row.year.toString(),
      row.yearlyIcevoltEquity,
      row.yearlyOwnerEquity
    ])
  );

  const interestLookup = {
    low: {
      12: 0.215,
      24: 0.2175,
      36: 0.22,
      48: 0.2225,
      60: 0.225,
      72: 0.2275,
      84: 0.23,
      96: 0.2325,
      108: 0.235,
      120: 0.2375
    },
    medium: {
      12: 0.2125,
      24: 0.215,
      36: 0.2175,
      48: 0.22,
      60: 0.2225,
      72: 0.225,
      84: 0.2275,
      96: 0.23,
      108: 0.2325,
      120: 0.235
    },
    high: {
      12: 0.21,
      24: 0.2125,
      36: 0.215,
      48: 0.2175,
      60: 0.22,
      72: 0.2225,
      84: 0.225,
      96: 0.2275,
      108: 0.23,
      120: 0.2325
    }
  };

  const getInterestRate = (rFee, duration) => {
    if (!duration) {
      return 0;
    }
    const months = Number(duration) * 12;
    if (Number(rFee) === 0.05) {
      return interestLookup.low[months];
    }

    if (Number(rFee) === 0.1) {
      return interestLookup.medium[months];
    }

    if (Number(rFee) === 0.15) {
      return interestLookup.high[months];
    }

    return 0;
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const history = useHistory();

  function pmt(
    rate_per_period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    // Return payment
    let result;
    if (rate_per_period === 0) {
      result = (present_value + future_value) / periods;
    } else {
      const term = (1 + rate_per_period) ** periods;
      if (type === 1) {
        result =
          ((future_value * rate_per_period) / (term - 1) +
            (present_value * rate_per_period) / (1 - 1 / term)) /
          (1 + rate_per_period);
      } else {
        result =
          (future_value * rate_per_period) / (term - 1) +
          (present_value * rate_per_period) / (1 - 1 / term);
      }
    }
    return -result;
  }

  function fv(rate_per_period, period, payment, present_value, type = 0) {
    // Return future value
    let result;
    if (rate_per_period === 0) {
      result = present_value + payment * period;
    } else {
      const term = (1 + rate_per_period) ** period;
      if (type === 1) {
        result =
          present_value * term +
          (payment * (1 + rate_per_period) * (term - 1)) / rate_per_period;
      } else {
        result =
          present_value * term + (payment * (term - 1)) / rate_per_period;
      }
    }
    return -result;
  }

  function ipmt(
    rate_per_period,
    period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    // Compute payment
    const payment = pmt(
      rate_per_period,
      periods,
      present_value,
      future_value,
      type
    );

    // Compute interest
    let interest;
    if (period === 1) {
      if (type === 1) {
        interest = 0;
      } else {
        interest = -present_value;
      }
    } else if (type === 1) {
      interest =
        fv(rate_per_period, period - 2, payment, present_value, 1) - payment;
    } else {
      interest = fv(rate_per_period, period - 1, payment, present_value, 0);
    }

    // Return interest
    return interest * rate_per_period;
  }

  function ppmt(
    rate_per_period,
    period,
    periods,
    present_value,
    future_value = 0,
    type = 0
  ) {
    return (
      pmt(rate_per_period, periods, present_value, future_value, type) -
      ipmt(rate_per_period, period, periods, present_value, future_value, type)
    );
  }

  function updateLoanModelTable(
    selectedLoanPeriod,
    selectedDate,
    selectedDownPayment,
    selectedLoanAmount
  ) {
    setLoading(true);
    const selectedMonthlyPayment = -pmt(
      getInterestRate(selectedDownPayment, selectedLoanPeriod) / 12,
      12 * Number(selectedLoanPeriod),
      selectedLoanAmount
    );
    const financeTable = [];
    const graphTable = [];
    const dateObj = new Date(selectedDate);
    let beginningBalance = selectedLoanAmount;
    const payment = Number(selectedMonthlyPayment);
    const firstEndingBalance = -fv(
      getInterestRate(selectedDownPayment, selectedLoanPeriod) / 12,
      1,
      -selectedMonthlyPayment,
      selectedLoanAmount
    );
    let icet = Number(recommendedSystem.totalKilowatts);
    const firstIcevoltEquity = 1 - selectedDownPayment;

    let principalPaid = 0;
    let interestPaid = 0;
    let yearlyIcet = 0;
    let ownerPayments = 0;

    for (let i = 1; i <= 12 * Number(selectedLoanPeriod); i += 1) {
      const date = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth() + i,
        dateObj.getDate()
      );

      const principal = -ppmt(
        getInterestRate(selectedDownPayment, selectedLoanPeriod) / 12,
        i,
        12 * Number(selectedLoanPeriod),
        selectedLoanAmount
      );

      const interest = -ipmt(
        getInterestRate(selectedDownPayment, selectedLoanPeriod) / 12,
        i,
        12 * Number(selectedLoanPeriod),
        selectedLoanAmount
      );

      const endingBalance = -fv(
        getInterestRate(selectedDownPayment, selectedLoanPeriod) / 12,
        i,
        -selectedMonthlyPayment,
        selectedLoanAmount
      );

      const icevoltEquity =
        endingBalance / (firstEndingBalance / firstIcevoltEquity);

      const ownerEquity = 1 - icevoltEquity;

      financeTable.push({
        paymentDate: date.toLocaleDateString("en-US"),
        beginningBalance: Number(Number(beginningBalance).toFixed(2)),
        payment: Number(Number(payment).toFixed(2)),
        principal: Number(Number(principal).toFixed(2)),
        interest: Number(Number(interest).toFixed(2)),
        endingBalance: Number(Number(endingBalance).toFixed(2)),
        year: date.getFullYear(),
        icet: Number(Number(icet).toFixed()),
        icevoltEquity: Number(Number(icevoltEquity).toFixed(4)),
        ownerEquity: Number(Number(ownerEquity).toFixed(4))
      });

      principalPaid += principal;
      interestPaid += interest;
      yearlyIcet += icet;
      ownerPayments += payment;

      if (date.getMonth() + 1 > 11 || i === 12 * Number(selectedLoanPeriod)) {
        if (dateObj.getDate() === 31 && date.getDate() !== 31) {
          continue;
        }
        graphTable.push({
          year: date.getFullYear(),
          principalPaid: Number(Number(principalPaid).toFixed(2)),
          interestPaid: Number(Number(interestPaid).toFixed(2)),
          loanBalance: Number(Number(endingBalance).toFixed(2)),
          yearlyIcet: Number(Number(yearlyIcet).toFixed()),
          yearlyIcevoltEquity: Number(Number(icevoltEquity).toFixed(4)),
          yearlyOwnerEquity: Number(Number(ownerEquity).toFixed(4)),
          ownerPayments: Number(Number(ownerPayments).toFixed(2))
        });

        principalPaid = 0;
        interestPaid = 0;
        yearlyIcet = 0;
        ownerPayments = 0;
      }

      icet = Number(icet * (1 - 0.000833));
      beginningBalance = endingBalance;
    }

    setSelectedGraphTable(graphTable);
    setGraphBarChart(
      graphTable.map(row => [row.year.toString(), row.ownerPayments])
    );
    setAreaChart(
      graphTable.map(row => [
        row.year.toString(),
        row.yearlyIcevoltEquity,
        row.yearlyOwnerEquity
      ])
    );
    setSelectedFinanceTable(financeTable);
    setLoading(false);
  }

  const onSelectDownPayment = selectedDownPayment => {
    const selectedMonthlyPayment = -pmt(
      getInterestRate(selectedDownPayment, loanPeriod) / 12,
      12 * Number(loanPeriod),
      recommendedSystem.price * (1 - Number(selectedDownPayment))
    );

    const selectedLoanAmount =
      recommendedSystem.price * (1 - selectedDownPayment);
    setMonthlyPayment(selectedMonthlyPayment);
    setDownPayment(selectedDownPayment);
    setAmountOfDownPayment(recommendedSystem.price * selectedDownPayment);
    setLoanAmount(selectedLoanAmount);
    setTotalCostOfLoan(Number(selectedMonthlyPayment) * loanPeriod * 12);
    setTotalInterest(
      Number(selectedMonthlyPayment) * Number(loanPeriod) * 12 -
        Number(selectedLoanAmount)
    );

    if (loanPeriod && startDateOfLoan) {
      updateLoanModelTable(
        loanPeriod,
        startDateOfLoan,
        selectedDownPayment,
        selectedLoanAmount
      );
    }
  };

  const onSelectLoanPeriod = selectedLoanPeriod => {
    const selectedLoanAmount =
      recommendedSystem.price * (1 - Number(downPayment));
    const selectedMonthlyPayment = -pmt(
      getInterestRate(downPayment, selectedLoanPeriod) / 12,
      12 * selectedLoanPeriod,
      selectedLoanAmount
    );
    setMonthlyPayment(selectedMonthlyPayment);
    setLoanPeriod(selectedLoanPeriod);
    setNumberOfPayments(selectedLoanPeriod * 12);
    setTotalCostOfLoan(
      Number(selectedMonthlyPayment) * selectedLoanPeriod * 12
    );
    setLoanAmount(selectedLoanAmount);
    setTotalInterest(
      Number(selectedMonthlyPayment) * Number(selectedLoanPeriod) * 12 -
        Number(selectedLoanAmount)
    );

    if (loanAmount && startDateOfLoan) {
      updateLoanModelTable(
        selectedLoanPeriod,
        startDateOfLoan,
        downPayment,
        selectedLoanAmount
      );
    }
  };

  const onSelectStartDate = selectedDate => {
    setStartDateOfLoan(selectedDate);

    if (loanAmount && loanPeriod) {
      updateLoanModelTable(loanPeriod, selectedDate, downPayment, loanAmount);
    }
  };

  const onSubmitFinance = () => {
    const userId = sessionStorage.getItem("userId");
    if (history.location.type === "updateFinance") {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/user/updateFinance`, {
        method: "put",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          selectedFinance: {
            plan: selectedPlan,
            downPayment,
            loanPeriod,
            startDateOfLoan,
            monthlyPayment: Number(Number(monthlyPayment).toFixed(2)),
            amountOfDownPayment: Number(Number(amountOfDownPayment).toFixed(2)),
            numberOfPayments,
            loanAmount: Number(Number(loanAmount).toFixed(2)),
            annualInterestRate: getInterestRate(downPayment, loanPeriod),
            totalInterest: Number(Number(totalInterest).toFixed(2)),
            totalCostOfLoan: Number(Number(totalCostOfLoan).toFixed(2)),
            selectedFinanceTable,
            selectedGraphTable
          }
        })
      })
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          if (data.success) {
            notify("success", data.message);
            setTimeout(() => {
              history.push({ pathname: "/landing-dashboard", type: "update" });
            }, 1000);
          }
        })
        .catch(() => {
          setLoading(false);
          notify("error", "Error Updating Finance");
        });
    } else {
      setSelectedFinance({
        plan: selectedPlan,
        downPayment,
        loanPeriod,
        startDateOfLoan,
        monthlyPayment: Number(Number(monthlyPayment).toFixed(2)),
        amountOfDownPayment: Number(Number(amountOfDownPayment).toFixed(2)),
        numberOfPayments,
        loanAmount: Number(Number(loanAmount).toFixed(2)),
        annualInterestRate: getInterestRate(downPayment, loanPeriod),
        totalInterest: Number(Number(totalInterest).toFixed(2)),
        totalCostOfLoan: Number(Number(totalCostOfLoan).toFixed(2)),
        selectedFinanceTable,
        selectedGraphTable
      });

      if (selectedPlan === "CUSTOM") {
        history.push("/custom-preview2");
      } else {
        history.push("/standard-preview2");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedFinance.downPayment) {
      onSelectDownPayment(downPayment);
      onSelectLoanPeriod(loanPeriod);
    }
  }, [recommendedSystem]);

  useEffect(() => {
    window.gtag("event", "finance_page_view", {
      event_category: "finance"
    });
  }, []);

  return (
    <div className="SelectFinancing">
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div className="select-finance-header">
            <p className="select-finance-header-text">Select Financing</p>
          </div>
          <form onSubmit={handleSubmit(onSubmitFinance)}>
            <div className="select-finance-input-wrapper">
              <p className="select-finance-input-label">
                Reservation
                <br />
                Fee
              </p>
              <select
                className={
                  errors.down_payment
                    ? "form-input-error"
                    : "select-finance-input"
                }
                name="down_payment"
                {...register("down_payment", {
                  required: true
                })}
                defaultValue={
                  selectedFinance.downPayment
                    ? Number(selectedFinance.downPayment)
                    : ""
                }
                onChange={e => {
                  onSelectDownPayment(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value={0.05}>Low</option>
                <option value={0.1}>Medium</option>
                <option value={0.15}>High</option>
              </select>
              <br />
              {errors.down_payment && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="select-finance-input-wrapper">
              <p className="select-finance-input-label">
                Power Contract
                <br />
                Duration(Yrs)
              </p>
              <select
                className={
                  errors.loan_period
                    ? "form-input-error"
                    : "select-finance-input"
                }
                name="loan_period"
                {...register("loan_period", {
                  required: true
                })}
                defaultValue={selectedFinance.loanPeriod}
                onChange={e => {
                  onSelectLoanPeriod(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                {/* <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option> */}
              </select>
              <br />
              {errors.loan_period && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="select-finance-date-input-wrapper">
              <p className="select-finance-input-label">
                Start Date Of Power Contract
              </p>
              <input
                className={
                  errors.start_date
                    ? "form-input-error"
                    : "select-finance-input"
                }
                type="date"
                name="start_date"
                {...register("start_date", {
                  required: true
                })}
                defaultValue={selectedFinance.startDateOfLoan}
                onChange={e => {
                  onSelectStartDate(e.target.value);
                }}
              />
              <br />
              {errors.start_date && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="loan-payment-wrapper">
              <div className="select-finance-header">
                <p className="select-finance-header-text">
                  Power Contract payment Details
                </p>
              </div>
              <div className="loan-info-plate-wrapper">
                <div className="loan-info-plate">
                  <p className="loan-info-title">Amount Of Reservation Fee</p>
                  {amountOfDownPayment && (
                    <p className="loan-info">
                      {`₦${Number(
                        Number(amountOfDownPayment).toFixed(2)
                      ).toLocaleString()}`}
                    </p>
                  )}
                </div>
                <div className="loan-info-plate">
                  <p className="loan-info-title">Number Of Payments</p>
                  <p className="loan-info">{numberOfPayments}</p>
                </div>
                <div className="loan-info-plate">
                  <p className="loan-info-title">
                    Monthly Flat Rate Utility Bill
                  </p>
                  {monthlyPayment && (
                    <p className="loan-info">
                      {`₦${Number(
                        Number(monthlyPayment).toFixed(2)
                      ).toLocaleString()}`}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              className="form-submit-btn"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {loggedIn ? (
                <button
                  type="button"
                  onClick={() => history.push("/landing-dashboard")}
                >
                  Cancel
                </button>
              ) : (
                <div />
              )}
              <button type="submit">
                {history.location.type === "updateFinance"
                  ? "Save Changes"
                  : "Confirm"}
              </button>
            </div>
          </form>
        </div>
        <div className="finance-graph-wrapper">
          <Chart
            width="100%"
            height="300px"
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={[["year", "Owner Payments"], ...graphBarChart]}
            options={{
              // Material design options
              chart: {
                title: "Icevolt Green Energy Serivices Ownership Model"
              }
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
          <Chart
            width="100%"
            height="300px"
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Year", "Icevolt ICET Equity", "Owner ICET Equity"],
              ...areaChart
            ]}
            options={{
              hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
              vAxis: { title: "Equity", minValue: 0 },
              // For the legend to fit, we make the chart area smaller
              chartArea: { width: "50%", height: "70%" }
              // lineWidth: 25
            }}
            // For tests
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </main>
    </div>
  );
}
export default SelectFinancing;
