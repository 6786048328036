import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import Header from "../../Components/Header/Header";
import RightGrid from "../../Components/RightGrid/RightGrid";
import "./AgentSignUp.css";

function AgentSignUp({ setLoading, notify }) {
  const [applicationComplete, setApplicationComplete] = useState(false);
  const [agentPicture, setAgentPicture] = useState({
    string: "",
    file: null
  });
  const [agentId, setAgentId] = useState({
    string: "",
    file: null
  });
  const [cacDocument, setCacDoument] = useState({
    string: "",
    file: null
  });
  const [ownBusiness, setOwnBusiness] = useState(false);
  const passport = React.createRef();
  const id = React.createRef();
  const cac = React.createRef();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSelectPhotograph = e => {
    clearErrors("passport");
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAgentPicture({
        string: reader.result,
        file
      });
    };
  };

  const onSelectId = e => {
    clearErrors("valid_id");
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAgentId({ string: reader.result, file });
    };
  };

  const onSelectCacDocument = e => {
    clearErrors("cac_document");
    const file = e.target.files[0];

    setCacDoument({ string: "", file });
  };

  const onRegister = formData => {
    if (!agentPicture.file) {
      if (passport.current) {
        passport.current.scrollIntoView({ behavior: "smooth" });
      }
      return setError("passport");
    }

    if (!agentId.file) {
      if (id.current) {
        id.current.scrollIntoView({ behavior: "smooth" });
      }
      return setError("valid_id");
    }

    if (ownBusiness && !cacDocument.file) {
      if (cac.current) {
        cac.current.scrollIntoView({ behavior: "smooth" });
      }
      return setError("cac_document");
    }

    setLoading(true);

    const applicationData = {
      ...formData,
      email: formData.email.toLowerCase(),
      passport: agentPicture.file,
      valid_id: agentId.file,
      cac_document: cacDocument.file
    };

    const form_data = new FormData();

    Object.keys(applicationData).forEach(key => {
      form_data.append(key, applicationData[key]);
    });

    return fetch(`${process.env.REACT_APP_API_URL}/agent/register`, {
      method: "post",
      body: form_data
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);

          window.gtag("event", "agent_application", {
            event_category: "conversions"
          });

          setApplicationComplete(true);
          notify("success", data.message);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.gtag("event", "agent_application_view", {
      event_category: "agent"
    });
  }, []);

  return (
    <div className="RegisterPage">
      {applicationComplete && (
        <div className="thankyou-wrapper">
          <div className="thankyou-content">
            <h4>Your Application has been sent</h4>
            <p>
              You will be contacted after your profile has been reviewed and an
              email will be sent to you to complete your registration
            </p>
            <div
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              <a href="/">Proceed to load selector</a>
            </div>
          </div>
        </div>
      )}
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <Form onSubmit={handleSubmit(onRegister)} className="form-container">
            <h2 className="form-header">Agent Application</h2>
            <p className="form-subheader">
              fill in the details below to apply to be an IceVolt agent.
            </p>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <input
                    className={
                      errors.firstname ? "form-input-error" : "form-input-tile"
                    }
                    name="firstname"
                    placeholder="same name on your NIN or driver's license"
                    type="text"
                    {...register("firstname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.firstname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <input
                    className={
                      errors.lastname ? "form-input-error" : "form-input-tile"
                    }
                    name="lastname"
                    placeholder="same name on your NIN or driver's license"
                    type="text"
                    {...register("lastname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.lastname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <input
                    className={
                      errors.email ? "form-input-error" : "form-input-tile"
                    }
                    name="email"
                    type="email"
                    {...register("email", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.email && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="phone_number">Phone Number</Label>
                  <input
                    className={
                      errors.phone_number
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="phone_number"
                    type="tel"
                    {...register("phone_number", {
                      required: true,
                      minLength: 6,
                      maxLength: 12
                    })}
                  />
                  <br />
                  {errors.phone_number && (
                    <p className="formfeedback">Enter a valid phone number</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <input
                    className={
                      errors.address ? "form-input-error" : "form-input-tile"
                    }
                    name="address"
                    type="text"
                    {...register("address", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.address && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="passport">Recent photograph</Label>
                  <input
                    ref={passport}
                    className={
                      errors.passport ? "form-input-error" : "form-input-tile"
                    }
                    name="passport"
                    type="file"
                    accept="image/*"
                    onChange={onSelectPhotograph}
                  />
                  <br />
                  {errors.passport && (
                    <p className="formfeedback">Upload a photograph</p>
                  )}
                </FormGroup>
                {agentPicture.file && (
                  <img
                    className="agent-form-photograph"
                    src={agentPicture.string}
                    alt=""
                  />
                )}
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="valid_id">Picture of NIN slip</Label>
                  <input
                    ref={id}
                    className={
                      errors.valid_id ? "form-input-error" : "form-input-tile"
                    }
                    name="valid_id"
                    type="file"
                    accept="image/*"
                    onChange={onSelectId}
                  />
                  <br />
                  {errors.valid_id && (
                    <p className="formfeedback">Upload your NIN</p>
                  )}
                </FormGroup>
                {agentId.file && (
                  <img className="agent-form-id" src={agentId.string} alt="" />
                )}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="valid_id_number">Virtual NIN</Label>
                  <input
                    className={
                      errors.valid_id_number
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="valid_id_number"
                    type="text"
                    {...register("valid_id_number", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.valid_id_number && (
                    <p className="formfeedback">
                      Enter the Identification number on your NIN slip
                    </p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <legend>Do you own a registered business?</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="owner"
                        onClick={() => {
                          setOwnBusiness(true);
                        }}
                        {...register("owner", {
                          required: true
                        })}
                        value="Yes"
                      />
                      Yes
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="owner"
                        onClick={() => {
                          setOwnBusiness(false);
                        }}
                        {...register("owner", {
                          required: true
                        })}
                        value="No"
                      />
                      No
                    </Label>
                  </div>
                </div>
                {errors.owner && (
                  <p className="formfeedback">Select an option</p>
                )}
              </div>
            </Row>
            {ownBusiness && (
              <>
                <Row className="form-row">
                  <Col md={12}>
                    <FormGroup>
                      <Label for="business_registered_name">
                        Business Registered name
                      </Label>
                      <input
                        className={
                          errors.business_registered_name
                            ? "form-input-error"
                            : "form-input-tile"
                        }
                        name="business_registered_name"
                        type="text"
                        {...register("business_registered_name", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.business_registered_name && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={12}>
                    <FormGroup>
                      <Label for="business_office_address">
                        Business Office Address
                      </Label>
                      <input
                        className={
                          errors.business_office_address
                            ? "form-input-error"
                            : "form-input-tile"
                        }
                        name="business_office_address"
                        type="tel"
                        {...register("business_office_address", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.business_office_address && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={6}>
                    <FormGroup>
                      <Label for="business_cac">Business CAC/ BN number</Label>
                      <input
                        className={
                          errors.business_cac
                            ? "form-input-error"
                            : "form-input-tile"
                        }
                        name="business_cac"
                        type="text"
                        {...register("business_cac", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.business_cac && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="cac_document">CAC document</Label>
                      <input
                        ref={cac}
                        className={
                          errors.cac_document
                            ? "form-input-error"
                            : "form-input-tile"
                        }
                        name="cac_document"
                        type="file"
                        onChange={onSelectCacDocument}
                      />
                      <br />
                      {errors.cac_document && (
                        <p className="formfeedback">Upload document</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={6}>
                    <FormGroup>
                      <Label for="years_in_business">
                        Number of years in Business
                      </Label>
                      <input
                        className={
                          errors.years_in_business
                            ? "form-input-error"
                            : "form-input-tile"
                        }
                        name="years_in_business"
                        type="number"
                        {...register("years_in_business", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.years_in_business && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="type_of_business">Type of Business</Label>
                      <input
                        className={
                          errors.years ? "form-input-error" : "form-input-tile"
                        }
                        type="text"
                        name="type_of_business"
                        {...register("type_of_business", {
                          required: true
                        })}
                      />
                      <br />
                      {errors.type_of_business && (
                        <p className="formfeedback">
                          This field cannot be empty
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <Row className="form-row">
              <legend>Do you have any criminal records?</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="criminal_record"
                        {...register("criminal_record", {
                          required: true
                        })}
                        value="Yes"
                      />
                      Yes
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="criminal_record"
                        {...register("criminal_record", {
                          required: true
                        })}
                        value="No"
                      />
                      No
                    </Label>
                  </div>
                </div>
                {errors.criminal_record && (
                  <p className="formfeedback">Select an option</p>
                )}
              </div>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="next_upgrade">
                    States of business operations(seperate with commas)
                  </Label>
                  <input
                    className={
                      errors.business_states
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="business_states"
                    type="text"
                    {...register("business_states", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.business_states && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="awareness_means">
                    How did you hear about us?
                  </Label>
                  <select
                    className={
                      errors.awareness_means
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="awareness_means"
                    {...register("awareness_means", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Twitter">Twitter</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="LinkedIn">Google</option>
                    <option value="LinkedIn">Flyers</option>
                    <option value="Friend">Friend</option>
                    <option value="Other">Other</option>
                  </select>
                  <br />
                  {errors.awareness_means && (
                    <p className="formfeedback">Select an option</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="business_plan">
                    Tell us about your business/customer acquisition plan
                  </Label>
                  <textarea
                    className={
                      errors.business_plan
                        ? "form-textarea-error"
                        : "form-textarea-tile"
                    }
                    name="business_plan"
                    {...register("business_plan", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.business_plan && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="form-submit-btn">
              <button type="submit">Complete</button>
            </div>
          </Form>
        </div>
        <RightGrid />
      </main>
    </div>
  );
}

export default AgentSignUp;
