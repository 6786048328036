import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "../../Components/Header/Header";
import AgentContract from "./AgentContract";
import "./AccountVerification.css";

const AccountVerification = ({
  notify,
  setLoading,
  logUserIn,
  logAgentIn,
  logSalesRepIn,
  role
}) => {
  const [status, setStatus] = useState("VERIFYING");
  const [userEmail, setUserEmail] = useState();
  const [termsAgree, setTermsAgree] = useState(false);
  const [agentPage, setAgentpage] = useState(1);
  const [agentInfo, setAgentInfo] = useState({});
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();

  const history = useHistory();

  const password = useRef({});
  password.current = watch("password", "");

  const onAgentProceed = () => {
    if (termsAgree) {
      setAgentpage(2);
    }
  };

  const onSetPassword = formData => {
    setLoading(true);
    if (role === "Agent") {
      fetch(`${process.env.REACT_APP_API_URL}/agent/setpassword`, {
        method: "put",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({ email: userEmail, password: formData.password })
      })
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          if (data.success) {
            notify("success", data.message);
            logAgentIn(data.responseData.agentId);
            history.push("/agent/dashboard");
          } else {
            notify("error", data.message);
          }
        })
        .catch(() => {
          setLoading(false);
          notify("error", "An error occurred");
        });
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/auth/setPassword`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({ email: userEmail, password: formData.password })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success && data.responseData.type === "Client") {
            notify("success", data.message);
            fetch(
              `${process.env.REACT_APP_API_URL}/user/userDetails/${data.responseData.userId}`
            )
              .then(res => res.json())
              .then(userData => {
                setLoading(false);
                logUserIn(userData.responseData);
                notify("success", "Welcome");
                if (userData.responseData.userDetails.type === "Paying") {
                  history.push("/dashboard");
                } else {
                  history.push("/landing-dashboard");
                }
              })
              .catch(() => {
                setLoading(false);
                notify("warn", "login");
              });
          } else if (data.success && data.responseData.type === "SalesRep") {
            setLoading(false);
            notify("success", data.message);
            logSalesRepIn(data.responseData.salesRepId);
            history.push("/sales/dashboard");
          } else {
            setLoading(false);
            notify("error", data.message);
          }
        })
        .catch(() => {
          setLoading(false);
          notify("error", "An error occurred");
        });
    }
  };

  useEffect(() => {
    if (role === "Agent") {
      fetch(`${process.env.REACT_APP_API_URL}/agent/verifyemail`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({ token })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setStatus("SUCCESS");
            setAgentInfo(data.responseData);
            setUserEmail(data.responseData.email);
          } else {
            setStatus("ERROR");
          }
        })
        .catch(() => {
          setStatus("ERROR");
        });
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({ token })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setStatus("SUCCESS");
            setUserEmail(data.responseData.email);
          } else {
            setStatus("ERROR");
          }
        })
        .catch(() => {
          setStatus("ERROR");
        });
    }
  }, []);

  return (
    <div className="AccountVerificationPage">
      <Header />
      <div className="AccountVerification-container">
        {status === "VERIFYING" && (
          <p className="AccountVerification-message">Verifying Link...</p>
        )}
        {status === "ERROR" && (
          <p className="AccountVerification-message">
            There seems to be an error with the link
          </p>
        )}
        {status === "SUCCESS" && (
          <>
            {role === "Agent" ? (
              <>
                {agentPage === 1 ? (
                  <AgentContract
                    termsAgree={termsAgree}
                    setTermsAgree={setTermsAgree}
                    onProceed={onAgentProceed}
                    agentInfo={agentInfo}
                  />
                ) : (
                  <>
                    <div className="login-header">
                      <p className="login-header-text">
                        You&apos;ve successfully confirmed your email. One more
                        step, Please create a password.
                      </p>
                    </div>
                    <form
                      className="form-container"
                      onSubmit={handleSubmit(onSetPassword)}
                    >
                      <div className="form-input-wrapper">
                        <p className="select-finance-input-label">
                          Enter Password
                        </p>
                        <input
                          className={
                            errors.password
                              ? "form-input-error"
                              : "form-input-tile"
                          }
                          name="password"
                          type="password"
                          {...register("password", {
                            required: "Provide a password",
                            minLength: {
                              value: 8,
                              message:
                                "Password must have at least 8 characters"
                            },
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                              message:
                                "Passwords have to contain at least one uppercase and one lower case character and a number"
                            }
                          })}
                        />
                        <br />
                        {errors.password && (
                          <p className="formfeedback">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                      <div className="form-input-wrapper">
                        <p className="select-finance-input-label">
                          Confirm Password
                        </p>
                        <input
                          className={
                            errors.confirm_password
                              ? "form-input-error"
                              : "form-input-tile"
                          }
                          name="confirm_password"
                          type="password"
                          placeholder="Password"
                          {...register("confirm_password", {
                            required: "You must re-enter your password",
                            validate: value =>
                              value === password.current ||
                              "The passwords do not match"
                          })}
                        />
                        <br />
                        {errors.confirm_password && (
                          <p className="formfeedback">
                            {errors.confirm_password.message}
                          </p>
                        )}
                      </div>
                      <div className="form-submit-btn">
                        <button style={{ marginLeft: "0" }} type="submit">
                          Save
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="login-header">
                  <p className="login-header-text">
                    You&apos;ve successfully confirmed your email. One more
                    step, Please create a password.
                  </p>
                </div>
                <form
                  className="form-container"
                  onSubmit={handleSubmit(onSetPassword)}
                >
                  <div className="form-input-wrapper">
                    <p className="select-finance-input-label">Enter Password</p>
                    <input
                      className={
                        errors.password ? "form-input-error" : "form-input-tile"
                      }
                      name="password"
                      type="password"
                      {...register("password", {
                        required: "Provide a password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters"
                        },
                        pattern: {
                          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                          message:
                            "Passwords have to contain at least one uppercase and one lower case character and a number"
                        }
                      })}
                    />
                    <br />
                    {errors.password && (
                      <p className="formfeedback">{errors.password.message}</p>
                    )}
                  </div>
                  <div className="form-input-wrapper">
                    <p className="select-finance-input-label">
                      Confirm Password
                    </p>
                    <input
                      className={
                        errors.confirm_password
                          ? "form-input-error"
                          : "form-input-tile"
                      }
                      name="confirm_password"
                      type="password"
                      placeholder="Password"
                      {...register("confirm_password", {
                        required: "You must re-enter your password",
                        validate: value =>
                          value === password.current ||
                          "The passwords do not match"
                      })}
                    />
                    <br />
                    {errors.confirm_password && (
                      <p className="formfeedback">
                        {errors.confirm_password.message}
                      </p>
                    )}
                  </div>
                  <div className="form-submit-btn">
                    <button style={{ marginLeft: "0" }} type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AccountVerification;
