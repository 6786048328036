import React, { useState } from "react";

const UserNote = ({ defaultNote, setUserNote, onSaveUserNote, userId }) => {
  const [note, setNote] = useState(defaultNote);
  return (
    <div className="advance-customer">
      <div
        className="advance-customer-background"
        onClick={() => setUserNote("")}
      />
      <div className="content">
        <h4>User Note</h4>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Enter a note about this user"
          value={note}
          onChange={e => {
            setNote(e.target.value);
          }}
        />
        <button
          type="button"
          onClick={() => {
            onSaveUserNote(userId, note);
            setUserNote("");
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UserNote;
