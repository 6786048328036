import React from "react";
import "./CustomerInformation.css";

const CustomerInformation = ({ userDetails }) => (
  <div className="customer-info">
    <div className="landing-dashboard-user-personal">
      <div className="landing-dashboard-user-info">
        <h3 className="landing-dashboard-user-name">
          {`${userDetails.userDetails.firstname} ${userDetails.userDetails.lastname}`}
        </h3>
        <p className="landing-dashboard-user-email">
          {userDetails.userDetails.email}
        </p>
      </div>
    </div>
    <h4 className="customer-info-setion-header">Contact Information</h4>
    <h6 className="sub-heading">
      Installation Address:
      <span className="sub">
        {` ${userDetails.userDetails.installation_address}`}
      </span>
    </h6>
    <h6 className="sub-heading">
      Billing Address:
      <span className="sub">
        {` ${userDetails.userDetails.installation_address}`}
      </span>
    </h6>
    <h6 className="sub-heading">
      Phone:
      <span className="sub">{` ${userDetails.userDetails.phone_number}`}</span>
    </h6>
    {/* <h4 className="customer-info-setion-header">Loan Information</h4>
    <h6 className="sub-heading">
      Credit File:
      <span className="sub"> Zim Okafor Credit Report 18-Oct-2021</span>
    </h6>
    <h6 className="sub-heading">
      Loan Report:
      <span className="sub"> Loan profile 18-Oct-2021</span>
    </h6> */}
  </div>
);

export default CustomerInformation;
