import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Header from "../../Components/Header/Header";
import NewExisting from "../../Components/NewExisting/NewExisting";
import RightGrid from "../../Components/RightGrid/RightGrid";
import "./Login.css";

function Login({
  role,
  setLoading,
  logUserIn,
  logAdminIn,
  logAgentIn,
  logSalesRepIn,
  notify
}) {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const [seePassword, setSeePassword] = useState(true);
  const history = useHistory();

  const onLogin = formData => {
    setLoading(true);
    if (role === "Agent") {
      fetch(`${process.env.REACT_APP_API_URL}/agent/login`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          email: formData.email.toLowerCase(),
          role
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setLoading(false);
            logAgentIn(data.responseData.agent.id);
            notify("success", "Welcome");
            history.push("/agent/dashboard");
          } else {
            notify("error", data.message);
            setLoading(false);
          }
        })
        .catch(() => {
          notify("error", "An error occurred");
          setLoading(false);
        });
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          email: formData.email.toLowerCase(),
          role
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success && role === "Client") {
            setLoading(false);
            if (data.responseData.loginType === "Client") {
              logUserIn({
                ...data.responseData,
                remember_me: formData.remember_me
              });
              if (data.responseData.userDetails.type === "Paying") {
                history.push("/dashboard");
              } else {
                history.push("/landing-dashboard");
              }
            } else {
              logSalesRepIn(data.responseData.salesRep.id);
              notify("success", "Welcome");
              history.push("/sales/dashboard");
            }
          } else if (data.success && role === "Admin") {
            setLoading(false);
            logAdminIn(data.responseData);
            notify("success", "Welcome");
            history.push("/admin/dashboard");
          } else {
            notify("error", data.message);
            setLoading(false);
          }
        })
        .catch(() => {
          notify("error", "An error occurred");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    const userId = localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : sessionStorage.getItem("userId");
    const adminId = sessionStorage.getItem("adminId");
    if (role === "Client" && userId) {
      fetch(`${process.env.REACT_APP_API_URL}/user/userDetails/${userId}`)
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          logUserIn(data.responseData);
          notify("success", "Welcome");
          if (data.responseData.userDetails.type === "Paying") {
            history.push("/dashboard");
          } else {
            history.push("/landing-dashboard");
          }
        })
        .catch(() => {
          setLoading(false);
          notify("warn", "login");
        });
    } else if (role === "Admin" && adminId) {
      fetch(`${process.env.REACT_APP_API_URL}/admin/profile/${adminId}`)
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          logAdminIn(data.responseData);
          notify("success", "Welcome");
          history.push("/admin/dashboard");
        })
        .catch(() => {
          setLoading(false);
          notify("warn", "login");
        });
    }

    setLoading(false);
  }, []);

  return (
    <div className="LoginPage">
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div>
            <NewExisting selected="exist" />
          </div>
          <div className="login-header">
            {role === "Admin" ? (
              <p className="login-header-text">Admin Login</p>
            ) : (
              <p className="login-header-text">
                Please enter Your Login details
              </p>
            )}
          </div>
          <form className="form-container" onSubmit={handleSubmit(onLogin)}>
            <div className="form-input-wrapper">
              <input
                className={
                  errors.email ? "form-input-error" : "form-input-tile"
                }
                style={{ height: "45px" }}
                name="email"
                type="email"
                placeholder="Email Address"
                {...register("email", {
                  required: true
                })}
              />
              <br />
              {errors.email && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="form-input-wrapper">
              <div className="container">
                <input
                  className={
                    errors.password ? "form-input-error" : "form-input-tile"
                  }
                  name="password"
                  type={`${seePassword ? "password" : "text"}`}
                  placeholder="Password"
                  {...register("password", {
                    required: true
                  })}
                />
                <div
                  className="eye"
                  onClick={() => setSeePassword(!seePassword)}
                >
                  {seePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
              <br />
              {errors.password && (
                <p className="formfeedback">This field cannot be empty</p>
              )}
            </div>
            <div className="remember-forgot">
              <div className="remember-me">
                <input
                  type="checkbox"
                  name="remember_me"
                  {...register("remember_me")}
                />
                Remember me
              </div>
              {role === "Admin" ? (
                <div />
              ) : (
                <Link
                  to={`${
                    role === "Agent"
                      ? "/agent/forgot-password"
                      : "/forgot-password"
                  }`}
                >
                  Forgot Password?
                </Link>
              )}
            </div>
            <div className="form-submit-btn">
              <button style={{ marginLeft: "0" }} type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
        <RightGrid />
      </main>
    </div>
  );
}

export default Login;
