import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "reactstrap";
import Header from "../../Components/Header/Header";
import RefineSelection from "../../Components/RefineSelection/RefineSelection";
import "./CustomPreview.css";

function CustomPreview({
  page,
  customFeedback,
  setCustomFeedback,
  recommendedSystem,
  setRecommendedSystem,
  selectedFinance,
  setSelectedFinance,
  appliances,
  setAppliances,
  pricebook,
  basicRecommended,
  loggedIn,
  selectedPlan,
  notify,
  setLoading
}) {
  const [showRefine, setShowRefine] = useState(false);

  const reservationFeeValue = {
    0.05: "Low",
    0.1: "Medium",
    0.15: "High"
  };

  const history = useHistory();

  const onSelectFinancing = () => {
    history.push("/select-financing");
  };

  const onCloseRefine = () => {
    setShowRefine(false);
  };

  const onSaveChanges = () => {
    setLoading(true);
    const userId = sessionStorage.getItem("userId");

    fetch(`${process.env.REACT_APP_API_URL}/user/updateSelection`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        plan: selectedPlan,
        customFeedback,
        recommendedSystem,
        appliances,
        selectedFinance,
        userId
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
          setTimeout(() => {
            history.push({ pathname: "/landing-dashboard", type: "update" });
          }, 1000);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "Error Updating Selection");
      });
  };

  const onPlaceOrder = () => {
    history.push("/register");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="CustomPreview">
      {showRefine && (
        <RefineSelection
          onClose={onCloseRefine}
          userCustomFeedback={customFeedback}
          setUserCustomFeedback={setCustomFeedback}
          recommendedSystem={recommendedSystem}
          setRecommendedSystem={setRecommendedSystem}
          selectedFinance={selectedFinance}
          setSelectedFinance={setSelectedFinance}
          appliances={appliances}
          setAppliances={setAppliances}
          pricebook={pricebook}
          basicRecommended={basicRecommended}
          selectedPlan={selectedPlan}
        />
      )}
      <Header />
      <h1 className="preview-header-label">Review My Solution</h1>
      <div className="preview-selection">
        <div className="preview-header">
          <p className="preview-header-text">My Selection</p>
        </div>
        <div className="preview-body">
          <p className="preview-plan-text">Custom Design</p>
        </div>
      </div>
      {recommendedSystem && recommendedSystem.sunlightAdjustment && (
        <>
          <div className="preview-recommended">
            <div className="preview-header">
              <p className="preview-header-text">Recommended System</p>
            </div>
            <div className="preview-body">
              <p className="preview-body-text">{recommendedSystem.make}</p>
            </div>
          </div>
        </>
      )}
      <div>
        <Form className="form-container">
          <h5 className="mb-3 form-header">Selection Details</h5>
          <div className="refine-details">
            <div className="refine-details-header">
              <p className="refine-details-header-text">
                Regular Household Appliances
              </p>
              <p className="refine-details-header-subtext">
                Low consumption, Daily Use
              </p>
            </div>
            <div className="refine-details-body">
              <div className="appliance-wrapper-label">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              <div className="appliance-wrapper-label appliance-label2">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              {appliances.map(
                appliance =>
                  appliance.appliance_category ===
                    "Regular Household Appliances" &&
                  appliance.checked && (
                    <div
                      className="appliance-wrapper"
                      key={appliance.appliance_id}
                    >
                      <div className="appliance-checkbox-name">
                        {appliance.appliance_name}
                      </div>
                      <div className="appliance-quantity">
                        <p>{appliance.custom_quantity}</p>
                      </div>
                      <div className="appliance-wattage">
                        <div className="appliance-wattage-input-wrapper">
                          <input
                            className="appliance-wattage-input"
                            disabled
                            value={appliance.wattage}
                          />
                          <p>W</p>
                        </div>
                      </div>
                      <div className="appliance-usage">
                        <div className="appliance-usage-input-wrapper">
                          <input
                            className="appliance-usage-input"
                            disabled
                            value={appliance.daily_run_hours}
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="refine-details">
            <div className="refine-details-header">
              <p className="refine-details-header-text">
                High Power Appliances
              </p>
              <p className="refine-details-header-subtext">
                High consumption, Regular Use
              </p>
            </div>
            <div className="refine-details-body">
              <div className="appliance-wrapper-label">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              <div className="appliance-wrapper-label appliance-label2">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              {appliances.map(
                appliance =>
                  appliance.appliance_category === "High Power Appliances" &&
                  appliance.checked && (
                    <div
                      className="appliance-wrapper"
                      key={appliance.appliance_id}
                    >
                      <div className="appliance-checkbox-name">
                        {appliance.appliance_name}
                      </div>
                      <div className="appliance-quantity">
                        <p>{appliance.custom_quantity}</p>
                      </div>
                      <div className="appliance-wattage">
                        <div className="appliance-wattage-input-wrapper">
                          <input
                            className="appliance-wattage-input"
                            value={appliance.wattage}
                            disabled
                          />
                          <p>W</p>
                        </div>
                      </div>
                      <div className="appliance-usage">
                        <div className="appliance-usage-input-wrapper">
                          <input
                            className="appliance-usage-input"
                            value={appliance.daily_run_hours}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <Row>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">
                    Envac Proprietary Thermal Storage Equipment (Air
                    Conditioning and Water Heater)
                  </p>
                  <p className="refine-details-header-subtext" />
                </div>
                <div className="refine-details-body">
                  {appliances.map(
                    appliance =>
                      appliance.appliance_category ===
                        "Envac Proprietary Thermal Storage Equipment (Air Conditioning and Water Heater)" &&
                      appliance.checked && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <p>{appliance.custom_quantity}</p>
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                disabled
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">
                    Lighthing Requirements
                  </p>
                  <p className="refine-details-header-subtext" />
                </div>
                <div className="refine-details-body">
                  {appliances.map(
                    appliance =>
                      appliance.appliance_category ===
                        "Lighting Requirements" &&
                      appliance.checked && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <p>{appliance.custom_quantity}</p>
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                disabled
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">Others</p>
                  <p className="refine-details-header-subtext">
                    Custom Appliances
                  </p>
                </div>
                <div className="refine-details-body">
                  {appliances.map(
                    appliance =>
                      appliance.appliance_category === "Others" &&
                      appliance.checked && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <p>{appliance.custom_quantity}</p>
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                disabled
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
            {/* <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">
                    Water Heater Points
                  </p>
                  <p className="refine-details-header-subtext" />
                </div>
                <div className="refine-details-body">
                  {appliances.map(
                    appliance =>
                      appliance.appliance_category ===
                        "Water Heater Points" && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <p>{appliance.custom_quantity}</p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col> */}
          </Row>
          <Row>
            <div className="preview-body custom-preview-selection-details">
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Battery Replacement Package
                </p>
                <p className="selection-details-element-value">
                  {customFeedback.battery_replacement_package}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Quaterly Maintenance Package
                </p>
                <p className="selection-details-element-value">
                  {customFeedback.quarterly_maintenance_package}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Extended Warranty Package
                </p>
                <p className="selection-details-element-value">
                  {customFeedback.extended_warranty_package}
                </p>
              </div>
              <div className="selection-details-element" />
            </div>
          </Row>
          <h5 className="mb-3 form-header">Design Load Calculations</h5>
          {recommendedSystem && recommendedSystem.sunlightAdjustment && (
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Start Up Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalStartUpPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.thermalBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Energy Use(WH)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalEnergy}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Adjustment for Sunlight Intensity
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.sunlightAdjustment}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalThermalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.totalThermalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Main Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.mainBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element" />
            </div>
          )}
          <h5 className="mb-3 form-header">Equipment Specification</h5>
          {recommendedSystem && recommendedSystem.sunlightAdjustment && (
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Inverter Capacity
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.products["Inverter Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Battery Bank Capacity
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.products["Battery Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Storage Unit (EnVAC)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.products["AC PACKAGE"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Solar Panel Sizing
                </p>
                <p className="selection-details-element-value">
                  {Number(recommendedSystem.products["Solar Package"].rating)}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Water Heater Capacity
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.products["WATER HEATER"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Space Cooling (Nature Breeze)
                </p>
                <p className="selection-details-element-value">
                  {recommendedSystem.products["Nature Breeze Package"].rating}
                </p>
              </div>
            </div>
          )}
        </Form>
      </div>
      {page === 2 && (
        <div className="preview-financing">
          <div className="preview-header">
            <p className="preview-header-text">My Selected Financing</p>
          </div>
          <div className="preview-body custom-preview-selection-details">
            <div className="selection-details-element">
              <p className="selection-details-element-label">Reservation Fee</p>
              <p className="selection-details-element-value">
                {reservationFeeValue[Number(selectedFinance.downPayment)]}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Power Contract Duration in years
              </p>
              <p className="selection-details-element-value">
                {selectedFinance.loanPeriod}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Monthly Flat Rate Utility Bill
              </p>
              <p className="selection-details-element-value">
                {`₦${selectedFinance.monthlyPayment.toLocaleString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Amount of Reservation Fee
              </p>
              <p className="selection-details-element-value">
                {`₦${selectedFinance.amountOfDownPayment.toLocaleString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Number of Payments
              </p>
              <p className="selection-details-element-value">
                {selectedFinance.numberOfPayments}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Start Date of Power Contract
              </p>
              <p className="selection-details-element-value">
                {`${new Date(selectedFinance.startDateOfLoan).toDateString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                End Date of Power Contract
              </p>
              <p className="selection-details-element-value">
                {new Date(
                  selectedFinance.selectedFinanceTable[
                    selectedFinance.selectedFinanceTable.length - 1
                  ].paymentDate
                ).toDateString()}
              </p>
            </div>
            <div className="form-submit-btn">
              <button type="button" onClick={() => onSelectFinancing()}>
                Edit Subscription
              </button>
            </div>
          </div>
        </div>
      )}
      {page === 1 ? (
        <div
          className="form-submit-btn"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loggedIn ? (
            <button
              type="button"
              onClick={() => history.push("/landing-dashboard")}
            >
              cancel
            </button>
          ) : (
            <div />
          )}
          <button
            type="button"
            onClick={() => setShowRefine(true)}
            className="preview-refine-btn"
          >
            Refine Selection
          </button>
          <button type="button" onClick={() => onSelectFinancing()}>
            Select Subscription
          </button>
        </div>
      ) : (
        <div
          className="form-submit-btn"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loggedIn ? (
            <>
              <button
                type="button"
                onClick={() => history.push("/landing-dashboard")}
              >
                cancel
              </button>
              <button
                type="button"
                onClick={() => setShowRefine(true)}
                className="preview-refine-btn"
              >
                Refine Selection
              </button>
              <button
                type="button"
                onClick={() => onSaveChanges()}
                className="preview-order-btn"
              >
                Save Changes
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setShowRefine(true)}
                className="preview-refine-btn"
              >
                Refine Selection
              </button>
              <button
                type="button"
                onClick={() => onPlaceOrder()}
                className="preview-order-btn"
              >
                Place Order
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomPreview;
