export const dash = [true, false, false, false, false, false, false];
export const compPricing = [false, true, false, false, false, false, false];
export const priceBook = [false, false, true, false, false, false, false];
export const plans = [false, false, false, true, false, false, false];
export const report = [false, false, false, false, true, false, false];
export const settings = [false, false, false, false, false, true, false];
export const logout = [false, false, false, false, false, false, true];

export const leads = [true, false, false, false];
export const prospects = [false, true, false, false];
export const audits = [false, false, true, false];
export const paying = [false, false, false, true];
