import React from "react";
import "./Billing.css";

const Billing = ({ userDetails }) => {
  const financeTable = userDetails.userFinance.finance_table;

  const firstUnpaid = financeTable.find(entry => !entry.paid);

  return (
    <div
      style={{ background: "#fff", borderRadius: "10px" }}
      className="billing"
    >
      <div className="payment-history">
        <span
          style={{
            display: "inline-block",
            marginRight: "30px",
            fontWeight: "600",
            fontSize: "14px",
            color: "#0D1071"
          }}
        >
          Payment History
        </span>
        <span
          style={{
            display: "inline-block",
            marginRight: "15px",
            fontWeight: "500",
            fontSize: "14px",
            color: "rgba(97, 96, 97, 0.6)"
          }}
        >
          {`Period:
          ${financeTable[0].paymentDate} -
          ${financeTable[financeTable.length - 1].paymentDate}
        `}
        </span>
        <span
          style={{
            display: "inline-block",
            marginRight: "15px",
            fontWeight: "500",
            fontSize: "14px",
            color: "rgba(97, 96, 97, 0.6)"
          }}
        >
          {`Next Pay Date: ${firstUnpaid.paymentDate}`}
        </span>
        <span className="download-statement">Download Statement</span>
      </div>
      <div style={{ overflowX: "auto", height: "400px" }}>
        <table>
          <thead>
            <tr className="headings">
              <th>Status</th>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>Balance Due</th>
            </tr>
          </thead>
          <tbody>
            {userDetails.userFinance.finance_table.map(bill => (
              <tr key={bill.beginningBalance}>
                <td className={`${bill.paid ? "four" : "seven"}`}>
                  <span
                    style={{
                      background: `${bill.paid ? "#75D33B" : "#FFCA42"}`,
                      borderRadius: "5px",
                      padding: `${bill.paid ? "3px 16px" : "3px 6px"}`
                    }}
                  >
                    {bill.paid ? "PAID" : "PENDING"}
                  </span>
                </td>
                <td>{bill.paymentDate}</td>
                <td style={{ textTransform: "capitalize" }}>Bank</td>
                <td>{bill.payment}</td>
                <td>{bill.endingBalance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Billing;
