import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../images/copy.png";
import "./UserWallet.css";

const UserWallet = ({ userDetails, refreshPage, notify, setLoading }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [input, setInput] = useState("");
  const [assets, setAssets] = useState([]);

  const onAddWalletAddress = () => {
    setLoading(true);

    if (!input) {
      notify("error", "Enter wallet address");
      setLoading(false);
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/wallet/addAddress`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          email: userDetails.userDetails.email,
          walletAddress: input
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setLoading(false);
            notify("success", data.message);

            setTimeout(() => {
              refreshPage();
            }, 2000);
          } else {
            setLoading(false);
            notify("error", data.message);
          }
        })
        .catch(() => {
          notify("error", "An error occurred");
        });
    }
  };

  useEffect(() => {
    if (userDetails.userWallet.wallet_address) {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/wallet/getAsstes/${userDetails.userDetails.email}`
      )
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setLoading(false);
            setAssets(data.responseData.assets);
          } else {
            setLoading(false);
            notify("error", data.message);
          }
        })
        .catch(() => {
          setLoading(true);
          notify("error", "An error occurred");
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="customer-info">
      <div className="wallet-cards">
        <div className="wallet-details">
          {userDetails.userWallet.wallet_address ? (
            <div>
              <p>{userDetails.userWallet.wallet_address}</p>
              <CopyToClipboard
                text={userDetails.userWallet.wallet_address}
                onCopy={() => setLinkCopied(true)}
              >
                <button type="button">
                  <img src={copy} alt="" />
                  Copy Address
                </button>
              </CopyToClipboard>
              {linkCopied && (
                <p className="referrals-link-copied">copied &#10003;</p>
              )}
            </div>
          ) : (
            <div>
              <p>No Address Added</p>
            </div>
          )}
        </div>
        {!userDetails.userWallet.wallet_address && (
          <div className="wallet-input-wrapper">
            <label htmlFor="wallet_address">Enter Wallet Address</label>
            <br />
            <input
              type="text"
              name="wallet_address"
              value={input}
              onChange={e => {
                setInput(e.target.value);
              }}
            />
            <br />
            <button type="button" onClick={onAddWalletAddress}>
              Add Address
            </button>
          </div>
        )}
      </div>
      {userDetails.userWallet.wallet_address && (
        <>
          <h4 className="customer-info-setion-header">Assets</h4>
          <div className="wallet-cards">
            {assets.map(asset => (
              <div key={asset.assetId} className="wallet-input-wrapper">
                <p className="asset-name">{asset.assetName}</p>
                <p className="asset-unit-name">{asset.assetUnitName}</p>
                <p className="asset-amount">
                  {asset.amount
                    ? asset.amount / 10 ** asset.decimals
                    : asset.amount}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
      <h4 className="customer-info-setion-header">Token Report</h4>
      {/* <h6 className="sub-heading">
        Installation Address:
        <span className="sub">
          {` ${userDetails.userDetails.installation_address}`}
        </span>
      </h6> */}
    </div>
  );
};

export default UserWallet;
