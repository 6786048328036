import React from "react";
import spinner from "../../images/spinner.gif";
import "./RootLoading.css";

const RootLoading = () => (
  <div className="root-loading-wrapper">
    <div className="root-loading-content">
      <img src={spinner} alt="loading..." />
    </div>
  </div>
);

export default RootLoading;
