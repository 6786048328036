import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./StandardSolution.css";
import Header from "../../Components/Header/Header";
import NewExisting from "../../Components/NewExisting/NewExisting";
import RightGrid from "../../Components/RightGrid/RightGrid";
import CustomFeedbackComp from "../../Components/customFeedbackComp/customFeedbackComp";
import BasicPlan from "../../images/bp-img.png";
import SilverPlan from "../../images/sp-img.png";
import GoldPlan from "../../images/gp-img.png";
import PlatinumPlan from "../../images/pp-img.png";

function StandardSolution({
  loggedIn,
  setLoading,
  setSelectedPlan,
  setRecommendedSystem,
  setPricebook,
  setAppliances,
  setBasicRecommended,
  customFeedback,
  setCustomFeedback
}) {
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [selectedSolution, setSelectedSolution] = useState();

  const history = useHistory();

  const planQuantity = {
    "STARTER SOLAR GENERATOR": ["starter_solar_generator_quantity"],
    "BASIC SOLAR GENERATOR": ["basic_solar_generator_quantity"],
    "ESSENTIAL SOLAR": ["essential_solar_quantity"],
    "STARTER SOLAR": ["starter_solar_quantity"],
    "STARTER PLUS SOLAR": ["starter_plus_solar_quantity"],
    "BASIC SOLAR": ["basic_solar_quantity"],
    "BASIC PLUS SOLAR": ["basic_plus_solar_quantity"],
    "PREMIUM SOLAR": ["premium_solar_quantity"],
    "SILVER SOLAR": ["silver_solar_quantity"],
    "GOLD SOLAR": ["gold_solar_quantity"],
    "PLATINUM SOLAR": ["platinum_solar_quantity"],
    "ESSENTIAL BACKUP": ["essential_bakup_quantity"],
    "STARTER BACKUP": ["starter_backup_quantity"],
    "STARTER PLUS BACKUP": ["starter_plus_backup_quantity"],
    "BASIC BACKUP": ["basic_backup_quantity"],
    "BASIC PLUS BACKUP": ["basic_plus_backup_quantity"],
    "PREMIUM BACKUP": ["premium_backup_quantity"],
    CUSTOM: ["custom_quantity"]
  };

  const solarType = {
    "STARTER SOLAR GENERATOR": "25% Solar",
    "BASIC SOLAR GENERATOR": "25% Solar",
    "ESSENTIAL SOLAR": "50% Solar",
    "STARTER SOLAR": "50% Solar",
    "STARTER PLUS SOLAR": "50% Solar",
    "BASIC SOLAR": "75% Solar",
    "BASIC PLUS SOLAR": "Off-Grid 100% Solar",
    "PREMIUM SOLAR": "Off-Grid 100% Solar",
    "SILVER SOLAR": "75% Solar",
    "GOLD SOLAR": "75% Solar",
    "PLATINUM SOLAR": "75% Solar",
    "ESSENTIAL BACKUP": "No Solar",
    "STARTER BACKUP": "No Solar",
    "STARTER PLUS BACKUP": "No Solar",
    "BASIC BACKUP": "No Solar",
    "BASIC PLUS BACKUP": "No Solar",
    "PREMIUM BACKUP": "No Solar"
  };

  const onSelectStandardSolution = plan => {
    window.gtag("event", "select_standard_solution", {
      event_category: "solution"
    });

    setSelectedPlan(plan);
    setSelectedSolution(plan);
    setCustomFeedback({
      battery_replacement_package: "Not Included",
      quarterly_maintenance_package: "Not Included",
      extended_warranty_package: "Not Included",
      solar_panels: solarType[plan]
    });

    if (
      plan === "STARTER SOLAR GENERATOR" ||
      plan === "BASIC SOLAR GENERATOR"
    ) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/solution/standardSolution`, {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          plan,
          customFeedback: {
            battery_replacement_package: "Not Included",
            quarterly_maintenance_package: "Not Included",
            extended_warranty_package: "Not Included",
            solar_panels: "No Solar"
          }
        })
      })
        .then(res => res.json())
        .then(data => {
          setRecommendedSystem(data.responseData.productsRecommendation);
          if (setPricebook) {
            setPricebook(data.responseData.pricebook);
          }

          const selectedAppliances = data.responseData.appliances.map(
            appliance => ({
              ...appliance,
              checked: Boolean(appliance[planQuantity[plan]]),
              custom_quantity: appliance[planQuantity[plan]]
            })
          );

          setAppliances(selectedAppliances);

          if (setBasicRecommended) {
            setBasicRecommended(data.responseData.basicRecommendationProducts);
          }

          history.push("/standard-preview");
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setShowCustomFeedback(true);
    }
  };

  const onCloseCustomFeedback = () => {
    setShowCustomFeedback(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="StandardSolution">
      {showCustomFeedback && (
        <CustomFeedbackComp
          onClose={onCloseCustomFeedback}
          userCustomFeedback={customFeedback}
          setUserCustomFeedback={setCustomFeedback}
          setRecommendedSystem={setRecommendedSystem}
          setAppliances={setAppliances}
          setPricebook={setPricebook}
          selectedPlan={selectedSolution}
          setBasicRecommended={setBasicRecommended}
          setLoading={setLoading}
        />
      )}
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div>{!loggedIn && <NewExisting selected="new" />}</div>
          <div className="standard-header">
            <p className="standard-header-text">Solar Solutions</p>
          </div>
          <div className="standard-plan-wrapper">
            <div
              className="standard-plan standard-plan-starter-solar"
              onClick={() => {
                onSelectStandardSolution("STARTER SOLAR GENERATOR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="STARTER SOLAR GENERATOR"
                />
                <p className="standard-plan-text">STARTER Solar Generator</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-solar"
              onClick={() => {
                onSelectStandardSolution("BASIC SOLAR GENERATOR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="BASIC SOLAR GENERATOR"
                />
                <p className="standard-plan-text">BASIC Solar Generator</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-solar"
              onClick={() => {
                onSelectStandardSolution("ESSENTIAL SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="ESSENTIAL SOLAR"
                />
                <p className="standard-plan-text">ESSENTIAL Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-solar"
              onClick={() => {
                onSelectStandardSolution("STARTER SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="STARTER SOLAR"
                />
                <p className="standard-plan-text">STARTER Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-plus-solar"
              onClick={() => {
                onSelectStandardSolution("STARTER PLUS SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="STARTER PLUS SOLAR"
                />
                <p className="standard-plan-text">STARTER PLUS Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-basic-solar"
              onClick={() => {
                onSelectStandardSolution("BASIC SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="BASIC SOLAR"
                />
                <p className="standard-plan-text">BASIC Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-basic-plus-solar"
              onClick={() => {
                onSelectStandardSolution("BASIC PLUS SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="BASIC PLUS SOLAR"
                />
                <p className="standard-plan-text">BASIC PLUS Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-premium-solar"
              onClick={() => {
                onSelectStandardSolution("PREMIUM SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="PREMIUM SOLAR"
                />
                <p className="standard-plan-text">PREMIUM Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-silver-solar"
              onClick={() => {
                onSelectStandardSolution("SILVER SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={SilverPlan}
                  className="standard-plan-img"
                  alt="SILVER SOLAR"
                />
                <p className="standard-plan-text">SILVER Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-gold-solar"
              onClick={() => {
                onSelectStandardSolution("GOLD SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={GoldPlan}
                  className="standard-plan-img"
                  alt="GOLD SOLAR"
                />
                <p className="standard-plan-text">GOLD Solar</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-platinum-solar"
              onClick={() => {
                onSelectStandardSolution("PLATINUM SOLAR");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={PlatinumPlan}
                  className="standard-plan-img"
                  alt="PLATINUM SOLAR"
                />
                <p className="standard-plan-text">PLATINUM Solar</p>
              </div>
            </div>
          </div>
          <div className="standard-header">
            <p className="standard-header-text">Backup Solutions</p>
          </div>
          <div className="standard-plan-wrapper">
            <div
              className="standard-plan standard-plan-starter-backup"
              onClick={() => {
                onSelectStandardSolution("ESSENTIAL BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="ESSENTIAL BACKUP"
                />
                <p className="standard-plan-text">ESSENTIAL Backup</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-backup"
              onClick={() => {
                onSelectStandardSolution("STARTER BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="STARTER BACKUP"
                />
                <p className="standard-plan-text">STARTER Backup</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-starter-plus-backup"
              onClick={() => {
                onSelectStandardSolution("STARTER PLUS BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="STARTER PLUS BACKUP"
                />
                <p className="standard-plan-text">STARTER PLUS Backup</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-basic-backup"
              onClick={() => {
                onSelectStandardSolution("BASIC BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="BASIC BACKUP"
                />
                <p className="standard-plan-text">BASIC Backup</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-basic-plus-backup"
              onClick={() => {
                onSelectStandardSolution("BASIC PLUS BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={BasicPlan}
                  className="standard-plan-img"
                  alt="BASIC PLUS BACKUP"
                />
                <p className="standard-plan-text">BASIC PLUS Backup</p>
              </div>
            </div>
            <div
              className="standard-plan standard-plan-premium-backup"
              onClick={() => {
                onSelectStandardSolution("PREMIUM BACKUP");
              }}
            >
              <div className="standard-plan-content">
                <img
                  src={SilverPlan}
                  className="standard-plan-img"
                  alt="PREMIUM BACKUP"
                />
                <p className="standard-plan-text">PREMIUM Backup</p>
              </div>
            </div>
          </div>
        </div>
        <RightGrid />
      </main>
    </div>
  );
}
export default StandardSolution;
