import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import vector2 from "../../images/Ellipse37.png";
import vector3 from "../../images/Ellipse38.png";
import vector4 from "../../images/Ellipse39.png";
import vector5 from "../../images/Ellipse40.png";
import noteIcon from "../../images/note.png";
import UserDetails from "../UserDetails/UserDetails";
import ActivateCustomer from "../AdvanceCustomer/ActivateCustomer";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import UserNote from "../UserNote/UserNote";

const Bin = ({ setLoading, notify, refreshPage }) => {
  const [page, setPage] = useState("ANALYTICS");
  const [allUsers, setAllusers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [activateCustomer, setActivateCustomer] = useState(false);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [userNote, setUserNote] = useState("");

  const onSelectUser = user => {
    setSelectedUser(user);
    setPage("USERDETAILS");
  };

  const statusIndicator = {
    NONE: "#CCCCCC",
    LOW: "#00FF00",
    MEDIUM: "#DAF034",
    FAIR: "#FFCA42",
    HIGH: "#FF0000",
    UNKNOWN: "#0000CC"
  };

  const topCount = allUsers.filter(user => user.user_score >= 75).length;
  const middleCount = allUsers.filter(
    user => user.user_score > 25 && user.user_score < 75
  ).length;
  const bottomCount = allUsers.filter(user => user.user_score <= 25).length;

  const onClickActivate = user => {
    setSelectedUser(user);
    setActivateCustomer(true);
  };

  const onClickDelete = user => {
    setSelectedUser(user);
    setDeleteCustomer(true);
  };

  const onClickNote = user => {
    setSelectedUser(user);
    setUserNote(user.user_note || "Enter a note about this user");
  };

  const onActivateCustomer = loginId => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/activate/${loginId}`, {
      method: "post",
      headers: { "content-Type": "application/json" }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  const onDeleteUser = userId => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/delete/${userId}`, {
      method: "delete",
      headers: { "content-Type": "application/json" }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  const onSaveUserNote = (userId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/addUserNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/users`)
      .then(res => res.json())
      .then(data => {
        setPage("ANALYTICS");
        setAllusers(
          data.responseData.filter(user => user.status === "DEACTIVATED")
        );
        setLoading(false);
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  return page === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedUser}
      notify={notify}
      setPage={setPage}
      basePage="Dashboard"
    />
  ) : (
    <div className="analytics">
      {activateCustomer && (
        <ActivateCustomer
          setActivateCustomer={setActivateCustomer}
          onActivateCustomer={onActivateCustomer}
          loginId={selectedUser.login_id}
        />
      )}
      {deleteCustomer && (
        <DeleteConfirmation
          setDeleteCustomer={setDeleteCustomer}
          onDeleteUser={onDeleteUser}
          content="Are you sure you want to delete this user. This action cannot be reversed?"
          userId={selectedUser.user_id}
        />
      )}
      {userNote && (
        <UserNote
          setUserNote={setUserNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedUser.user_id}
          defaultNote={userNote}
        />
      )}
      <div className="analytics-visitors">
        <div className="single">
          <img src={vector2} alt="" />
          <div>
            <span className="title">Total Deactivated</span>
            <span
              style={{ display: "block", color: "#4c9de8", fontWeight: "600" }}
            >
              {topCount + middleCount + bottomCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector3} alt="" />
          <div>
            <span className="title">Top Percentile</span>
            <span
              style={{ display: "block", color: "#95d33b", fontWeight: "600" }}
            >
              {topCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector4} alt="" />
          <div>
            <span className="title">Middle Percentile</span>
            <span
              style={{ display: "block", color: "#FFCA42", fontWeight: "600" }}
            >
              {middleCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector5} alt="" />
          <div>
            <span className="title">Bottom Percentile</span>
            <span
              style={{ display: "block", color: "#F01515", fontWeight: "600" }}
            >
              {bottomCount}
            </span>
          </div>
        </div>
      </div>
      <div className="barchart-container">
        <div style={{ position: "relative" }} className="bar-chart">
          <Chart
            width="100%"
            height="300px"
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Task", "Hours per Day"],
              ["Work", 0],
              ["Bottom Percentile", bottomCount],
              ["Middle Percentile", middleCount],
              ["Top Percentile", topCount],
              ["Sleep", 0]
            ]}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className="analytics-table-card">
          <p style={{ color: " #75D33B", fontSize: "16px", fontWeight: "600" }}>
            Top Percentile
          </p>
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map(
                  user =>
                    user.user_score >= 75 &&
                    user.type === "Lead" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#4C9DE8",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickActivate(user)}
                          >
                            Activate Customer
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#D20000",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickDelete(user)}
                          >
                            Delete Customer
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="analytics-table-card">
          <p style={{ color: "#FFCA42", fontSize: "18px", fontWeight: "600" }}>
            Middle Percentile
          </p>
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <td>Date</td>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map(
                  user =>
                    user.user_score > 25 &&
                    user.user_score < 75 &&
                    user.type === "Lead" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#4C9DE8",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickActivate(user)}
                          >
                            Activate Customer
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#D20000",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickDelete(user)}
                          >
                            Delete Customer
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="analytics-table-card">
          <p style={{ color: "#F01515", fontSize: "18px", fontWeight: "600" }}>
            Bottom Percentile
          </p>
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map(
                  user =>
                    user.user_score <= 25 &&
                    user.type === "Lead" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#4C9DE8",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickActivate(user)}
                          >
                            Activate Customer
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#D20000",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickDelete(user)}
                          >
                            Delete Customer
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bin;
