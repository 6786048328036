import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import vector1 from "../../images/Ellipse36.png";
import vector2 from "../../images/Ellipse37.png";
import vector3 from "../../images/Ellipse38.png";
import vector4 from "../../images/Ellipse39.png";
import vector5 from "../../images/Ellipse40.png";
import noteIcon from "../../images/note.png";
import searchIcon from "../../images/Group12.png";
import UserDetails from "../../Components/UserDetails/UserDetails";
import "./Analytics.css";
import { leads, prospects, audits, paying } from "./states";
import AdvanceCustomer from "../../Components/AdvanceCustomer/AdvanceCustomer";
import DeleteConfirmation from "../../Components/DeleteConfirmation/DeleteConfirmation";
import UserNote from "../../Components/UserNote/UserNote";

const Analytics = ({ setLoading, notify, refreshPage }) => {
  const [state, setState] = useState(leads);
  const [page, setPage] = useState("ANALYTICS");
  const [allUsers, setAllusers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [advanceCustomer, setAdvanceCustomer] = useState(false);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [userNote, setUserNote] = useState("");
  const [searchInput, setSearchInput] = useState({
    lead: "",
    prospect: "",
    audit: "",
    paying: ""
  });

  const handleLeads = () => {
    setState(leads);
  };
  const handleProspects = () => {
    setState(prospects);
  };
  const handleAudits = () => {
    setState(audits);
  };
  const handlePaying = () => {
    setState(paying);
  };

  const onSelectUser = user => {
    setSelectedUser(user);
    setPage("USERDETAILS");
  };

  const statusIndicator = {
    NONE: "#CCCCCC",
    LOW: "#00FF00",
    MEDIUM: "#DAF034",
    FAIR: "#FFCA42",
    HIGH: "#FF0000",
    UNKNOWN: "#0000CC"
  };

  const leadsCount = allUsers.filter(user => user.type === "Lead").length;
  const propsCount = allUsers.filter(user => user.type === "Prospect").length;
  const auditCount = allUsers.filter(user => user.type === "Audit").length;
  const payingCount = allUsers.filter(user => user.type === "Paying").length;
  const topCount = allUsers.filter(user => user.user_score >= 75).length;
  const middleCount = allUsers.filter(
    user => user.user_score > 25 && user.user_score < 75
  ).length;
  const bottomCount = allUsers.filter(user => user.user_score <= 25).length;

  const onClickAdvance = user => {
    setSelectedUser(user);
    setAdvanceCustomer(true);
  };

  const onClickDelete = user => {
    setSelectedUser(user);
    setDeleteCustomer(true);
  };

  const onClickNote = user => {
    setSelectedUser(user);
    setUserNote(user.user_note || "Enter a note about this user");
  };

  const onAdUser = (type, userId, testUser, message) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/advanceUser`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        type,
        userId,
        testUser,
        message
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  const onDeactivateUser = loginId => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/deactivate/${loginId}`, {
      method: "delete",
      headers: { "content-Type": "application/json" }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  const onSaveUserNote = (userId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/addUserNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/users`)
      .then(res => res.json())
      .then(data => {
        setPage("ANALYTICS");
        setAllusers(
          data.responseData.filter(user => user.status !== "DEACTIVATED")
        );
        setLoading(false);
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  return page === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedUser}
      notify={notify}
      setPage={setPage}
      basePage="Dashboard"
    />
  ) : (
    <div className="analytics">
      {advanceCustomer && (
        <AdvanceCustomer
          advanceCustomer={advanceCustomer}
          setAdvanceCustomer={setAdvanceCustomer}
          onAdUser={onAdUser}
          type={selectedUser.type}
          userId={selectedUser.user_id}
        />
      )}
      {deleteCustomer && (
        <DeleteConfirmation
          setDeleteCustomer={setDeleteCustomer}
          onDeleteUser={onDeactivateUser}
          content="Are you sure you want to deactivate this user?"
          userId={selectedUser.login_id}
        />
      )}
      {userNote && (
        <UserNote
          setUserNote={setUserNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedUser.user_id}
          defaultNote={userNote}
        />
      )}
      <h4>Analytics</h4>
      <div className="analytics-visitors">
        <div className="single">
          <img src={vector1} alt="" />
          <div>
            <span className="title">Total Visitors</span>
            <span
              style={{ display: "block", color: "#15294d", fontWeight: "600" }}
            >
              {topCount + middleCount + bottomCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector2} alt="" />
          <div>
            <span className="title">Total Customers</span>
            <span
              style={{ display: "block", color: "#4c9de8", fontWeight: "600" }}
            >
              {topCount + middleCount + bottomCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector3} alt="" />
          <div>
            <span className="title">Top Percentile</span>
            <span
              style={{ display: "block", color: "#95d33b", fontWeight: "600" }}
            >
              {topCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector4} alt="" />
          <div>
            <span className="title">Middle Percentile</span>
            <span
              style={{ display: "block", color: "#FFCA42", fontWeight: "600" }}
            >
              {middleCount}
            </span>
          </div>
        </div>
        <div className="single">
          <img src={vector5} alt="" />
          <div>
            <span className="title">Bottom Percentile</span>
            <span
              style={{ display: "block", color: "#F01515", fontWeight: "600" }}
            >
              {bottomCount}
            </span>
          </div>
        </div>
      </div>
      <div className="barchart-container">
        <div style={{ position: "relative" }} className="bar-chart">
          <Chart
            width="100%"
            height="300px"
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={[
              ["category", "customers"],
              ["Leads", leadsCount],
              ["Prospects", propsCount],
              ["Audit", auditCount],
              ["Paying", payingCount]
            ]}
            options={{
              // Material design options
              chart: {
                title: "IceVolt Customer Category Model"
              }
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
        <div style={{ position: "relative" }} className="bar-chart">
          <Chart
            width="100%"
            height="300px"
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Task", "Hours per Day"],
              ["Work", 0],
              ["Bottom Percentile", bottomCount],
              ["Middle Percentile", middleCount],
              ["Top Percentile", topCount],
              ["Sleep", 0]
            ]}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>
      <div className="leads-prospects" style={{ padding: "10px" }}>
        <span
          onClick={handleLeads}
          className={`${state[0] ? "active" : "disabled"}`}
        >
          Leads
        </span>
        <span
          onClick={handleProspects}
          className={`${state[1] ? "active" : "disabled"}`}
        >
          Prospects
        </span>
        <span
          onClick={handleAudits}
          className={`${state[2] ? "active" : "disabled"}`}
        >
          Prospects for Site Audit
        </span>
        <span
          onClick={handlePaying}
          className={`${state[3] ? "active" : "disabled"}`}
        >
          Paying Customers
        </span>
      </div>
      {state[0] && (
        <div style={{ marginTop: "20px" }}>
          <div className="analytics-serach-wrapper">
            <img src={searchIcon} alt="" />
            <input
              className="analytics-search"
              type="search"
              placeholder="Search by name..."
              onChange={
                e => setSearchInput({ ...searchInput, lead: e.target.value })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </div>
          <div className="analytics-table-card">
            <p
              style={{ color: " #75D33B", fontSize: "16px", fontWeight: "600" }}
            >
              Top Percentile
            </p>
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Credit Status</th>
                    <th>Advance</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase())
                    )
                    .map(
                      user =>
                        user.user_score >= 75 &&
                        user.type === "Lead" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#4C9DE8",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickAdvance(user)}
                              >
                                Advance Customer
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="analytics-table-card">
            <p
              style={{ color: "#FFCA42", fontSize: "18px", fontWeight: "600" }}
            >
              Middle Percentile
            </p>
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <td>Date</td>
                    <th>Credit Status</th>
                    <th>Advance</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase())
                    )
                    .map(
                      user =>
                        user.user_score > 25 &&
                        user.user_score < 75 &&
                        user.type === "Lead" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#4C9DE8",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickAdvance(user)}
                              >
                                Advance Customer
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="analytics-table-card">
            <p
              style={{ color: "#F01515", fontSize: "18px", fontWeight: "600" }}
            >
              Bottom Percentile
            </p>
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Credit Status</th>
                    <th>Advance</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.lead.toLowerCase())
                    )
                    .map(
                      user =>
                        user.user_score <= 25 &&
                        user.type === "Lead" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#4C9DE8",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickAdvance(user)}
                              >
                                Advance Customer
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {state[1] && (
        <div style={{ marginTop: "20px" }}>
          <div className="analytics-serach-wrapper">
            <img src={searchIcon} alt="" />
            <input
              className="analytics-search"
              type="search"
              placeholder="Search by name..."
              onChange={
                e =>
                  setSearchInput({ ...searchInput, prospect: e.target.value })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </div>
          <div className="analytics-table-card">
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Credit Status</th>
                    <th>Advance</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.prospect.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.prospect.toLowerCase())
                    )
                    .map(
                      user =>
                        user.type === "Prospect" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#4C9DE8",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickAdvance(user)}
                              >
                                Advance Customer
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {state[2] && (
        <div style={{ marginTop: "20px" }}>
          <div className="analytics-serach-wrapper">
            <img src={searchIcon} alt="" />
            <input
              className="analytics-search"
              type="search"
              placeholder="Search by name..."
              onChange={
                e => setSearchInput({ ...searchInput, audit: e.target.value })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </div>
          <div className="analytics-table-card">
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Credit Status</th>
                    <th>Advance</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.audit.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.audit.toLowerCase())
                    )
                    .map(
                      user =>
                        user.type === "Audit" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#4C9DE8",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickAdvance(user)}
                              >
                                Advance Customer
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {state[3] && (
        <div style={{ marginTop: "20px" }}>
          <div className="analytics-serach-wrapper">
            <img src={searchIcon} alt="" />
            <input
              className="analytics-search"
              type="search"
              placeholder="Search by name..."
              onChange={
                e => setSearchInput({ ...searchInput, paying: e.target.value })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </div>
          <div className="analytics-table-card">
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Credit Status</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers
                    .filter(
                      item =>
                        item.firstname
                          .toLowerCase()
                          .includes(searchInput.paying.toLowerCase()) ||
                        item.lastname
                          .toLowerCase()
                          .includes(searchInput.paying.toLowerCase())
                    )
                    .map(
                      user =>
                        user.type === "Paying" && (
                          <tr key={user.user_id}>
                            <td
                              style={{ cursor: "pointer", color: "gray" }}
                              onClick={() => onSelectUser(user)}
                            >
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>
                              {new Date(user.date).toLocaleDateString("en-GB")}
                            </td>
                            <td>
                              <span
                                style={{
                                  display: "inline-block",
                                  background: `${
                                    statusIndicator[user.credit_status]
                                  }`,
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "5px"
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: "#D20000",
                                  display: "inline-block",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => onClickDelete(user)}
                              >
                                Deactivate Customer
                              </span>
                            </td>
                            <td>
                              <img
                                onClick={() => onClickNote(user)}
                                className="noteIcon"
                                src={noteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
