import React, { useState } from "react";
import "./AdvanceCustomer.css";

const confirmPayment = ({ onConfirmPayment, setConfirmationOpen }) => {
  const [inputvalue, setInputValue] = useState("");

  return (
    <div className="advance-customer">
      <div
        className="advance-customer-background"
        onClick={() => setConfirmationOpen(false)}
      />
      <div className="content">
        <h4>Confirmation Message</h4>
        {/* <input type="text" name="" id="" /> */}
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          value={inputvalue}
          onChange={e => {
            setInputValue(e.target.value);
          }}
          placeholder="Type your reason here..."
        />
        <button
          type="button"
          onClick={() => {
            onConfirmPayment(inputvalue);
            setConfirmationOpen(false);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default confirmPayment;
