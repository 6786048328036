import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import Header from "../../Components/Header/Header";
import NewExisting from "../../Components/NewExisting/NewExisting";
import RightGrid from "../../Components/RightGrid/RightGrid";
import ThankYou from "../../Components/ThankYou/ThankYou";
import "./Register.css";

function Register({
  selectedPlan,
  customFeedback,
  recommendedSystem,
  appliances,
  selectedFinance,
  setLoading,
  notify
}) {
  const [thanksModal, setThanksModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onRegister = formData => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        userData: {
          ...formData,
          email: formData.email.toLowerCase()
        },
        plan: selectedPlan,
        customFeedback,
        recommendedSystem,
        appliances,
        selectedFinance
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);

          window.gtag("event", "place_an_order", {
            event_category: "conversions"
          });

          setThanksModal(true);
          notify("success", data.message);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.gtag("event", "registration_page_view", {
      event_category: "register"
    });
  }, []);

  return (
    <div className="RegisterPage">
      {thanksModal && <ThankYou />}
      <Header />
      <main className="fp-grid">
        <div className="grid1">
          <div>
            <NewExisting selected="exist" />
          </div>
          <Form onSubmit={handleSubmit(onRegister)} className="form-container">
            <p className="form-subheader">
              Please fill in the details to help determine the best solution
              suited to you.
            </p>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <input
                    className={
                      errors.firstname ? "form-input-error" : "form-input-tile"
                    }
                    name="firstname"
                    type="text"
                    {...register("firstname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.firstname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <input
                    className={
                      errors.lastname ? "form-input-error" : "form-input-tile"
                    }
                    name="lastname"
                    type="text"
                    {...register("lastname", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.lastname && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <input
                    className={
                      errors.email ? "form-input-error" : "form-input-tile"
                    }
                    name="email"
                    type="email"
                    {...register("email", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.email && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="phone_number">Phone Number</Label>
                  <input
                    className={
                      errors.phone_number
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="phone_number"
                    type="tel"
                    {...register("phone_number", {
                      required: true,
                      minLength: 6,
                      maxLength: 12
                    })}
                  />
                  <br />
                  {errors.phone_number && (
                    <p className="formfeedback">Enter a valid phone number</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="dob">Date Of Birth</Label>
                  <input
                    className={
                      errors.dob ? "form-input-error" : "form-input-tile"
                    }
                    name="dob"
                    type="date"
                    {...register("dob", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.dob && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="employment_status">Employment Status</Label>
                  <select
                    className={
                      errors.employment_status
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="employment_status"
                    {...register("employment_status", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Consulting or Other Ad Hoc Employment">
                      Consulting or Other Ad Hoc Employment
                    </option>
                    <option value="Contract Employment">
                      Contract Employment
                    </option>
                    <option value="Small Business Owner with predictable Cash Flow">
                      Small Business Owner with predictable Cash Flow
                    </option>
                    <option value="Medium and Large Business Owner with predictable Cash Flow">
                      Medium and Large Business Owner with predictable Cash Flow
                    </option>
                    <option value="Salaried Employment">
                      Salaried Employment
                    </option>
                  </select>
                  <br />
                  {errors.employment_status && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="installation_address">Installation Address</Label>
                  <input
                    className={
                      errors.installation_address
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="installation_address"
                    type="text"
                    {...register("installation_address", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.installation_address && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Installation_location_type">
                    Installation Location Type
                  </Label>
                  <select
                    className={
                      errors.Installation_location_type
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="Installation_location_type"
                    {...register("Installation_location_type", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Single Family Residence">
                      Single Family Residence
                    </option>
                    <option value="Small Business Premises">
                      Small Business Premises
                    </option>
                    <option value="Shared Multi Family Residence">
                      Multi Family Residence
                    </option>
                  </select>
                  <br />
                  {errors.Installation_location_type && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <input
                    className={
                      errors.country ? "form-input-error" : "form-input-tile"
                    }
                    name="country"
                    type="text"
                    value="Nigeria"
                    {...register("country", {
                      required: true
                    })}
                  />
                  <br />
                  {errors.country && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="state">State</Label>
                  <select
                    className={
                      errors.state ? "form-input-error" : "form-input-tile"
                    }
                    name="state"
                    {...register("state", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Abuja FCT">Abuja FCT</option>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                    <option value="Bauchi">Bauchi</option>
                    <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option>
                    <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option>
                    <option value="Kaduna">Kaduna</option>
                    <option value="Kano">Kano</option>
                    <option value="Kastina">Kastina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Nasarawa">Nasarawa</option>
                    <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>
                    <option value="Taraba">Taraba</option>
                    <option value="Yobe">Yobe</option>
                    <option value="Zamfara">Zamfara</option>
                  </select>
                  <br />
                  {errors.state && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="monthly_grid_power_cost">
                    Monthly Grid Power Cost
                  </Label>
                  <select
                    className={
                      errors.monthly_grid_power_cost
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="monthly_grid_power_cost"
                    {...register("monthly_grid_power_cost", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Less than N50,000">Less than N50,000</option>
                    <option value="N50,000 to N100,000">
                      N50,000 to N100,000
                    </option>
                    <option value="N100,000 to N200,000">
                      N100,000 to N200,000
                    </option>
                    <option value="N200,000 to N300,000">
                      N200,000 to N300,000
                    </option>
                    <option value="N300,000 to N400,000">
                      N300,000 to N400,000
                    </option>
                    <option value="N400,000 to N500,000">
                      N400,000 to N500,000
                    </option>
                    <option value="Above 500,000">Above 500,000</option>
                  </select>
                  <br />
                  {errors.monthly_grid_power_cost && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="monthly_fuel_cost_estimate">
                    Monthly Fuel Cost Estimate
                  </Label>
                  <select
                    className={
                      errors.monthly_fuel_cost_estimate
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="monthly_fuel_cost_estimate"
                    {...register("monthly_fuel_cost_estimate", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Less than N50,000">Less than N50,000</option>
                    <option value="N50,000 to N100,000">
                      N50,000 to N100,000
                    </option>
                    <option value="N100,000 to N200,000">
                      N100,000 to N200,000
                    </option>
                    <option value="N200,000 to N300,000">
                      N200,000 to N300,000
                    </option>
                    <option value="N300,000 to N400,000">
                      N300,000 to N400,000
                    </option>
                    <option value="N400,000 to N500,000">
                      N400,000 to N500,000
                    </option>
                    <option value="Zero">
                      Zero (I do not use generators as a power solution)
                    </option>
                  </select>
                  <br />
                  {errors.monthly_fuel_cost_estimate && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="monthly_power_need_cost">
                    Monthly Power Needs Cost
                  </Label>
                  <select
                    className={
                      errors.monthly_power_need_cost
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="monthly_power_need_cost"
                    {...register("monthly_power_need_cost", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="N50,000 to N100,000">
                      N50,000 to N100,000
                    </option>
                    <option value="N100,000 to N250,000">
                      N100,000 to N250,000
                    </option>
                    <option value="N250,000 to N500,000">
                      N250,000 to N500,000
                    </option>
                    <option value="Above 500,000">Above N500,000</option>
                  </select>
                  <br />
                  {errors.monthly_power_need_cost && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <legend>Do you own a Power Generator? (Diesel or Petrol)</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="power_generator"
                        {...register("power_generator", {
                          required: true
                        })}
                        value="Yes"
                      />
                      Yes
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="power_generator"
                        {...register("power_generator", {
                          required: true
                        })}
                        value="No"
                      />
                      No
                    </Label>
                  </div>
                </div>
                {errors.power_generator && (
                  <p className="formfeedback">Select an option</p>
                )}
              </div>
            </Row>
            <Row className="form-row">
              <legend>Are you connected to Grid Power?</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="grid_power"
                        {...register("grid_power", {
                          required: true
                        })}
                        value="Yes"
                      />
                      Yes
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="grid_power"
                        {...register("grid_power", {
                          required: true
                        })}
                        value="No"
                      />
                      No
                    </Label>
                  </div>
                </div>
                {errors.grid_power && (
                  <p className="formfeedback">Select an option</p>
                )}
              </div>
            </Row>
            <Row className="form-row">
              <legend>
                Do you own Solar Panels or any other form of clean energy
                solution
              </legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="clean_energy"
                        {...register("clean_energy", {
                          required: true
                        })}
                        value="Yes"
                      />
                      Yes
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="clean_energy"
                        {...register("clean_energy", {
                          required: true
                        })}
                        value="No"
                      />
                      No
                    </Label>
                  </div>
                </div>
                {errors.clean_energy && (
                  <p className="formfeedback">Select an option</p>
                )}
              </div>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="credit_service_familiarity">
                    How familiar are you with Credit Services for making major
                    purchases? (like Home Mortgage, Car Loans etc.)
                  </Label>
                  <select
                    className={
                      errors.credit_service_familiarity
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="credit_service_familiarity"
                    {...register("credit_service_familiarity", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="I have never used a Credit Service to finance a major purchase">
                      I have never used a Credit Service to finance a major
                      purchase
                    </option>
                    <option value="I have an active Credit Loan account and make periodic payments">
                      I have an active Credit Loan account and make periodic
                      payments
                    </option>
                    <option value="I have used a Credit Service in the past, but currently do not have outstanding loans">
                      I have used a Credit Service in the past, but currently do
                      not have outstanding loans
                    </option>
                  </select>
                  <br />
                  {errors.credit_service_familiarity && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={10}>
                <FormGroup>
                  <Label for="next_upgrade">
                    When do you expect to make the next investment or upgrade to
                    your power system?
                  </Label>
                  <select
                    className={
                      errors.next_upgrade
                        ? "form-input-error"
                        : "form-input-tile"
                    }
                    name="next_upgrade"
                    {...register("next_upgrade", {
                      required: true
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Within One (1) Month">
                      Within One (1) Month
                    </option>
                    <option value="Within Six (6) Months">
                      Within Six (6) Months
                    </option>
                    <option value="Within One (1) Year">
                      Within One (1) Year
                    </option>
                    <option value="Within Two (2) Yeasr">
                      Within Two (2) Years
                    </option>
                    <option value="Within Three (3) Years">
                      Within Three (3) Years
                    </option>
                  </select>
                  <br />
                  {errors.next_upgrade && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={12}>
                <FormGroup>
                  <Label for="agent_code">
                    Were you invited by an agent? Enter agent's code
                  </Label>
                  <input
                    className={
                      errors.agent_code ? "form-input-error" : "form-input-tile"
                    }
                    name="agent_code"
                    type="text"
                    {...register("agent_code", {
                      required: false
                    })}
                  />
                  <br />
                  {errors.agent_code && (
                    <p className="formfeedback">This field cannot be empty</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="form-submit-btn">
              <button type="submit">Complete</button>
            </div>
          </Form>
        </div>
        <RightGrid />
      </main>
    </div>
  );
}

export default Register;
