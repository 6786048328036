/* eslint-disable react/no-array-index-key */
import React from "react";
import { Form, Row, Col } from "reactstrap";
import { Chart } from "react-google-charts";
import "./CustomerDashboardComp.css";

const CustomerDashboardComp = ({ userDetails }) => {
  const reservationFeeValue = {
    0.05: "Low",
    0.1: "Medium",
    0.15: "High"
  };

  const graphBarChart = userDetails.userFinance.graph_table.map(row => [
    row.year.toString(),
    row.ownerPayments
  ]);

  const landingRecommendedSystem = userDetails.userRecommendedSystem;

  return (
    <div className="CustomerDashboardComp">
      <div className="reservation-fee-monthly-payment">
        <a
          href="https://flutterwave.com/pay/icevolt-flatrate-bill"
          target="_blank"
          rel="noreferrer"
        >
          Pay Monthly Flat Rate Utility Bill
        </a>
      </div>
      <div className="user-details-payment-graph">
        <Chart
          width="100%"
          height="300px"
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={[["year", "Owner Payments"], ...graphBarChart]}
          options={{
            // Material design options
            chart: {
              title: "Icevolt Green Energy Serivices Ownership Model"
            }
          }}
          // For tests
          rootProps={{ "data-testid": "2" }}
        />
      </div>
      <div className="preview-selection">
        <div className="preview-header">
          <p className="preview-header-text">My Selection</p>
        </div>
        <div className="preview-body">
          <p className="preview-plan-text">{userDetails.userDetails.plan}</p>
        </div>
      </div>
      {userDetails.userDetails.plan === "CUSTOM" ? (
        <div className="preview-selection-details">
          <Form className="form-container">
            <h5 className="mb-3 form-header">Selection Details</h5>
            <div className="refine-details">
              <div className="refine-details-header">
                <p className="refine-details-header-text">
                  Regular Household Appliances
                </p>
                <p className="refine-details-header-subtext">
                  Low consumption, Daily Use
                </p>
              </div>
              <div className="refine-details-body">
                <div className="appliance-wrapper-label">
                  <div className="appliance-checkbox-name-label" />
                  <div className="appliance-quantity-label">
                    <p>Qty</p>
                  </div>
                  <div className="appliance-wattage-label">
                    <p>wattage</p>
                  </div>
                  <div className="appliance-usage-label">
                    <p>Daily Usage(Hrs)</p>
                  </div>
                </div>
                <div className="appliance-wrapper-label appliance-label2">
                  <div className="appliance-checkbox-name-label" />
                  <div className="appliance-quantity-label">
                    <p>Qty</p>
                  </div>
                  <div className="appliance-wattage-label">
                    <p>wattage</p>
                  </div>
                  <div className="appliance-usage-label">
                    <p>Daily Usage(Hrs)</p>
                  </div>
                </div>
                {userDetails.userRecommendedSystem.appliances.map(
                  appliance =>
                    appliance.appliance_category ===
                      "Regular Household Appliances" &&
                    appliance.checked && (
                      <div
                        className="appliance-wrapper"
                        key={appliance.appliance_id}
                      >
                        <div className="appliance-checkbox-name">
                          {appliance.appliance_name}
                        </div>
                        <div className="appliance-quantity">
                          <p>{appliance.custom_quantity}</p>
                        </div>
                        <div className="appliance-wattage">
                          <div className="appliance-wattage-input-wrapper">
                            <input
                              className="appliance-wattage-input"
                              disabled
                              value={appliance.wattage}
                            />
                            <p>W</p>
                          </div>
                        </div>
                        <div className="appliance-usage">
                          <div className="appliance-usage-input-wrapper">
                            <input
                              className="appliance-usage-input"
                              disabled
                              value={appliance.daily_run_hours}
                            />
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="refine-details">
              <div className="refine-details-header">
                <p className="refine-details-header-text">
                  High Power Appliances
                </p>
                <p className="refine-details-header-subtext">
                  High consumption, Regular Use
                </p>
              </div>
              <div className="refine-details-body">
                <div className="appliance-wrapper-label">
                  <div className="appliance-checkbox-name-label" />
                  <div className="appliance-quantity-label">
                    <p>Qty</p>
                  </div>
                  <div className="appliance-wattage-label">
                    <p>wattage</p>
                  </div>
                  <div className="appliance-usage-label">
                    <p>Daily Usage(Hrs)</p>
                  </div>
                </div>
                <div className="appliance-wrapper-label appliance-label2">
                  <div className="appliance-checkbox-name-label" />
                  <div className="appliance-quantity-label">
                    <p>Qty</p>
                  </div>
                  <div className="appliance-wattage-label">
                    <p>wattage</p>
                  </div>
                  <div className="appliance-usage-label">
                    <p>Daily Usage(Hrs)</p>
                  </div>
                </div>
                {userDetails.userRecommendedSystem.appliances.map(
                  appliance =>
                    appliance.appliance_category === "High Power Appliances" &&
                    appliance.checked && (
                      <div
                        className="appliance-wrapper"
                        key={appliance.appliance_id}
                      >
                        <div className="appliance-checkbox-name">
                          {appliance.appliance_name}
                        </div>
                        <div className="appliance-quantity">
                          <p>{appliance.custom_quantity}</p>
                        </div>
                        <div className="appliance-wattage">
                          <div className="appliance-wattage-input-wrapper">
                            <input
                              className="appliance-wattage-input"
                              value={appliance.wattage}
                              disabled
                            />
                            <p>W</p>
                          </div>
                        </div>
                        <div className="appliance-usage">
                          <div className="appliance-usage-input-wrapper">
                            <input
                              className="appliance-usage-input"
                              value={appliance.daily_run_hours}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <Row>
              <Col>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">
                      Envac Proprietary Thermal Storage Equipment (Air
                      Conditioning and Water Heater)
                    </p>
                    <p className="refine-details-header-subtext" />
                  </div>
                  <div className="refine-details-body">
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "Envac Proprietary Thermal Storage Equipment (Air Conditioning and Water Heater)" &&
                        appliance.checked && (
                          <div
                            className="grid-appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                            <div className="appliance-wattage">
                              <div className="appliance-wattage-input-wrapper">
                                <input
                                  className="appliance-wattage-input"
                                  value={appliance.wattage}
                                  disabled
                                />
                                <p>W</p>
                              </div>
                            </div>
                            <div className="appliance-usage">
                              <div className="appliance-usage-input-wrapper">
                                <input
                                  className="appliance-usage-input"
                                  value={appliance.daily_run_hours}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">
                      Lighthing Requirements
                    </p>
                    <p className="refine-details-header-subtext" />
                  </div>
                  <div className="refine-details-body">
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "Lighting Requirements" &&
                        appliance.checked && (
                          <div
                            className="grid-appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                            <div className="appliance-wattage">
                              <div className="appliance-wattage-input-wrapper">
                                <input
                                  className="appliance-wattage-input"
                                  value={appliance.wattage}
                                  disabled
                                />
                                <p>W</p>
                              </div>
                            </div>
                            <div className="appliance-usage">
                              <div className="appliance-usage-input-wrapper">
                                <input
                                  className="appliance-usage-input"
                                  value={appliance.daily_run_hours}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">Others</p>
                    <p className="refine-details-header-subtext">
                      Custom Appliances
                    </p>
                  </div>
                  <div className="refine-details-body">
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category === "Others" &&
                        appliance.checked && (
                          <div
                            className="grid-appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                            <div className="appliance-wattage">
                              <div className="appliance-wattage-input-wrapper">
                                <input
                                  className="appliance-wattage-input"
                                  value={appliance.wattage}
                                  disabled
                                />
                                <p>W</p>
                              </div>
                            </div>
                            <div className="appliance-usage">
                              <div className="appliance-usage-input-wrapper">
                                <input
                                  className="appliance-usage-input"
                                  value={appliance.daily_run_hours}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col>
              {/* <Col>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">
                      Water Heater Points
                    </p>
                    <p className="refine-details-header-subtext" />
                  </div>
                  <div className="refine-details-body">
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "Water Heater Points" && (
                          <div
                            className="grid-appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col> */}
            </Row>
            <Row>
              <div className="preview-body custom-preview-selection-details">
                <div className="selection-details-element">
                  <p className="selection-details-element-label">
                    Battery Replacement Package
                  </p>
                  <p className="selection-details-element-value">
                    {userDetails.userCustomFeedback.battery_replacement_package}
                  </p>
                </div>
                <div className="selection-details-element">
                  <p className="selection-details-element-label">
                    Quaterly Maintenance Package
                  </p>
                  <p className="selection-details-element-value">
                    {
                      userDetails.userCustomFeedback
                        .quarterly_maintenance_package
                    }
                  </p>
                </div>
                <div className="selection-details-element">
                  <p className="selection-details-element-label">
                    Extended Warranty Package
                  </p>
                  <p className="selection-details-element-value">
                    {userDetails.userCustomFeedback.extended_warranty_package}
                  </p>
                </div>
                <div className="selection-details-element" />
              </div>
            </Row>
            <h5 className="mb-3 form-header">Design Load Calculations</h5>
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Start Up Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalStartUpPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.thermalBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Energy Use(WH)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalEnergy}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Adjustment for Sunlight Intensity
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.sunlightAdjustment}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalThermalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.totalThermalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Main Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.mainBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element" />
            </div>
            <h5 className="mb-3 form-header">Equipment Specification</h5>
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Inverter Capacity
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.products["Inverter Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Battery Bank Capacity
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.products["Battery Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Storage Unit (EnVAC)
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.products["AC PACKAGE"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Solar Panel Sizing
                </p>
                <p className="selection-details-element-value">
                  {Number(
                    landingRecommendedSystem.products["Solar Package"].rating
                  )}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Water Heater Capacity
                </p>
                <p className="selection-details-element-value">
                  {landingRecommendedSystem.products["WATER HEATER"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Space Cooling (Nature Breeze)
                </p>
                <p className="selection-details-element-value">
                  {
                    landingRecommendedSystem.products["Nature Breeze Package"]
                      .rating
                  }
                </p>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <div className="preview-description">
          <div className="preview-header">
            <p className="preview-header-text">Description</p>
          </div>
          <div className="preview-body">
            <p className="preview-body-text">
              {userDetails.userRecommendedSystem.description}
            </p>
            <p className="recommended-details-tile-body-text">
              {userDetails.userRecommendedSystem.sub_description}
            </p>
          </div>
        </div>
      )}
      <div className="preview-recommended">
        <div className="preview-header">
          <p className="preview-header-text">Recommended System</p>
        </div>
        <div className="preview-body">
          <p className="preview-body-text">
            {userDetails.userRecommendedSystem.make}
          </p>
        </div>
      </div>
      {userDetails.userDetails.plan !== "CUSTOM" && (
        <>
          <div className="preview-recommended-details">
            <div className="preview-header">
              <p className="preview-header-text">Recommended System Details</p>
            </div>
            <div className="preview-body recommended-details-body">
              {Object.keys(landingRecommendedSystem.products).map(
                productKey =>
                  productKey !== "IceVolt Operating Margin" && (
                    <div className="recommended-details-tile" key={productKey}>
                      <div className="recommended-details-tile-header">
                        <p className="recommended-details-tile-header-text">
                          {productKey}
                        </p>
                        <p className="recommended-details-tile-header-text">{`Qty: ${landingRecommendedSystem.products[productKey].quantity}`}</p>
                      </div>
                      <div className="recommended-details-tile-body">
                        {landingRecommendedSystem.products[
                          productKey
                        ].components.map((component, i) => (
                          <p
                            className="recommended-details-tile-body-text"
                            key={`${component}${i}`}
                          >
                            {component}
                          </p>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <div>
            <div className="preview-body custom-preview-selection-details">
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Battery Replacement Package
                </p>
                <p className="selection-details-element-value">
                  {userDetails.userCustomFeedback.battery_replacement_package}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Quaterly Maintenance Package
                </p>
                <p className="selection-details-element-value">
                  {userDetails.userCustomFeedback.quarterly_maintenance_package}
                </p>
              </div>
              {(userDetails.userDetails.plan === "PREMIUM SOLAR" ||
                userDetails.userDetails.plan === "SILVER SOLAR" ||
                userDetails.userDetails.plan === "GOLD SOLAR" ||
                userDetails.userDetails.plan === "PLATINUM SOLAR" ||
                userDetails.userDetails.plan === "PREMIUM BACKUP") && (
                <div className="selection-details-element">
                  <p className="selection-details-element-label">
                    Extended Warranty Package
                  </p>
                  <p className="selection-details-element-value">
                    {userDetails.userCustomFeedback.extended_warranty_package}
                  </p>
                </div>
              )}
              <div className="selection-details-element" />
            </div>
          </div>
        </>
      )}
      <div className="preview-financing">
        <div className="preview-header">
          <p className="preview-header-text">My Selected Financing</p>
        </div>
        <div className="preview-body custom-preview-selection-details">
          <div className="selection-details-element">
            <p className="selection-details-element-label">Reservation Fee</p>
            <p className="selection-details-element-value">
              {
                reservationFeeValue[
                  Number(userDetails.userFinance.down_payment)
                ]
              }
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              Power Contract Duration in years
            </p>
            <p className="selection-details-element-value">
              {userDetails.userFinance.loan_period}
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              Monthly Flat Rate Utility Bill
            </p>
            <p className="selection-details-element-value">
              {`₦${Number(
                userDetails.userFinance.monthly_payment
              ).toLocaleString()}`}
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              Amount of Reservation Fee
            </p>
            <p className="selection-details-element-value">
              {`₦${Number(
                userDetails.userFinance.amount_of_down_payment
              ).toLocaleString()}`}
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              Number of Payments
            </p>
            <p className="selection-details-element-value">
              {userDetails.userFinance.number_of_payments}
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              Start Date of Power Contract
            </p>
            <p className="selection-details-element-value">
              {`${new Date(
                userDetails.userFinance.start_date_of_loan
              ).toDateString()}`}
            </p>
          </div>
          <div className="selection-details-element">
            <p className="selection-details-element-label">
              End Date of Power Contract
            </p>
            <p className="selection-details-element-value">
              {new Date(
                userDetails.userFinance.finance_table[
                  userDetails.userFinance.finance_table.length - 1
                ].paymentDate
              ).toDateString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboardComp;
