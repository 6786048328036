import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import {
  // NatureBreezeTooltip,
  // EnvacWaterTooltip,
  EnvaStorezTooltip
} from "../Tooltip/Tooltip";
import getProductRecommendation from "./recommendationHandler";
import getFinancing from "./financeHandler";
import close from "../../svg/close.svg";
import plus from "../../svg/plus.svg";
import minus from "../../svg/minus.svg";
import "./RefineSelection.css";

function RefineSelection({
  onClose,
  userCustomFeedback,
  setUserCustomFeedback,
  recommendedSystem,
  setRecommendedSystem,
  selectedFinance,
  setSelectedFinance,
  appliances,
  setAppliances,
  pricebook,
  basicRecommended,
  selectedPlan
}) {
  const [customFeedback, setCustomFeedback] = useState(
    userCustomFeedback || {}
  );
  const [refineRecommended, setRefineRecommended] = useState(
    recommendedSystem || {}
  );

  const [selectedAppliances, setSelectedAppliances] = useState(appliances);

  const addRemoveAppliance = (appliance_id, value) => {
    const newAppliances = selectedAppliances.map(appliance => {
      if (appliance.appliance_id === appliance_id) {
        return { ...appliance, checked: value, custom_quantity: value ? 1 : 0 };
      }

      return appliance;
    });

    setSelectedAppliances(newAppliances);
  };

  const updateApplianceQuantity = (appliance_id, operation) => {
    const newAppliances = selectedAppliances.map(appliance => {
      if (appliance.appliance_id === appliance_id) {
        if (operation === "add" && appliance.custom_quantity < 50) {
          return {
            ...appliance,
            checked: true,
            custom_quantity: appliance.custom_quantity + 1
          };
        }
        if (operation === "subtract" && appliance.custom_quantity > 0) {
          return {
            ...appliance,
            checked: !(appliance.custom_quantity === 1),
            custom_quantity: appliance.custom_quantity - 1
          };
        }
      }

      return appliance;
    });

    setSelectedAppliances(newAppliances);
  };

  const updateApplianceWattage = (appliance_id, value) => {
    const newAppliances = selectedAppliances.map(appliance => {
      if (appliance.appliance_id === appliance_id) {
        if (value >= 1 && value <= 5000) {
          return { ...appliance, wattage: value };
        }
      }

      return appliance;
    });

    setSelectedAppliances(newAppliances);
  };

  const updateRunHours = (appliance_id, value) => {
    const newAppliances = selectedAppliances.map(appliance => {
      if (appliance.appliance_id === appliance_id) {
        if (value >= 0.5 && value <= 24) {
          return { ...appliance, daily_run_hours: value };
        }
      }

      return appliance;
    });

    setSelectedAppliances(newAppliances);
  };

  const onSelectSolarPanel = e => {
    setCustomFeedback({
      ...customFeedback,
      solar_panels: e.target.value
    });
  };

  const onSelectBatRepPack = e => {
    setCustomFeedback({
      ...customFeedback,
      battery_replacement_package: e.target.value
    });
  };

  const onSelectQuaMainPack = e => {
    setCustomFeedback({
      ...customFeedback,
      quarterly_maintenance_package: e.target.value
    });
  };

  const onSelectExtWarPack = e => {
    setCustomFeedback({
      ...customFeedback,
      extended_warranty_package: e.target.value
    });
  };

  const onSubmitSolution = () => {
    setRecommendedSystem(refineRecommended);
    setAppliances(selectedAppliances);
    setUserCustomFeedback(customFeedback);
    if (selectedFinance) {
      getFinancing(selectedFinance, refineRecommended, setSelectedFinance);
    }
    onClose(true);
  };

  useEffect(async () => {
    setRefineRecommended(
      await getProductRecommendation(
        customFeedback,
        selectedAppliances,
        pricebook,
        basicRecommended,
        selectedPlan
      )
    );
  }, [selectedAppliances, customFeedback]);

  return (
    <div className="Refine-selection-wrapper">
      <div className="Refine-selection-modal">
        <div className="close-icon-wrapper">
          <img
            className="close-icon"
            src={close}
            alt=""
            onClick={() => onClose()}
          />
        </div>
        <Form className="form-container">
          <h5 className="mb-3 form-header">Refine Selection</h5>
          <div className="refine-details">
            <div className="refine-details-header">
              <p className="refine-details-header-text">
                Regular Household Appliances
              </p>
              <p className="refine-details-header-subtext">
                Low consumption, Daily Use
              </p>
            </div>
            <div className="refine-details-body">
              <div className="appliance-wrapper-label">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              <div className="appliance-wrapper-label appliance-label2">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              {selectedAppliances.map(
                appliance =>
                  appliance.appliance_category ===
                    "Regular Household Appliances" && (
                    <div
                      className="appliance-wrapper"
                      key={appliance.appliance_id}
                    >
                      <div className="appliance-checkbox-name">
                        <input
                          type="checkbox"
                          name={appliance.appliance_name}
                          checked={appliance.checked}
                          onChange={e => {
                            addRemoveAppliance(
                              appliance.appliance_id,
                              e.target.checked
                            );
                          }}
                        />
                        {appliance.appliance_name}
                      </div>
                      <div className="appliance-quantity">
                        <img
                          src={minus}
                          alt=""
                          onClick={() => {
                            updateApplianceQuantity(
                              appliance.appliance_id,
                              "subtract"
                            );
                          }}
                        />
                        <p>{appliance.custom_quantity}</p>
                        <img
                          src={plus}
                          alt=""
                          onClick={() => {
                            updateApplianceQuantity(
                              appliance.appliance_id,
                              "add"
                            );
                          }}
                        />
                      </div>
                      <div className="appliance-wattage">
                        <div className="appliance-wattage-input-wrapper">
                          <input
                            className="appliance-wattage-input"
                            value={appliance.wattage}
                            onChange={e => {
                              updateApplianceWattage(
                                appliance.appliance_id,
                                e.target.value
                              );
                            }}
                          />
                          <p>W</p>
                        </div>
                      </div>
                      <div className="appliance-usage">
                        <div className="appliance-usage-input-wrapper">
                          <input
                            className="appliance-usage-input"
                            value={appliance.daily_run_hours}
                            onChange={e => {
                              updateRunHours(
                                appliance.appliance_id,
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="refine-details">
            <div className="refine-details-header">
              <p className="refine-details-header-text">
                High Power Appliances
              </p>
              <p className="refine-details-header-subtext">
                High consumption, Regular Use
              </p>
            </div>
            <div className="refine-details-body">
              <div className="appliance-wrapper-label">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              <div className="appliance-wrapper-label appliance-label2">
                <div className="appliance-checkbox-name-label" />
                <div className="appliance-quantity-label">
                  <p>Qty</p>
                </div>
                <div className="appliance-wattage-label">
                  <p>wattage</p>
                </div>
                <div className="appliance-usage-label">
                  <p>Daily Usage(Hrs)</p>
                </div>
              </div>
              {selectedAppliances.map(
                appliance =>
                  appliance.appliance_category === "High Power Appliances" && (
                    <div
                      className="appliance-wrapper"
                      key={appliance.appliance_id}
                    >
                      <div className="appliance-checkbox-name">
                        <input
                          type="checkbox"
                          name={appliance.appliance_name}
                          checked={appliance.checked}
                          onChange={e => {
                            addRemoveAppliance(
                              appliance.appliance_id,
                              e.target.checked
                            );
                          }}
                        />
                        {appliance.appliance_name}
                      </div>
                      <div className="appliance-quantity">
                        <img
                          src={minus}
                          alt=""
                          onClick={() => {
                            updateApplianceQuantity(
                              appliance.appliance_id,
                              "subtract"
                            );
                          }}
                        />
                        <p>{appliance.custom_quantity}</p>
                        <img
                          src={plus}
                          alt=""
                          onClick={() => {
                            updateApplianceQuantity(
                              appliance.appliance_id,
                              "add"
                            );
                          }}
                        />
                      </div>
                      <div className="appliance-wattage">
                        <div className="appliance-wattage-input-wrapper">
                          <input
                            className="appliance-wattage-input"
                            value={appliance.wattage}
                            onChange={e => {
                              updateApplianceWattage(
                                appliance.appliance_id,
                                e.target.value
                              );
                            }}
                          />
                          <p>W</p>
                        </div>
                      </div>
                      <div className="appliance-usage">
                        <div className="appliance-usage-input-wrapper">
                          <input
                            className="appliance-usage-input"
                            value={appliance.daily_run_hours}
                            onChange={e => {
                              updateRunHours(
                                appliance.appliance_id,
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <Row>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header has-tooltip">
                  <p className="refine-details-header-text">
                    Envac Proprietary Thermal Storage Equipment (Air
                    Conditioning and Water Heater)
                  </p>
                  <EnvaStorezTooltip />
                  <p className="refine-details-header-subtext" />
                </div>
                <div className="refine-details-body">
                  {selectedAppliances.map(
                    appliance =>
                      appliance.appliance_category ===
                        "Envac Proprietary Thermal Storage Equipment (Air Conditioning and Water Heater)" && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            <input
                              type="checkbox"
                              name={appliance.appliance_name}
                              checked={appliance.checked}
                              onChange={e => {
                                addRemoveAppliance(
                                  appliance.appliance_id,
                                  e.target.checked
                                );
                              }}
                            />
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <img
                              src={minus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "subtract"
                                );
                              }}
                            />
                            <p>{appliance.custom_quantity}</p>
                            <img
                              src={plus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "add"
                                );
                              }}
                            />
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                onChange={e => {
                                  updateApplianceWattage(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                onChange={e => {
                                  updateRunHours(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">
                    Lighthing Requirements
                  </p>
                  <p className="refine-details-header-subtext" />
                </div>
                <div className="refine-details-body">
                  {selectedAppliances.map(
                    appliance =>
                      appliance.appliance_category ===
                        "Lighting Requirements" && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            <input
                              type="checkbox"
                              name={appliance.appliance_name}
                              checked={appliance.checked}
                              onChange={e => {
                                addRemoveAppliance(
                                  appliance.appliance_id,
                                  e.target.checked
                                );
                              }}
                            />
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <img
                              src={minus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "subtract"
                                );
                              }}
                            />
                            <p>{appliance.custom_quantity}</p>
                            <img
                              src={plus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "add"
                                );
                              }}
                            />
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                onChange={e => {
                                  updateApplianceWattage(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                onChange={e => {
                                  updateRunHours(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="refine-details">
                <div className="refine-details-header">
                  <p className="refine-details-header-text">Others</p>
                  <p className="refine-details-header-subtext">
                    Custom Appliances
                  </p>
                </div>
                <div className="refine-details-body">
                  {selectedAppliances.map(
                    appliance =>
                      appliance.appliance_category === "Others" && (
                        <div
                          className="grid-appliance-wrapper"
                          key={appliance.appliance_id}
                        >
                          <div className="appliance-checkbox-name">
                            <input
                              type="checkbox"
                              name={appliance.appliance_name}
                              checked={appliance.checked}
                              onChange={e => {
                                addRemoveAppliance(
                                  appliance.appliance_id,
                                  e.target.checked
                                );
                              }}
                            />
                            {appliance.appliance_name}
                          </div>
                          <div className="appliance-quantity">
                            <img
                              src={minus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "subtract"
                                );
                              }}
                            />
                            <p>{appliance.custom_quantity}</p>
                            <img
                              src={plus}
                              alt=""
                              onClick={() => {
                                updateApplianceQuantity(
                                  appliance.appliance_id,
                                  "add"
                                );
                              }}
                            />
                          </div>
                          <div className="appliance-wattage">
                            <div className="appliance-wattage-input-wrapper">
                              <input
                                className="appliance-wattage-input"
                                value={appliance.wattage}
                                onChange={e => {
                                  updateApplianceWattage(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                              <p>W</p>
                            </div>
                          </div>
                          <div className="appliance-usage">
                            <div className="appliance-usage-input-wrapper">
                              <input
                                className="appliance-usage-input"
                                value={appliance.daily_run_hours}
                                onChange={e => {
                                  updateRunHours(
                                    appliance.appliance_id,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Col>
            <Col>
              {/* <Row>
                <div className="refine-details">
                  <div className="refine-details-header has-tooltip">
                    <p className="refine-details-header-text">
                      Thermal Storage Central Water Heater
                    </p>
                    <EnvacWaterTooltip />
                    <p className="refine-details-header-subtext" />
                  </div>
                  <div className="refine-details-body">
                    {selectedAppliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "Water Heater Points" && (
                          <div
                            className="grid-appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              <input
                                type="checkbox"
                                name={appliance.appliance_name}
                                checked={appliance.checked}
                                onChange={e => {
                                  addRemoveAppliance(
                                    appliance.appliance_id,
                                    e.target.checked
                                  );
                                }}
                              />
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <img
                                src={minus}
                                alt=""
                                onClick={() => {
                                  updateApplianceQuantity(
                                    appliance.appliance_id,
                                    "subtract"
                                  );
                                }}
                              />
                              <p>{appliance.custom_quantity}</p>
                              <img
                                src={plus}
                                alt=""
                                onClick={() => {
                                  updateApplianceQuantity(
                                    appliance.appliance_id,
                                    "add"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Row> */}
              <Row>
                <FormGroup>
                  <Label for="solar_panels">Solar Panels</Label>
                  <select
                    className="form-input-tile"
                    name="solar_panels"
                    defaultValue={customFeedback.solar_panels}
                    onChange={onSelectSolarPanel}
                  >
                    <option value="">Select</option>
                    <option value="Off-Grid 100% Solar">
                      Off-Grid 100% Solar
                    </option>
                    <option value="75% Solar">75% Solar</option>
                    <option value="50% Solar">50% Solar</option>
                    <option value="25% Solar">25% Solar</option>
                    <option value="No Solar">No Solar</option>
                  </select>
                  <br />
                </FormGroup>
              </Row>
            </Col>
          </Row>
          <Row className="form-row">
            <Col>
              <legend>Battery Replacement Package</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="battery_replacement_package"
                        value="Included"
                        onClick={onSelectBatRepPack}
                        defaultChecked={
                          customFeedback.battery_replacement_package ===
                          "Included"
                        }
                      />
                      Included
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="battery_replacement_package"
                        value="Not Included"
                        onClick={onSelectBatRepPack}
                        defaultChecked={
                          customFeedback.battery_replacement_package ===
                          "Not Included"
                        }
                      />
                      Not Included
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <legend>Quarterly Maintenance Package</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="quarterly_maintenance_package"
                        value="Included"
                        onClick={onSelectQuaMainPack}
                        defaultChecked={
                          customFeedback.quarterly_maintenance_package ===
                          "Included"
                        }
                      />
                      Included
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="quarterly_maintenance_package"
                        value="Not Included"
                        onClick={onSelectQuaMainPack}
                        defaultChecked={
                          customFeedback.quarterly_maintenance_package ===
                          "Not Included"
                        }
                      />
                      Not Included
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <legend>Extended Warranty Package</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="extended_warranty_package"
                        value="Included"
                        onClick={onSelectExtWarPack}
                        defaultChecked={
                          customFeedback.extended_warranty_package ===
                          "Included"
                        }
                      />
                      Included
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="extended_warranty_package"
                        value="Not Included"
                        onClick={onSelectExtWarPack}
                        defaultChecked={
                          customFeedback.extended_warranty_package ===
                          "Not Included"
                        }
                      />
                      Not Included
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h5 className="mb-3 form-header">Design Load Calculations</h5>
          {refineRecommended.sunlightAdjustment && (
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Start Up Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalStartUpPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.thermalBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Energy Use(WH)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalEnergy}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power Requirement(W)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Adjustment for Sunlight Intensity
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.sunlightAdjustment}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Power (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalThermalPower}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Total Kilowatts (Thermal Storage)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.totalThermalKilowatts}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Main Battery Requirement
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.mainBatteryRequirement}
                </p>
              </div>
              <div className="refine-selection-details-element" />
            </div>
          )}
          <h5 className="mb-3 form-header">Equipment Specification</h5>
          {refineRecommended.sunlightAdjustment && (
            <div className="preview-body custom-preview-selection-details">
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Inverter Capacity
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.products["Inverter Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Battery Bank Capacity
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.products["Battery Package"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Thermal Storage Unit (EnVAC)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.products["AC PACKAGE"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Solar Panel Sizing
                </p>
                <p className="selection-details-element-value">
                  {Number(refineRecommended.products["Solar Package"].rating)}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Water Heater Capacity
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.products["WATER HEATER"].rating}
                </p>
              </div>
              <div className="refine-selection-details-element">
                <p className="selection-details-element-label">
                  Space Cooling (Nature Breeze)
                </p>
                <p className="selection-details-element-value">
                  {refineRecommended.products["Nature Breeze Package"].rating}
                </p>
              </div>
            </div>
          )}
          <div
            className="form-submit-btn"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button
              type="button"
              onClick={() => onClose()}
              className="preview-refine-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => onSubmitSolution()}
              className="preview-order-btn"
            >
              Confirm
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RefineSelection;
