import React, { useState, useEffect } from "react";
import "./ComponentPricing.css";

const ComponentPricing = ({ setLoading, notify }) => {
  const [pricebook, setPricebook] = useState([]);
  const [packagePrices, setPackagePrices] = useState({});

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/products/pricebook`)
      .then(res => res.json())
      .then(data => {
        setPricebook(data.responseData);
        setLoading(false);
        setPackagePrices({
          inverter1Price: data.responseData
            .filter(
              item => item.description === "1.5 KVA Inverter and Accessories"
            )
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          inverter2Price: data.responseData
            .filter(
              item => item.description === "3 KVA Inverter and Accessories"
            )
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          inverter3Price: data.responseData
            .filter(
              item => item.description === "5 KVA Inverter and Accessories"
            )
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          batteryPrice: data.responseData
            .filter(item => item.package === "Battery Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          solarPrice: data.responseData
            .filter(item => item.package === "Solar Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          groundingPrice: data.responseData
            .filter(item => item.package === "Solar Assessories")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          stackingPrice: data.responseData
            .filter(item => item.package === "Stacking Card")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          meterPrice: data.responseData
            .filter(item => item.package === "Meter Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          rackPrice: data.responseData
            .filter(item => item.package === "Battery Assessories")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          naturePrice: data.responseData
            .filter(item => item.package === "Nature Breeze Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          heaterPrice: data.responseData
            .filter(
              item => item.package === "5 Pts Envac Heater Package (300L)"
            )
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          unknown1Price: data.responseData
            .filter(item => item.package === "UNKNOWN1")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          unknown2Price: data.responseData
            .filter(item => item.package === "UNKNOWN2")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          ac250Price: data.responseData
            .filter(item => item.package === "Envc AC Package (Igloo 250)")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          ac1000LitePrice: data.responseData
            .filter(
              item => item.package === "Envc AC Package (Igloo 1000 Lite)"
            )
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          unknown3Price: data.responseData
            .filter(item => item.package === "UNKNOWN3")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          unknown4Price: data.responseData
            .filter(item => item.package === "UNKNOWN4")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          replacementPrice: data.responseData
            .filter(item => item.package === "Battery Replacement Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          maintenancePrice: data.responseData
            .filter(item => item.package === "Quarterly Maintenance Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          warrantyPrice: data.responseData
            .filter(item => item.package === "Extended Warranty Package")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          utilityPriceSolar: data.responseData
            .filter(item => item.description === "IceVolt Utility Box - Solar")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b)),
          utilityPriceBackup: data.responseData
            .filter(item => item.description === "IceVolt Utility Box - Backup")
            .map(newItem => newItem.price)
            .reduce((a, b) => Number(a) + Number(b))
        });
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  const onSaveChanges = () => {
    notify("warn", "currently unable to update pricebook");
    // setLoading(true);
    // fetch(`${process.env.REACT_APP_API_URL}/products/pricebook`, {
    //   method: "put",
    //   headers: { "content-Type": "application/json" },
    //   body: JSON.stringify({
    //     pricebook
    //   })
    // })
    //   .then(res => res.json())
    //   .then(data => {
    //     if (data.success) {
    //       setLoading(false);
    //       notify("success", "Prodcut prices Updated");
    //     } else {
    //       notify("error", "Unable to Update product prices");
    //     }
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     notify("error", "Unable to Update product prices");
    //   });
  };

  const onPriceChange = (e, i) => {
    const newObjectUpdate = {
      ...pricebook[i],
      price: e.target.value
    };
    const newPricebook = pricebook;
    newPricebook[i] = newObjectUpdate;
    setPricebook(newPricebook);
  };
  return (
    <div className="ComponentPricing">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 className="productlist-text">Product List</h3>
        <button
          type="button"
          onClick={onSaveChanges}
          style={{
            padding: "7px 28px",
            color: "white",
            background: "#4c9de8",
            borderRadius: "5px",
            fontSize: "14px",
            border: "none"
          }}
        >
          Save Changes
        </button>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className="productlist-table">
          <thead className="productlist-table-head">
            <tr>
              <th className="productlist-description-head">Description</th>
              <th>Product detail</th>
              <th>Price</th>
              <th className="productlist-totalprice-head">Total Price(NGN)</th>
            </tr>
          </thead>
          <tbody>
            {pricebook
              .filter(
                item => item.description === "1.5 KVA Inverter and Accessories"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="12">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="12">
                      {Number(packagePrices.inverter1Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.description === "3 KVA Inverter and Accessories"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="12">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="12">
                      {Number(packagePrices.inverter2Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.description === "5 KVA Inverter and Accessories"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="12">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="12">
                      {Number(packagePrices.inverter3Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Battery Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="3">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="3">
                      {Number(packagePrices.batteryPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Solar Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="6">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="6">
                      {Number(packagePrices.solarPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Solar Assessories")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.groundingPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Stacking Card")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.stackingPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Meter Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.meterPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Battery Assessories")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.rackPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Nature Breeze Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.naturePrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.package === "5 Pts Envac Heater Package (300L)"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="4">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="4">
                      {Number(packagePrices.heaterPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "UNKNOWN1")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="4">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="4">
                      {Number(packagePrices.unknown1Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "UNKNOWN2")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="4">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="4">
                      {Number(packagePrices.unknown2Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Envc AC Package (Igloo 250)")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="6">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="6">
                      {Number(packagePrices.ac250Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.package === "Envc AC Package (Igloo 1000 Lite)"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="6">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="6">
                      {Number(packagePrices.ac1000LitePrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "UNKNOWN3")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="6">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="6">
                      {Number(packagePrices.unknown3Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "UNKNOWN4")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.unknown4Price).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Battery Replacement Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.replacementPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Quarterly Maintenance Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.maintenancePrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(item => item.package === "Extended Warranty Package")
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.warrantyPrice).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.description === "IceVolt Utility Box - Solar"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(packagePrices.utilityPriceSolar).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
            {pricebook
              .filter(
                item => item.description === "IceVolt Utility Box - Backup"
              )
              .map((item, i) => (
                <tr key={item.product_id}>
                  {i === 0 && (
                    <td className="productlist-description" rowSpan="1">
                      {item.description}
                    </td>
                  )}
                  <td>{item.product_name}</td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={item.price}
                      onChange={e => onPriceChange(e, i)}
                      style={{
                        border: "none",
                        borderBottom: "2px solid black",
                        outline: "none"
                      }}
                    />
                  </td>
                  {i === 0 && (
                    <td rowSpan="1">
                      {Number(
                        packagePrices.utilityPriceBackup
                      ).toLocaleString()}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComponentPricing;
