import React from "react";
import "./MobileNav.css";
import smalllogo from "../../images/smalllogo.png";
import fabars from "../../images/fabars.png";

const MobileNav = ({ sideBar, setSideBar }) => (
  <div className="mobile-nav">
    <div className="flex">
      <img className="small-logo" src={smalllogo} alt="" />
      <img
        src={fabars}
        className="fabars"
        alt=""
        onClick={() => setSideBar(!sideBar)}
      />
    </div>
  </div>
);

export default MobileNav;
