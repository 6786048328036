/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "reactstrap";
import { Chart } from "react-google-charts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ConfirmPayment from "../AdvanceCustomer/ConfirmPayment";
import copy from "../../images/copy.png";
import "./UserDetails.css";

const UserDetails = ({
  setLoading,
  selectedUser,
  notify,
  setPage,
  setAgentPage,
  basePage,
  role
}) => {
  const [userDetails, setUserDetails] = useState({});
  const [creditDetailsExists, setCreditDetailsExists] = useState(true);
  const [detailsPage, setDetailsPage] = useState("SELECTION");

  const [graphBarChart, setGraphBarChart] = useState([]);

  const [financeTable, setFinanceTable] = useState([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [confirmIndex, setConfirmIndex] = useState();

  const [linkCopied, setLinkCopied] = useState(false);

  const [assets, setAssets] = useState([]);

  const landingRecommendedSystem = userDetails.userRecommendedSystem;

  const statusIndicator = {
    NONE: "#CCCCCC",
    LOW: "#00FF00",
    MEDIUM: "#DAF034",
    FAIR: "#FFCA42",
    HIGH: "#FF0000",
    UNKNOWN: "#0000CC"
  };

  const reservationFeeValue = {
    0.05: "Low",
    0.1: "Medium",
    0.15: "High"
  };

  const onChangeDetailsPage = page => {
    setDetailsPage(page);
  };

  const onConfirmPayment = i => {
    setConfirmIndex(i);
    setConfirmationOpen(true);
  };

  const onSubmitConfirmation = message => {
    setLoading(true);
    const newTable = financeTable.map((table, index) =>
      index === confirmIndex ? { ...table, paid: true, message } : table
    );

    fetch(`${process.env.REACT_APP_API_URL}/user/confirmUserPayment`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        financeId: selectedUser.finance_id,
        paymentTable: newTable
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
          setFinanceTable(newTable);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "Unable to confirm payment");
      });
  };

  const resendActivationMail = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/resendActivationMail`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: selectedUser.email,
        firstname: selectedUser.firstname
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
        } else {
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occured");
      });
  };

  const displayPage = () => {
    switch (detailsPage) {
      case "SELECTION":
        return (
          <div>
            <div className="rank-feedback">
              <p className="detail-description">
                Monthly Grid Power Cost:
                <span className="detail-value">
                  {userDetails.userDetails.monthly_grid_power_cost}
                </span>
              </p>
              <p className="detail-description">
                Do you own Solar Panels or any other form of clean energy
                solution?
                <span className="detail-value">
                  {userDetails.userDetails.clean_energy}
                </span>
              </p>
              <p className="detail-description">
                Monthly Fuel Cost Estimate:
                <span className="detail-value">
                  {userDetails.userDetails.monthly_fuel_cost_estimate}
                </span>
              </p>
              <p className="detail-description">
                When do you expect to make the next investment or upgrade to
                your power system?
                <span className="detail-value">
                  {userDetails.userDetails.next_upgrade}
                </span>
              </p>
              <p className="detail-description">
                Monthly Power Needs Cost:
                <span className="detail-value">
                  {userDetails.userDetails.monthly_power_need_cost}
                </span>
              </p>
              <p className="detail-description">
                How familiar are you with Credit Services for making major
                purchases?
                <span className="detail-value">
                  {userDetails.userDetails.credit_service_familiarity}
                </span>
              </p>
              <p className="detail-description">
                Do you own a Power Generator?
                <span className="detail-value">
                  {userDetails.userDetails.power_generator}
                </span>
              </p>
              <p className="detail-description">
                Monthly Grid Power Cost:
                <span className="detail-value">
                  {userDetails.userDetails.monthly_grid_power_cost}
                </span>
              </p>
              <p className="detail-description">
                Are you connected to Grid Power
                <span className="detail-value">
                  {userDetails.userDetails.grid_power}
                </span>
              </p>
              <p className="detail-description">
                Monthly Grid Power Cost:
                <span className="detail-value">
                  {userDetails.userDetails.monthly_grid_power_cost}
                </span>
              </p>
            </div>
            <div className="preview-selection">
              <div className="preview-header">
                <p className="preview-header-text">Selection</p>
              </div>
              <div className="preview-body">
                <p className="preview-plan-text">
                  {userDetails.userDetails.plan}
                </p>
              </div>
            </div>
            {userDetails.userRecommendedSystem && (
              <>
                {userDetails.userDetails.plan === "CUSTOM" ? (
                  <div className="preview-selection-details">
                    <Form className="form-container">
                      <h5 className="mb-3 form-header">Selection Details</h5>
                      <div className="refine-details">
                        <div className="refine-details-header">
                          <p className="refine-details-header-text">
                            Regular Household Appliances
                          </p>
                          <p className="refine-details-header-subtext">
                            Low consumption, Daily Use
                          </p>
                        </div>
                        <div className="refine-details-body">
                          <div className="appliance-wrapper-label">
                            <div className="appliance-checkbox-name-label" />
                            <div className="appliance-quantity-label">
                              <p>Qty</p>
                            </div>
                            <div className="appliance-wattage-label">
                              <p>wattage</p>
                            </div>
                            <div className="appliance-usage-label">
                              <p>Daily Usage(Hrs)</p>
                            </div>
                          </div>
                          <div className="appliance-wrapper-label appliance-label2">
                            <div className="appliance-checkbox-name-label" />
                            <div className="appliance-quantity-label">
                              <p>Qty</p>
                            </div>
                            <div className="appliance-wattage-label">
                              <p>wattage</p>
                            </div>
                            <div className="appliance-usage-label">
                              <p>Daily Usage(Hrs)</p>
                            </div>
                          </div>
                          {userDetails.userRecommendedSystem.appliances.map(
                            appliance =>
                              appliance.appliance_category ===
                                "Regular Household Appliances" &&
                              appliance.checked && (
                                <div
                                  className="appliance-wrapper"
                                  key={appliance.appliance_id}
                                >
                                  <div className="appliance-checkbox-name">
                                    {appliance.appliance_name}
                                  </div>
                                  <div className="appliance-quantity">
                                    <p>{appliance.custom_quantity}</p>
                                  </div>
                                  <div className="appliance-wattage">
                                    <div className="appliance-wattage-input-wrapper">
                                      <input
                                        className="appliance-wattage-input"
                                        disabled
                                        value={appliance.wattage}
                                      />
                                      <p>W</p>
                                    </div>
                                  </div>
                                  <div className="appliance-usage">
                                    <div className="appliance-usage-input-wrapper">
                                      <input
                                        className="appliance-usage-input"
                                        disabled
                                        value={appliance.daily_run_hours}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      <div className="refine-details">
                        <div className="refine-details-header">
                          <p className="refine-details-header-text">
                            High Power Appliances
                          </p>
                          <p className="refine-details-header-subtext">
                            High consumption, Regular Use
                          </p>
                        </div>
                        <div className="refine-details-body">
                          <div className="appliance-wrapper-label">
                            <div className="appliance-checkbox-name-label" />
                            <div className="appliance-quantity-label">
                              <p>Qty</p>
                            </div>
                            <div className="appliance-wattage-label">
                              <p>wattage</p>
                            </div>
                            <div className="appliance-usage-label">
                              <p>Daily Usage(Hrs)</p>
                            </div>
                          </div>
                          <div className="appliance-wrapper-label appliance-label2">
                            <div className="appliance-checkbox-name-label" />
                            <div className="appliance-quantity-label">
                              <p>Qty</p>
                            </div>
                            <div className="appliance-wattage-label">
                              <p>wattage</p>
                            </div>
                            <div className="appliance-usage-label">
                              <p>Daily Usage(Hrs)</p>
                            </div>
                          </div>
                          {userDetails.userRecommendedSystem.appliances.map(
                            appliance =>
                              appliance.appliance_category ===
                                "High Power Appliances" &&
                              appliance.checked && (
                                <div
                                  className="appliance-wrapper"
                                  key={appliance.appliance_id}
                                >
                                  <div className="appliance-checkbox-name">
                                    {appliance.appliance_name}
                                  </div>
                                  <div className="appliance-quantity">
                                    <p>{appliance.custom_quantity}</p>
                                  </div>
                                  <div className="appliance-wattage">
                                    <div className="appliance-wattage-input-wrapper">
                                      <input
                                        className="appliance-wattage-input"
                                        value={appliance.wattage}
                                        disabled
                                      />
                                      <p>W</p>
                                    </div>
                                  </div>
                                  <div className="appliance-usage">
                                    <div className="appliance-usage-input-wrapper">
                                      <input
                                        className="appliance-usage-input"
                                        value={appliance.daily_run_hours}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <div className="refine-details">
                            <div className="refine-details-header">
                              <p className="refine-details-header-text">
                                Envac Proprietary Thermal Storage Equipment (Air
                                Conditioning and Water Heater)
                              </p>
                              <p className="refine-details-header-subtext" />
                            </div>
                            <div className="refine-details-body">
                              {userDetails.userRecommendedSystem.appliances.map(
                                appliance =>
                                  appliance.appliance_category ===
                                    "Envac Proprietary Thermal Storage Equipment (Air Conditioning and Water Heater)" &&
                                  appliance.checked && (
                                    <div
                                      className="grid-appliance-wrapper"
                                      key={appliance.appliance_id}
                                    >
                                      <div className="appliance-checkbox-name">
                                        {appliance.appliance_name}
                                      </div>
                                      <div className="appliance-quantity">
                                        <p>{appliance.custom_quantity}</p>
                                      </div>
                                      <div className="appliance-wattage">
                                        <div className="appliance-wattage-input-wrapper">
                                          <input
                                            className="appliance-wattage-input"
                                            value={appliance.wattage}
                                            disabled
                                          />
                                          <p>W</p>
                                        </div>
                                      </div>
                                      <div className="appliance-usage">
                                        <div className="appliance-usage-input-wrapper">
                                          <input
                                            className="appliance-usage-input"
                                            value={appliance.daily_run_hours}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="refine-details">
                            <div className="refine-details-header">
                              <p className="refine-details-header-text">
                                Lighthing Requirements
                              </p>
                              <p className="refine-details-header-subtext" />
                            </div>
                            <div className="refine-details-body">
                              {userDetails.userRecommendedSystem.appliances.map(
                                appliance =>
                                  appliance.appliance_category ===
                                    "Lighting Requirements" &&
                                  appliance.checked && (
                                    <div
                                      className="grid-appliance-wrapper"
                                      key={appliance.appliance_id}
                                    >
                                      <div className="appliance-checkbox-name">
                                        {appliance.appliance_name}
                                      </div>
                                      <div className="appliance-quantity">
                                        <p>{appliance.custom_quantity}</p>
                                      </div>
                                      <div className="appliance-wattage">
                                        <div className="appliance-wattage-input-wrapper">
                                          <input
                                            className="appliance-wattage-input"
                                            value={appliance.wattage}
                                            disabled
                                          />
                                          <p>W</p>
                                        </div>
                                      </div>
                                      <div className="appliance-usage">
                                        <div className="appliance-usage-input-wrapper">
                                          <input
                                            className="appliance-usage-input"
                                            value={appliance.daily_run_hours}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="refine-details">
                            <div className="refine-details-header">
                              <p className="refine-details-header-text">
                                Others
                              </p>
                              <p className="refine-details-header-subtext">
                                Custom Appliances
                              </p>
                            </div>
                            <div className="refine-details-body">
                              {userDetails.userRecommendedSystem.appliances.map(
                                appliance =>
                                  appliance.appliance_category === "Others" &&
                                  appliance.checked && (
                                    <div
                                      className="grid-appliance-wrapper"
                                      key={appliance.appliance_id}
                                    >
                                      <div className="appliance-checkbox-name">
                                        {appliance.appliance_name}
                                      </div>
                                      <div className="appliance-quantity">
                                        <p>{appliance.custom_quantity}</p>
                                      </div>
                                      <div className="appliance-wattage">
                                        <div className="appliance-wattage-input-wrapper">
                                          <input
                                            className="appliance-wattage-input"
                                            value={appliance.wattage}
                                            disabled
                                          />
                                          <p>W</p>
                                        </div>
                                      </div>
                                      <div className="appliance-usage">
                                        <div className="appliance-usage-input-wrapper">
                                          <input
                                            className="appliance-usage-input"
                                            value={appliance.daily_run_hours}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </Col>
                        {/* <Col>
                          <div className="refine-details">
                            <div className="refine-details-header">
                              <p className="refine-details-header-text">
                                Water Heater Points
                              </p>
                              <p className="refine-details-header-subtext" />
                            </div>
                            <div className="refine-details-body">
                              {userDetails.userRecommendedSystem.appliances.map(
                                appliance =>
                                  appliance.appliance_category ===
                                    "Water Heater Points" && (
                                    <div
                                      className="grid-appliance-wrapper"
                                      key={appliance.appliance_id}
                                    >
                                      <div className="appliance-checkbox-name">
                                        {appliance.appliance_name}
                                      </div>
                                      <div className="appliance-quantity">
                                        <p>{appliance.custom_quantity}</p>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <div className="preview-body custom-preview-selection-details">
                          <div className="selection-details-element">
                            <p className="selection-details-element-label">
                              Battery Replacement Package
                            </p>
                            <p className="selection-details-element-value">
                              {
                                userDetails.userCustomFeedback
                                  .battery_replacement_package
                              }
                            </p>
                          </div>
                          <div className="selection-details-element">
                            <p className="selection-details-element-label">
                              Quaterly Maintenance Package
                            </p>
                            <p className="selection-details-element-value">
                              {
                                userDetails.userCustomFeedback
                                  .quarterly_maintenance_package
                              }
                            </p>
                          </div>
                          <div className="selection-details-element">
                            <p className="selection-details-element-label">
                              Extended Warranty Package
                            </p>
                            <p className="selection-details-element-value">
                              {
                                userDetails.userCustomFeedback
                                  .extended_warranty_package
                              }
                            </p>
                          </div>
                          <div className="selection-details-element" />
                        </div>
                      </Row>
                      <h5 className="mb-3 form-header">
                        Design Load Calculations
                      </h5>
                      <div className="preview-body custom-preview-selection-details">
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Start Up Power Requirement(W)
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalStartUpPower}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Thermal Battery Requirement
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.thermalBatteryRequirement}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Energy Use(WH)
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalEnergy}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Power Requirement(W)
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalPower}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Adjustment for Sunlight Intensity
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.sunlightAdjustment}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Kilowatts
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalKilowatts}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Power (Thermal Storage)
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalThermalPower}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Total Kilowatts (Thermal Storage)
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.totalThermalKilowatts}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Main Battery Requirement
                          </p>
                          <p className="selection-details-element-value">
                            {landingRecommendedSystem.mainBatteryRequirement}
                          </p>
                        </div>
                        <div className="refine-selection-details-element" />
                      </div>
                      <h5 className="mb-3 form-header">
                        Equipment Specification
                      </h5>
                      <div className="preview-body custom-preview-selection-details">
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Inverter Capacity
                          </p>
                          <p className="selection-details-element-value">
                            {
                              landingRecommendedSystem.products[
                                "Inverter Package"
                              ].rating
                            }
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Battery Bank Capacity
                          </p>
                          <p className="selection-details-element-value">
                            {
                              landingRecommendedSystem.products[
                                "Battery Package"
                              ].rating
                            }
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Thermal Storage Unit (EnVAC)
                          </p>
                          <p className="selection-details-element-value">
                            {
                              landingRecommendedSystem.products["AC PACKAGE"]
                                .rating
                            }
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Solar Panel Sizing
                          </p>
                          <p className="selection-details-element-value">
                            {Number(
                              landingRecommendedSystem.products["Solar Package"]
                                .rating
                            )}
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Water Heater Capacity
                          </p>
                          <p className="selection-details-element-value">
                            {
                              landingRecommendedSystem.products["WATER HEATER"]
                                .rating
                            }
                          </p>
                        </div>
                        <div className="refine-selection-details-element">
                          <p className="selection-details-element-label">
                            Space Cooling (Nature Breeze)
                          </p>
                          <p className="selection-details-element-value">
                            {
                              landingRecommendedSystem.products[
                                "Nature Breeze Package"
                              ].rating
                            }
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div className="preview-description">
                    <div className="preview-header">
                      <p className="preview-header-text">Description</p>
                    </div>
                    <div className="preview-body">
                      <p className="preview-body-text">
                        {userDetails.userRecommendedSystem.description}
                      </p>
                      <p className="recommended-details-tile-body-text">
                        {userDetails.userRecommendedSystem.sub_description}
                      </p>
                    </div>
                  </div>
                )}
                <div className="preview-recommended">
                  <div className="preview-header">
                    <p className="preview-header-text">Recommended System</p>
                  </div>
                  <div className="preview-body">
                    <p className="preview-body-text">
                      {userDetails.userRecommendedSystem.make}
                    </p>
                  </div>
                </div>
                {userDetails.userDetails.plan !== "CUSTOM" && (
                  <>
                    <div className="preview-recommended-details">
                      <div className="preview-header">
                        <p className="preview-header-text">
                          Recommended System Details
                        </p>
                      </div>
                      <div className="preview-body recommended-details-body">
                        {Object.keys(landingRecommendedSystem.products).map(
                          productKey =>
                            productKey !== "IceVolt Operating Margin" && (
                              <div
                                className="recommended-details-tile"
                                key={productKey}
                              >
                                <div className="recommended-details-tile-header">
                                  <p className="recommended-details-tile-header-text">
                                    {productKey}
                                  </p>
                                  <p className="recommended-details-tile-header-text">{`Qty: ${landingRecommendedSystem.products[productKey].quantity}`}</p>
                                </div>
                                <div className="recommended-details-tile-body">
                                  {landingRecommendedSystem.products[
                                    productKey
                                  ].components.map((component, i) => (
                                    <p
                                      className="recommended-details-tile-body-text"
                                      key={`${component}${i}`}
                                    >
                                      {component}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="preview-body custom-preview-selection-details">
                        <div className="selection-details-element">
                          <p className="selection-details-element-label">
                            Battery Replacement Package
                          </p>
                          <p className="selection-details-element-value">
                            {
                              userDetails.userCustomFeedback
                                .battery_replacement_package
                            }
                          </p>
                        </div>
                        <div className="selection-details-element">
                          <p className="selection-details-element-label">
                            Quaterly Maintenance Package
                          </p>
                          <p className="selection-details-element-value">
                            {
                              userDetails.userCustomFeedback
                                .quarterly_maintenance_package
                            }
                          </p>
                        </div>
                        <div className="selection-details-element">
                          <p className="selection-details-element-label">
                            Extended Warranty Package
                          </p>
                          <p className="selection-details-element-value">
                            {
                              userDetails.userCustomFeedback
                                .extended_warranty_package
                            }
                          </p>
                        </div>
                        <div className="selection-details-element" />
                      </div>
                    </div>
                  </>
                )}
                <div className="preview-financing">
                  <div className="preview-header">
                    <p className="preview-header-text">Selected Financing</p>
                  </div>
                  <div className="preview-body custom-preview-selection-details">
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Reservation Fee
                      </p>
                      <p className="selection-details-element-value">
                        {
                          reservationFeeValue[
                            Number(userDetails.userFinance.down_payment)
                          ]
                        }
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Power Contract Duration in years
                      </p>
                      <p className="selection-details-element-value">
                        {userDetails.userFinance.loan_period}
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Monthly Flat Rate Utility Bill
                      </p>
                      <p className="selection-details-element-value">
                        {`₦${Number(
                          userDetails.userFinance.monthly_payment
                        ).toLocaleString()}`}
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Amount of Reservation Fee
                      </p>
                      <p className="selection-details-element-value">
                        {`₦${Number(
                          userDetails.userFinance.amount_of_down_payment
                        ).toLocaleString()}`}
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Number of Payments
                      </p>
                      <p className="selection-details-element-value">
                        {userDetails.userFinance.number_of_payments}
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Start Date of Power Contract
                      </p>
                      <p className="selection-details-element-value">
                        {`${new Date(
                          userDetails.userFinance.start_date_of_loan
                        ).toDateString()}`}
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        End Date of Power Contract
                      </p>
                      <p className="selection-details-element-value">
                        {new Date(
                          userDetails.userFinance.finance_table[
                            userDetails.userFinance.finance_table.length - 1
                          ].paymentDate
                        ).toDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedUser.status === "PENDING" && (
              <div className="resend-activation-mail">
                <button type="button" onClick={resendActivationMail}>
                  Resend Activation Mail
                </button>
              </div>
            )}
          </div>
        );
      case "PAYMENT":
        return (
          userDetails.userFinance && (
            <>
              <div className="user-details-payment-graph">
                <Chart
                  width="100%"
                  height="300px"
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={[["year", "Owner Payments"], ...graphBarChart]}
                  options={{
                    // Material design options
                    chart: {
                      title: "Icevolt Green Energy Serivices Ownership Model"
                    }
                  }}
                  // For tests
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
              <div className="user-details-payment-details">
                <table className="payment-details-table">
                  <thead className="payment-details-table-head">
                    <tr>
                      <th className="payment-details-sn-head">S/N</th>
                      <th>Payment Date</th>
                      <th>Begining Balance</th>
                      <th>Payment</th>
                      <th>Principal</th>
                      <th>Interest</th>
                      <th>Ending Balance</th>
                      <th>Year</th>
                      <th>payment</th>
                      <th className="payment-details-status-head">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financeTable.map((finance, i) => (
                      <tr key={finance.beginningBalance}>
                        <td>{i + 1}</td>
                        <td>
                          {new Date(finance.paymentDate).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>{finance.beginningBalance.toLocaleString()}</td>
                        <td>{finance.payment.toLocaleString()}</td>
                        <td>{finance.principal.toLocaleString()}</td>
                        <td>{finance.interest.toLocaleString()}</td>
                        <td>{finance.endingBalance.toLocaleString()}</td>
                        <td>{finance.year}</td>
                        <td>
                          <button
                            className="confirmpayment-btn"
                            type="button"
                            style={
                              finance.paid
                                ? { backgroundColor: "grey" }
                                : { backgroundColor: "rgb(76, 157, 232)" }
                            }
                            onClick={() => onConfirmPayment(i)}
                          >
                            {finance.paid ? "Confirmed" : "ConfirmPayment"}
                          </button>
                        </td>
                        <td>{finance.paid ? "Paid" : "Pending"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )
        );
      case "WALLET":
        return (
          <div className="customer-info">
            <div className="wallet-cards">
              <div className="wallet-details">
                {userDetails.userWallet.wallet_address ? (
                  <div>
                    <p>{userDetails.userWallet.wallet_address}</p>
                    <CopyToClipboard
                      text={userDetails.userWallet.wallet_address}
                      onCopy={() => setLinkCopied(true)}
                    >
                      <button type="button">
                        <img src={copy} alt="" />
                        Copy Address
                      </button>
                    </CopyToClipboard>
                    {linkCopied && (
                      <p className="referrals-link-copied">copied &#10003;</p>
                    )}
                  </div>
                ) : (
                  <div>
                    <p>No Address Added</p>
                  </div>
                )}
              </div>
            </div>
            {userDetails.userWallet.wallet_address && (
              <>
                <h4 className="customer-info-setion-header">Assets</h4>
                <div className="wallet-cards">
                  {assets.map(asset => (
                    <div key={asset.assetId} className="wallet-input-wrapper">
                      <p className="asset-name">{asset.assetName}</p>
                      <p className="asset-unit-name">{asset.assetUnitName}</p>
                      <p className="asset-amount">
                        {asset.amount
                          ? asset.amount / 10 ** asset.decimals
                          : asset.amount}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            <h4 className="customer-info-setion-header">Token Report</h4>
          </div>
        );
      default:
        return <div>No page to display</div>;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/user/userDetails/${selectedUser.user_id}`
    )
      .then(res => res.json())
      .then(data => {
        setUserDetails(data.responseData);
        setCreditDetailsExists(
          data.responseData.userDetails.credit_status !== "UNKNOWN" &&
            data.responseData.userDetails.credit_status !== "NONE"
        );

        if (data.responseData.userFinance) {
          setGraphBarChart(
            data.responseData.userFinance.graph_table.map(row => [
              row.year.toString(),
              row.ownerPayments
            ])
          );
          setFinanceTable(data.responseData.userFinance.finance_table);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (userDetails.userWallet && userDetails.userWallet.wallet_address) {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/wallet/getAsstes/${userDetails.userDetails.email}`
      )
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            setLoading(false);
            setAssets(data.responseData.assets);
          } else {
            setLoading(false);
            notify("error", data.message);
          }
        })
        .catch(() => {
          setLoading(true);
          notify("error", "An error occurred");
        });
    }
    // eslint-disable-next-line
  }, [userDetails]);

  return (
    <div className="UserDetails">
      {userDetails.userDetails && (
        <>
          {confirmationOpen && (
            <ConfirmPayment
              onConfirmPayment={onSubmitConfirmation}
              setConfirmationOpen={setConfirmationOpen}
            />
          )}
          <div className="userDetails-page-header">
            {basePage === "Agents" || basePage === "Sales Rep" ? (
              <p>
                <span
                  onClick={() => {
                    setAgentPage("AGENTS");
                  }}
                >
                  {basePage}
                </span>
                &nbsp;&gt;&nbsp;
                <span
                  onClick={() => {
                    setPage("ANALYTICS");
                  }}
                >
                  {basePage === "Sales Rep"
                    ? "Sales Rep Details"
                    : "Agent Details"}
                </span>
                &nbsp;&gt; User Details
              </p>
            ) : (
              <p>
                <span
                  onClick={() => {
                    setPage("ANALYTICS");
                  }}
                >
                  {basePage}
                </span>
                &nbsp;&gt; User Details
              </p>
            )}
            <div>
              <p>{userDetails.userDetails.user_account_id.split("-")[0]}</p>
              <p>{userDetails.userDetails.user_account_number}</p>
              <p className="credit-status-wrapper">
                Credit Status:
                <p
                  className="credit-status"
                  style={{
                    backgroundColor: `${
                      statusIndicator[userDetails.userDetails.credit_status]
                    }`
                  }}
                />
              </p>
            </div>
          </div>
          <div className="selected-user-details">
            {role !== "Agent" && (
              <div className="user-detail-tile">
                <h3 className="user-details-description">Credit Details</h3>
                {creditDetailsExists && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "600px"
                    }}
                  >
                    <p className="detail-description">
                      FirstName:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_firstname}
                      </span>
                    </p>
                    <p className="detail-description">
                      Surname:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_surname}
                      </span>
                    </p>
                    <p className="detail-description">
                      Othername:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_othernames}
                      </span>
                    </p>
                    <p className="detail-description">
                      Address:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_address}
                      </span>
                    </p>
                    <p className="detail-description">
                      Birthdate:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_birthdate}
                      </span>
                    </p>
                    <p className="detail-description">
                      Phone Number:
                      <span className="detail-value">
                        {userDetails.userDetails.credit_tel}
                      </span>
                    </p>
                    <p className="detail-description">
                      First Credit Check:
                      <span className="detail-value">
                        {new Date(
                          userDetails.userDetails.credit_first_date
                        ).toDateString()}
                      </span>
                    </p>
                    <p className="detail-description">
                      Last Credit Check:
                      <span className="detail-value">
                        {new Date(
                          userDetails.userDetails.credit_current_date
                        ).toDateString()}
                      </span>
                    </p>
                  </div>
                )}
                {userDetails.userDetails.credit_status === "UNKNOWN" && (
                  <>
                    <p className="detail-description">
                      This User does not have a loan record
                    </p>
                    <p className="detail-description">
                      First Credit Check:
                      <span className="detail-value">
                        {new Date(
                          userDetails.userDetails.credit_first_date
                        ).toDateString()}
                      </span>
                    </p>
                    <p className="detail-description">
                      Last Credit Check:
                      <span className="detail-value">
                        {new Date(
                          userDetails.userDetails.credit_current_date
                        ).toDateString()}
                      </span>
                    </p>
                  </>
                )}
                {userDetails.userDetails.credit_status === "NONE" && (
                  <p className="detail-description">
                    This User has not ran a credit check
                  </p>
                )}
              </div>
            )}
            <div className="user-detail-tile">
              <h3 className="user-details-description">Personal Details</h3>
              <p className="detail-description">
                Full Name:
                <span className="detail-value">
                  {`${userDetails.userDetails.firstname} ${userDetails.userDetails.lastname}`}
                </span>
              </p>
              <p className="detail-description">
                Phone Number:
                <span className="detail-value">
                  {userDetails.userDetails.phone_number}
                </span>
              </p>
              <p className="detail-description">
                Email Address:
                <span className="detail-value">
                  {userDetails.userDetails.email}
                </span>
              </p>
              <p className="detail-description">
                Date Of Birth:
                <span className="detail-value">
                  {userDetails.userDetails.dob}
                </span>
              </p>
              <p className="detail-description">
                Employment Status:
                <span className="detail-value">
                  {userDetails.userDetails.employment_status}
                </span>
              </p>
            </div>
            <div className="user-detail-tile">
              <h3 className="user-details-description">Installation Details</h3>
              <p className="detail-description">
                Installation Location Type:
                <span className="detail-value">
                  {userDetails.userDetails.installation_location_type}
                </span>
              </p>
              <p className="detail-description">
                Installation Adress:
                <span className="detail-value">
                  {userDetails.userDetails.installation_address}
                </span>
              </p>
              <p className="detail-description">
                State:
                <span className="detail-value">
                  {userDetails.userDetails.state}
                </span>
              </p>
              <p className="detail-description">
                Country:
                <span className="detail-value">
                  {userDetails.userDetails.country}
                </span>
              </p>
            </div>
            {role !== "Agent" && (
              <>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">Loan Details</h3>
                  {userDetails.userFinance && (
                    <>
                      <p className="detail-description">
                        Annual Interest Rate:
                        <span className="detail-value">
                          {userDetails.userFinance.annual_interest_rate}
                        </span>
                      </p>
                      <p className="detail-description">
                        Loan Period:
                        <span className="detail-value">
                          {userDetails.userFinance.loan_period}
                        </span>
                      </p>
                      <p className="detail-description">
                        Start Date of Loan:
                        <span className="detail-value">
                          {userDetails.userFinance.start_date_of_loan}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">Loan Summary</h3>
                  {userDetails.userFinance && (
                    <>
                      <p className="detail-description">
                        Monthly Flat Rate Utility Bill:
                        <span className="detail-value">
                          {Number(
                            userDetails.userFinance.monthly_payment
                          ).toLocaleString()}
                        </span>
                      </p>
                      <p className="detail-description">
                        Number of Payments:
                        <span className="detail-value">
                          {userDetails.userFinance.number_of_payments}
                        </span>
                      </p>
                      <p className="detail-description">
                        Total Interest:
                        <span className="detail-value">
                          {Number(
                            userDetails.userFinance.total_interest
                          ).toLocaleString()}
                        </span>
                      </p>
                      <p className="detail-description">
                        Total Cost of Loan:
                        <span className="detail-value">
                          {Number(
                            userDetails.userFinance.total_cost_of_loan
                          ).toLocaleString()}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">User Note</h3>
                  <p className="detail-description">
                    First Login:
                    {userDetails.userDetails.first_login_date && (
                      <span className="detail-value">
                        {new Date(
                          userDetails.userDetails.first_login_date
                        ).toDateString()}
                      </span>
                    )}
                  </p>
                  <p className="detail-description">
                    Note:
                    <span className="detail-value">
                      {userDetails.userDetails.user_note}
                    </span>
                  </p>
                </div>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">
                    Prospect Customer Info
                  </h3>
                  {userDetails.userDetails.prospect_date ? (
                    <>
                      <p className="detail-description">
                        Date:
                        <span className="detail-value">
                          {new Date(
                            userDetails.userDetails.prospect_date
                          ).toDateString()}
                        </span>
                      </p>
                      <p className="detail-description">
                        Message:
                        <span className="detail-value">
                          {userDetails.userDetails.prospect_message}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className="detail-description">Not yet a Prospect</p>
                  )}
                </div>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">
                    Prospect for Site Audit Customer Info
                  </h3>
                  {userDetails.userDetails.audit_date ? (
                    <>
                      <p className="detail-description">
                        Date:
                        <span className="detail-value">
                          {new Date(
                            userDetails.userDetails.audit_date
                          ).toDateString()}
                        </span>
                      </p>
                      <p className="detail-description">
                        Message:
                        <span className="detail-value">
                          {userDetails.userDetails.audit_message}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className="detail-description">
                      Not yet a Prospect for Site Audit
                    </p>
                  )}
                </div>
                <div className="user-detail-tile">
                  <h3 className="user-details-description">
                    Paying Customer Info
                  </h3>
                  {userDetails.userDetails.paying_date ? (
                    <>
                      <p className="detail-description">
                        Date:
                        <span className="detail-value">
                          {new Date(
                            userDetails.userDetails.paying_date
                          ).toDateString()}
                        </span>
                      </p>
                      <p className="detail-description">
                        Message:
                        <span className="detail-value">
                          {userDetails.userDetails.paying_message}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className="detail-description">
                      Not yet a Paying Customer
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="user-details-navigation">
            <div
              className="user-details-navigation-item-wrapper"
              onClick={() => {
                onChangeDetailsPage("SELECTION");
              }}
            >
              <p
                className={`user-details-navigation-item${
                  detailsPage === "SELECTION" ? " navigation-active" : ""
                }`}
              >
                Selection Details
              </p>
              {detailsPage === "SELECTION" && (
                <p className="user-details-navigation-indicator" />
              )}
            </div>
            {role !== "Agent" && (
              <div
                className="user-details-navigation-item-wrapper"
                onClick={() => {
                  onChangeDetailsPage("PAYMENT");
                }}
              >
                <p
                  className={`user-details-navigation-item${
                    detailsPage === "PAYMENT" ? " navigation-active" : ""
                  }`}
                >
                  Payment Details
                </p>
                {detailsPage === "PAYMENT" && (
                  <p className="user-details-navigation-indicator" />
                )}
              </div>
            )}
            {role !== "Agent" && (
              <div
                className="user-details-navigation-item-wrapper"
                onClick={() => {
                  onChangeDetailsPage("WALLET");
                }}
              >
                <p
                  className={`user-details-navigation-item${
                    detailsPage === "WALLET" ? " navigation-active" : ""
                  }`}
                >
                  Wallet
                </p>
                {detailsPage === "WALLET" && (
                  <p className="user-details-navigation-indicator" />
                )}
              </div>
            )}
          </div>
          {displayPage()}
        </>
      )}
    </div>
  );
};

export default UserDetails;
