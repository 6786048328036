import React from "react";
import spinner from "../../images/loading.gif";
import "./Loading.css";

const Loading = () => (
  <div className="loading-wrapper">
    <div className="loading-content">
      <img src={spinner} alt="loading..." />
    </div>
  </div>
);

export default Loading;
