import React from "react";

const Support = () => (
  <div className="customer-info">
    <div className="landing-dashboard-user-personal">
      <div className="landing-dashboard-user-info">
        <h3 className="landing-dashboard-user-name">Support</h3>
      </div>
    </div>
    <h4 className="customer-info-setion-header">Contact Support</h4>
  </div>
);

export default Support;
