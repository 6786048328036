import React, { useState } from "react";

const AssignUser = ({ setAssignUser, onAssignClient }) => {
  const [email, setEmail] = useState("");
  return (
    <div className="advance-customer">
      <div
        className="advance-customer-background"
        onClick={() => setAssignUser(false)}
      />
      <div className="content">
        <h4>Client's Email</h4>
        <input
          type="email"
          placeholder="Client Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="button"
          onClick={() => {
            onAssignClient(email);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AssignUser;
