export const dash = [
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false
];
export const customer = [
  false,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false
];
export const wallet = [
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false
];
export const install = [
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  false,
  false
];
export const bill = [
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  false
];
export const maintain = [
  false,
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false
];
export const equip = [
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false
];
export const support = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  false,
  false
];
export const settings = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  false
];
export const logout = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true
];
