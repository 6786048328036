import React from "react";
import "./AdvanceCustomer.css";

const ActivateCustomer = ({
  setActivateCustomer,
  onActivateCustomer,
  loginId
}) => (
  <div className="advance-customer">
    <div
      className="advance-customer-background"
      onClick={() => setActivateCustomer(false)}
    />
    <div className="content">
      <h5>Are you sure you want to activate this user?</h5>
      <button
        type="button"
        onClick={() => {
          onActivateCustomer(loginId);
          setActivateCustomer(false);
        }}
      >
        Yes
      </button>
      <button
        style={{
          marginRight: "20px"
        }}
        type="button"
        onClick={() => {
          setActivateCustomer(false);
        }}
      >
        No
      </button>
    </div>
  </div>
);

export default ActivateCustomer;
