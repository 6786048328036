import React from "react";
import "./NewExisting.css";

function NewExisting({ selected }) {
  return (
    <div className="NewExisting">
      <a
        type="button"
        href="/"
        className={`fp-new${selected === "new" ? " selected" : ""} `}
      >
        I'm new to Icevolt
      </a>
      <a
        type="button"
        href="/login"
        className={`fp-exist${selected === "exist" ? " selected" : ""} `}
      >
        I'm an existing customer
      </a>
    </div>
  );
}
export default NewExisting;
