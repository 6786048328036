import React, { useEffect, useState } from "react";
import UserDetails from "../UserDetails/UserDetails";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import verifiedIcon from "../../images/verified.png";
import "./AgentDetails.css";

const AgentDetails = ({
  setPage,
  selectedAgent,
  notify,
  setLoading,
  refreshPage
}) => {
  const [clients, setClients] = useState([]);
  const [detailsPage, setDetailsPage] = useState("ANALYTICS");
  const [selectedClient, setSelectedClient] = useState({});
  const [fullImage, setFullImage] = useState("");
  const [verifyAgent, setVerifyAgent] = useState(false);
  const [removeClient, setRemoveClient] = useState(false);

  const onSelectClient = user => {
    setSelectedClient(user);
    setDetailsPage("USERDETAILS");
  };

  const onVerifyAgent = id => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/verify/${id}`, {
      method: "put"
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          notify("success", data.message);
          setLoading(false);
          setTimeout(() => {
            refreshPage();
          }, 1000);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  };

  const onRemoveClient = userId => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/unAssignClient`, {
      method: "delete",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({ agentId: selectedAgent.id, userId })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/clients/${selectedAgent.id}`)
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setClients(data.responseData);
          setLoading(false);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  }, []);

  return detailsPage === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedClient}
      notify={notify}
      setPage={setDetailsPage}
      setAgentPage={setPage}
      basePage="Agents"
    />
  ) : (
    <>
      {verifyAgent && (
        <DeleteConfirmation
          setDeleteCustomer={setVerifyAgent}
          onDeleteUser={onVerifyAgent}
          userId={selectedAgent.id}
          content="Are you sure you want to verify This Agent?"
        />
      )}
      {fullImage && (
        <div
          className="agent-fullimage-wrapper"
          onClick={() => {
            setFullImage("");
          }}
        >
          <div className="agent-fullimage-content">
            <p>Click image to close</p>
            <img className="agent-fullimage" src={fullImage} alt="" />
          </div>
        </div>
      )}
      {removeClient && (
        <DeleteConfirmation
          setDeleteCustomer={setRemoveClient}
          onDeleteUser={onRemoveClient}
          userId={selectedClient.user_id}
          content="Are you sure you want to remove this client from this agent?"
        />
      )}
      <div className="userDetails-page-header">
        <p>
          <span
            onClick={() => {
              setPage("AGENTS");
            }}
          >
            Agents
          </span>
          &nbsp;&gt; Agent Details
        </p>
      </div>
      <div className="customer-info">
        <div className="landing-dashboard-user-personal">
          <div
            className="landing-dashboard-user-img"
            style={{
              backgroundImage: `url(${selectedAgent.passport})`,
              backgroundSize: "cover"
            }}
          />
          <div className="landing-dashboard-user-info">
            <h3 className="landing-dashboard-user-name">
              {`${selectedAgent.firstname} ${selectedAgent.lastname}`}
            </h3>
            <p className="landing-dashboard-user-email">
              {selectedAgent.email}
            </p>
          </div>
        </div>
        {selectedAgent.verified ? (
          <>
            <p style={{ fontWeight: "bold" }}>
              Verified
              <img
                style={{ height: "30px", marginLeft: "10px" }}
                src={verifiedIcon}
                alt=""
              />
            </p>
            <span
              style={{
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                background: "#4C9DE8",
                display: "inline-block",
                padding: "5px 20px",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={() => {
                onVerifyAgent(selectedAgent.id);
              }}
            >
              Resend Verification Mail
            </span>
          </>
        ) : (
          <span
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
              background: "#4C9DE8",
              display: "inline-block",
              padding: "5px 20px",
              borderRadius: "5px",
              cursor: "pointer"
            }}
            onClick={() => {
              setVerifyAgent(true);
            }}
          >
            Verify Agent
          </span>
        )}
        <h4 className="customer-info-setion-header">Agent Info</h4>
        <div className="agent-info-wrapper">
          <p className="agent-info-item">
            <span className="agent-info-item-label">First Name: </span>
            {selectedAgent.firstname}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Last Name: </span>
            {selectedAgent.lastname}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Phone Number: </span>
            {selectedAgent.phone_number}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Address: </span>
            {selectedAgent.address}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              Valid Means of Identification:
            </span>
            <div className="identification-wrapper">
              <div className="identification-wrapper-imgs">
                <img
                  src={selectedAgent.valid_id}
                  alt=""
                  onClick={() => {
                    setFullImage(selectedAgent.valid_id);
                  }}
                />
                {selectedAgent.admin_valid_id && (
                  <img
                    src={selectedAgent.admin_valid_id}
                    alt=""
                    onClick={() => {
                      setFullImage(selectedAgent.admin_valid_id);
                    }}
                  />
                )}
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  margin: "20px 0 0 0"
                }}
              >
                Click Images to Zoom
              </p>
            </div>
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              Identification Number:&nbsp;
            </span>
            {selectedAgent.valid_id_number}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Criminal Record? </span>
            {selectedAgent.criminal_record}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              How did to hear about us:&nbsp;
            </span>
            {selectedAgent.awareness_means}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              What is your business plan:&nbsp;
            </span>
            {selectedAgent.business_plan}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Registration Date: </span>
            {selectedAgent.date}
          </p>
        </div>
        <h4 className="customer-info-setion-header">Clients</h4>
        <div className="agent-info-wrapper">
          <div className="analytics">
            <div className="analytics-table-card">
              <div className="analytics-table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Date</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map(client => (
                      <tr key={client.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectClient(client)}
                        >
                          {`${client.firstname} ${client.lastname}`}
                        </td>
                        <td>{client.email}</td>
                        <td>{client.phone_number}</td>
                        <td>
                          {new Date(client.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#D20000",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              setSelectedClient(client);
                              setRemoveClient(true);
                            }}
                          >
                            Remove Client
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDetails;
