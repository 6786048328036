import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../images/logo.png";
import vector1 from "../../images/Vector.png";
import vector2 from "../../images/Group.png";
import vector9 from "../../images/Vector7.png";
import vector12 from "../../images/Vector10.png";
import vector13 from "../../images/Group12.png";
import agentsIcon from "../../images/agents.png";
import MobileNav from "../AdminDashboard/MobileNav";
import Overview from "./Overview";
import AgentProfile from "./AgentProfile";
import AgentProfileContract from "./AgentProfileContract";

const items = [
  { id: 1, text: "Dashboard", url: "dashboard", pict: vector1 },
  { id: 2, text: "Profile", url: "profile", pict: agentsIcon },
  { id: 3, text: "Contract", url: "contract", pict: vector2 },
  { id: 4, text: "Settings", url: "settings", pict: vector9 },
  { id: 5, text: "Logout", url: "logout", pict: vector12 }
];

const AgentDashboard = ({ setLoading, notify, refreshPage, logOut }) => {
  const [sideBar, setSideBar] = useState(true);

  const [agentDetails, setAgentDetails] = useState({});
  const [allClients, setAllClients] = useState([]);

  const history = useHistory();
  const { agentPage } = useParams();
  const agentPageObj = items.find(item => item.url === agentPage);

  const handleClick = url => {
    history.push(`/agent/${url}`);
    if (url === "logout") {
      logOut();
    }
    setSideBar(true);
  };

  const displayComponent = () => {
    switch (agentPage) {
      case "dashboard":
        return (
          <Overview
            agentDetails={agentDetails}
            allClients={allClients}
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "profile":
        return <AgentProfile selectedAgent={agentDetails} />;
      case "contract":
        return <AgentProfileContract selectedAgent={agentDetails} />;
      case "settings":
        return (
          <Overview
            agentDetails={agentDetails}
            allClients={allClients}
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "logout":
        return <h1>Logout</h1>;
      default:
        return history.push("/agent/dashboard");
    }
  };

  useEffect(() => {
    setLoading(true);
    const agenId = sessionStorage.getItem("agentId");
    fetch(`${process.env.REACT_APP_API_URL}/agent/agentdetails/${agenId}`)
      .then(res => res.json())
      .then(data => {
        setAllClients(data.responseData.agentClients);
        setAgentDetails(data.responseData.agent);
        setLoading(false);
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  return (
    <div className="admin-dashboard-page">
      <MobileNav sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`admin-dashboard-sidebar ${
          sideBar ? "translate" : "untranslate"
        }`}
      >
        <img src={logo} alt="" className="admin-dashboard-sidebar-logo" />
        <div className="admin-dashboard-sidebar-items">
          {items
            .filter(item => item.id < 4)
            .map(item => (
              <li
                key={item.id}
                className={`${agentPage === item.url ? "active" : ""}`}
                onClick={() => handleClick(item.url)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
        <div
          style={{
            borderTop: "2px solid #fff",
            width: "251px",
            margin: "0 auto"
          }}
        />
        <div className="admin-dashboard-settings-logout">
          {items
            .filter(item => item.id > 3)
            .map(item => (
              <li
                key={item.id}
                className={`${agentPage === item.url ? "active" : ""}`}
                onClick={() => handleClick(item.url)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
      </div>
      <div className="admin-dashboard-mainpage">
        <div className="header-area">
          <h4>{agentPageObj.text}</h4>
          <div style={{ position: "relative" }}>
            <img
              src={vector13}
              alt=""
              style={{ position: "absolute", top: "9.5px", left: "9.5px" }}
            />
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div className="component-area">
          {agentDetails.id && displayComponent()}
        </div>
      </div>
    </div>
  );
};
export default AgentDashboard;
