/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "reactstrap";
import Header from "../../Components/Header/Header";
import RefineSelection from "../../Components/RefineSelection/RefineSelection";
import CustomFeedbackComp from "../../Components/customFeedbackComp/customFeedbackComp";
import CreditCheck from "./CreditCheck";
import "./LandingDashboard.css";

const LandingDashboard = ({
  userDetails,
  setSelectedPlan,
  setCustomFeedback,
  setRecommendedSystem,
  setSelectedFinance,
  setAppliances,
  notify,
  logOut,
  setLoading,
  refreshPage
}) => {
  const [showRefine, setShowRefine] = useState(false);
  const [userPic, setUserPic] = useState(userDetails.userDetails.img_url);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const landingRecommendedSystem = userDetails.userRecommendedSystem;

  let landingFinance = {};
  if (userDetails.userFinance) {
    landingFinance = {
      plan: userDetails.userFinance.plan,
      downPayment: userDetails.userFinance.down_payment,
      loanPeriod: userDetails.userFinance.loan_period,
      startDateOfLoan: userDetails.userFinance.start_date_of_loan,
      monthlyPayment: userDetails.userFinance.monthly_payment,
      amountOfDownPayment: userDetails.userFinance.amount_of_down_payment,
      numberOfPayments: userDetails.userFinance.number_of_payments,
      loanAmount: userDetails.userFinance.loan_amount,
      annualInterestRate: userDetails.userFinance.annual_interest_rate,
      totalInterest: userDetails.userFinance.total_interest,
      totalCostOfLoan: userDetails.userFinance.total_cost_of_loan,
      selectedFinanceTable: userDetails.userFinance.finance_table,
      selectedGraphTable: userDetails.userFinance.graph_table
    };
  }

  const reservationFeeValue = {
    0.05: "Low",
    0.1: "Medium",
    0.15: "High"
  };
  const history = useHistory();

  const onCloseRefine = path => {
    setShowRefine(false);
    if (path) {
      setSelectedPlan("CUSTOM");
      history.push("/custom-preview2");
    }
  };

  const onSelectFinancing = () => {
    history.push({ pathname: "/select-financing", type: "updateFinance" });
  };

  const onImageUpload = e => {
    setLoading(true);
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      fetch(`${process.env.REACT_APP_API_URL}/user/uploadProfilePicture`, {
        method: "put",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          userId: userDetails.userDetails.user_id,
          logoStr: reader.result
        })
      })
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          if (data.success) {
            notify("success", "Image Uploaded");
            setUserPic(data.responseData.url);
          }
        })
        .catch(() => {
          setLoading(false);
          notify("error", "Error Uploading Image");
        });
    };
  };

  const onEditPackage = () => {
    setShowCustomFeedback(true);
  };

  const onCloseCustomFeedback = () => {
    setShowCustomFeedback(false);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (userDetails.userDetails.credit_status === "NONE") {
      notify(
        "info",
        "Run a credit check to confirm your eligibilty for our financing options"
      );
    }

    if (history.location.type === "update") {
      refreshPage();
    }
  }, []);

  return (
    <div className="LandingDashboard">
      {showCustomFeedback && (
        <CustomFeedbackComp
          onClose={onCloseCustomFeedback}
          userCustomFeedback={userDetails.userCustomFeedback}
          setUserCustomFeedback={setCustomFeedback}
          setRecommendedSystem={setRecommendedSystem}
          setAppliances={setAppliances}
          selectedPlan={userDetails.userDetails.plan}
          selectedFinance={landingFinance}
          setSelectedFinance={setSelectedFinance}
          setLoading={setLoading}
        />
      )}
      {showRefine && (
        <RefineSelection
          onClose={onCloseRefine}
          userCustomFeedback={userDetails.userCustomFeedback}
          setUserCustomFeedback={setCustomFeedback}
          recommendedSystem={userDetails.userRecommendedSystem}
          setRecommendedSystem={setRecommendedSystem}
          selectedFinance={landingFinance}
          setSelectedFinance={setSelectedFinance}
          appliances={userDetails.userRecommendedSystem.appliances}
          setAppliances={setAppliances}
          pricebook={userDetails.pricebook}
          basicRecommended={userDetails.basicRecommendationProducts}
          selectedPlan={userDetails.userDetails.plan}
        />
      )}
      {confirmationOpen && (
        <CreditCheck
          setConfirmationOpen={setConfirmationOpen}
          notify={notify}
          userId={userDetails.userDetails.user_id}
        />
      )}
      <Header page="Dashboard" logOut={logOut} />
      <div className="preview-dashboard">
        <p className="landing-dashboard-phone">
          <span className="bold">IceVolt Reservation Number: </span>
          {userDetails.userDetails.user_account_id.split("-")[0]}
        </p>
        <div className="preview-body">
          <div>
            <div className="landing-dashboard-user-personal-wrapper">
              <div className="landing-dashboard-user-personal">
                <div
                  className="landing-dashboard-user-img"
                  style={{
                    backgroundImage: `url(${userPic})`,
                    backgroundSize: "cover"
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onImageUpload}
                    className="landing-dashboard-user-img-icon"
                  />
                </div>
                <div className="landing-dashboard-user-info">
                  <h3 className="landing-dashboard-user-name">
                    {`${userDetails.userDetails.firstname} ${userDetails.userDetails.lastname}`}
                  </h3>
                  <p className="landing-dashboard-user-email">
                    {userDetails.userDetails.email}
                  </p>
                </div>
              </div>
            </div>
            <h4 className="landing-dashboard-contact-info">
              Contact Information
            </h4>
            <p className="landing-dashboard-installation-address">
              <span className="bold">Installation Address: </span>
              {userDetails.userDetails.installation_address}
            </p>
            <p className="landing-dashboard-phone">
              <span className="bold">Phone: </span>
              {userDetails.userDetails.phone_number}
            </p>
          </div>
          <div className="run-credit-check">
            <a
              onClick={() => {
                window.gtag("event", "reservation_fee_button_click", {
                  event_category: "reservation_fee"
                });
              }}
              href="https://flutterwave.com/pay/icevolt-reservation-fee"
              target="_blank"
              rel="noreferrer"
            >
              Pay Reservation Fee
            </a>
            <button
              type="button"
              onClick={() => {
                window.gtag("event", "credit_check_button_click", {
                  event_category: "credit_check"
                });
                setConfirmationOpen(true);
              }}
            >
              Run Credit Check
            </button>
          </div>
        </div>
      </div>
      <div className="preview-selection">
        <div className="preview-header">
          <p className="preview-header-text">My Selection</p>
        </div>
        <div className="preview-body">
          <p className="preview-plan-text">{userDetails.userDetails.plan}</p>
          {userDetails.userDetails.type === "Lead" && (
            <div className="form-submit-btn">
              <button
                type="button"
                onClick={() => {
                  history.push({ pathname: "/", type: "edit" });
                }}
              >
                {`${
                  userDetails.userRecommendedSystem ? "Change" : "Make"
                } Selection`}
              </button>
            </div>
          )}
        </div>
      </div>
      {userDetails.userRecommendedSystem && (
        <>
          {userDetails.userDetails.plan === "CUSTOM" ? (
            <div className="preview-selection-details">
              <Form className="form-container">
                <h5 className="mb-3 form-header">Selection Details</h5>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">
                      Regular Household Appliances
                    </p>
                    <p className="refine-details-header-subtext">
                      Low consumption, Daily Use
                    </p>
                  </div>
                  <div className="refine-details-body">
                    <div className="appliance-wrapper-label">
                      <div className="appliance-checkbox-name-label" />
                      <div className="appliance-quantity-label">
                        <p>Qty</p>
                      </div>
                      <div className="appliance-wattage-label">
                        <p>wattage</p>
                      </div>
                      <div className="appliance-usage-label">
                        <p>Daily Usage(Hrs)</p>
                      </div>
                    </div>
                    <div className="appliance-wrapper-label appliance-label2">
                      <div className="appliance-checkbox-name-label" />
                      <div className="appliance-quantity-label">
                        <p>Qty</p>
                      </div>
                      <div className="appliance-wattage-label">
                        <p>wattage</p>
                      </div>
                      <div className="appliance-usage-label">
                        <p>Daily Usage(Hrs)</p>
                      </div>
                    </div>
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "Regular Household Appliances" &&
                        appliance.checked && (
                          <div
                            className="appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                            <div className="appliance-wattage">
                              <div className="appliance-wattage-input-wrapper">
                                <input
                                  className="appliance-wattage-input"
                                  disabled
                                  value={appliance.wattage}
                                />
                                <p>W</p>
                              </div>
                            </div>
                            <div className="appliance-usage">
                              <div className="appliance-usage-input-wrapper">
                                <input
                                  className="appliance-usage-input"
                                  disabled
                                  value={appliance.daily_run_hours}
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
                <div className="refine-details">
                  <div className="refine-details-header">
                    <p className="refine-details-header-text">
                      High Power Appliances
                    </p>
                    <p className="refine-details-header-subtext">
                      High consumption, Regular Use
                    </p>
                  </div>
                  <div className="refine-details-body">
                    <div className="appliance-wrapper-label">
                      <div className="appliance-checkbox-name-label" />
                      <div className="appliance-quantity-label">
                        <p>Qty</p>
                      </div>
                      <div className="appliance-wattage-label">
                        <p>wattage</p>
                      </div>
                      <div className="appliance-usage-label">
                        <p>Daily Usage(Hrs)</p>
                      </div>
                    </div>
                    <div className="appliance-wrapper-label appliance-label2">
                      <div className="appliance-checkbox-name-label" />
                      <div className="appliance-quantity-label">
                        <p>Qty</p>
                      </div>
                      <div className="appliance-wattage-label">
                        <p>wattage</p>
                      </div>
                      <div className="appliance-usage-label">
                        <p>Daily Usage(Hrs)</p>
                      </div>
                    </div>
                    {userDetails.userRecommendedSystem.appliances.map(
                      appliance =>
                        appliance.appliance_category ===
                          "High Power Appliances" &&
                        appliance.checked && (
                          <div
                            className="appliance-wrapper"
                            key={appliance.appliance_id}
                          >
                            <div className="appliance-checkbox-name">
                              {appliance.appliance_name}
                            </div>
                            <div className="appliance-quantity">
                              <p>{appliance.custom_quantity}</p>
                            </div>
                            <div className="appliance-wattage">
                              <div className="appliance-wattage-input-wrapper">
                                <input
                                  className="appliance-wattage-input"
                                  value={appliance.wattage}
                                  disabled
                                />
                                <p>W</p>
                              </div>
                            </div>
                            <div className="appliance-usage">
                              <div className="appliance-usage-input-wrapper">
                                <input
                                  className="appliance-usage-input"
                                  value={appliance.daily_run_hours}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="refine-details">
                      <div className="refine-details-header">
                        <p className="refine-details-header-text">
                          Envac Proprietary Thermal Storage Equipment (Air
                          Conditioning and Water Heater)
                        </p>
                        <p className="refine-details-header-subtext" />
                      </div>
                      <div className="refine-details-body">
                        {userDetails.userRecommendedSystem.appliances.map(
                          appliance =>
                            appliance.appliance_category ===
                              "Envac Proprietary Thermal Storage Equipment (Air Conditioning and Water Heater)" &&
                            appliance.checked && (
                              <div
                                className="grid-appliance-wrapper"
                                key={appliance.appliance_id}
                              >
                                <div className="appliance-checkbox-name">
                                  {appliance.appliance_name}
                                </div>
                                <div className="appliance-quantity">
                                  <p>{appliance.custom_quantity}</p>
                                </div>
                                <div className="appliance-wattage">
                                  <div className="appliance-wattage-input-wrapper">
                                    <input
                                      className="appliance-wattage-input"
                                      value={appliance.wattage}
                                      disabled
                                    />
                                    <p>W</p>
                                  </div>
                                </div>
                                <div className="appliance-usage">
                                  <div className="appliance-usage-input-wrapper">
                                    <input
                                      className="appliance-usage-input"
                                      value={appliance.daily_run_hours}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="refine-details">
                      <div className="refine-details-header">
                        <p className="refine-details-header-text">
                          Lighthing Requirements
                        </p>
                        <p className="refine-details-header-subtext" />
                      </div>
                      <div className="refine-details-body">
                        {userDetails.userRecommendedSystem.appliances.map(
                          appliance =>
                            appliance.appliance_category ===
                              "Lighting Requirements" &&
                            appliance.checked && (
                              <div
                                className="grid-appliance-wrapper"
                                key={appliance.appliance_id}
                              >
                                <div className="appliance-checkbox-name">
                                  {appliance.appliance_name}
                                </div>
                                <div className="appliance-quantity">
                                  <p>{appliance.custom_quantity}</p>
                                </div>
                                <div className="appliance-wattage">
                                  <div className="appliance-wattage-input-wrapper">
                                    <input
                                      className="appliance-wattage-input"
                                      value={appliance.wattage}
                                      disabled
                                    />
                                    <p>W</p>
                                  </div>
                                </div>
                                <div className="appliance-usage">
                                  <div className="appliance-usage-input-wrapper">
                                    <input
                                      className="appliance-usage-input"
                                      value={appliance.daily_run_hours}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="refine-details">
                      <div className="refine-details-header">
                        <p className="refine-details-header-text">Others</p>
                        <p className="refine-details-header-subtext">
                          Custom Appliances
                        </p>
                      </div>
                      <div className="refine-details-body">
                        {userDetails.userRecommendedSystem.appliances.map(
                          appliance =>
                            appliance.appliance_category === "Others" &&
                            appliance.checked && (
                              <div
                                className="grid-appliance-wrapper"
                                key={appliance.appliance_id}
                              >
                                <div className="appliance-checkbox-name">
                                  {appliance.appliance_name}
                                </div>
                                <div className="appliance-quantity">
                                  <p>{appliance.custom_quantity}</p>
                                </div>
                                <div className="appliance-wattage">
                                  <div className="appliance-wattage-input-wrapper">
                                    <input
                                      className="appliance-wattage-input"
                                      value={appliance.wattage}
                                      disabled
                                    />
                                    <p>W</p>
                                  </div>
                                </div>
                                <div className="appliance-usage">
                                  <div className="appliance-usage-input-wrapper">
                                    <input
                                      className="appliance-usage-input"
                                      value={appliance.daily_run_hours}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </Col>
                  {/* <Col>
                    <div className="refine-details">
                      <div className="refine-details-header">
                        <p className="refine-details-header-text">
                          Water Heater Points
                        </p>
                        <p className="refine-details-header-subtext" />
                      </div>
                      <div className="refine-details-body">
                        {userDetails.userRecommendedSystem.appliances.map(
                          appliance =>
                            appliance.appliance_category ===
                              "Water Heater Points" && (
                              <div
                                className="grid-appliance-wrapper"
                                key={appliance.appliance_id}
                              >
                                <div className="appliance-checkbox-name">
                                  {appliance.appliance_name}
                                </div>
                                <div className="appliance-quantity">
                                  <p>{appliance.custom_quantity}</p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <div className="preview-body custom-preview-selection-details">
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Battery Replacement Package
                      </p>
                      <p className="selection-details-element-value">
                        {
                          userDetails.userCustomFeedback
                            .battery_replacement_package
                        }
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Quaterly Maintenance Package
                      </p>
                      <p className="selection-details-element-value">
                        {
                          userDetails.userCustomFeedback
                            .quarterly_maintenance_package
                        }
                      </p>
                    </div>
                    <div className="selection-details-element">
                      <p className="selection-details-element-label">
                        Extended Warranty Package
                      </p>
                      <p className="selection-details-element-value">
                        {
                          userDetails.userCustomFeedback
                            .extended_warranty_package
                        }
                      </p>
                    </div>
                    <div className="selection-details-element" />
                  </div>
                </Row>
                <h5 className="mb-3 form-header">Design Load Calculations</h5>
                <div className="preview-body custom-preview-selection-details">
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Start Up Power Requirement(W)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalStartUpPower}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Thermal Battery Requirement
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.thermalBatteryRequirement}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Energy Use(WH)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalEnergy}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Power Requirement(W)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalPower}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Adjustment for Sunlight Intensity
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.sunlightAdjustment}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Kilowatts
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalKilowatts}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Power (Thermal Storage)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalThermalPower}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Total Kilowatts (Thermal Storage)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.totalThermalKilowatts}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Main Battery Requirement
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.mainBatteryRequirement}
                    </p>
                  </div>
                  <div className="refine-selection-details-element" />
                </div>
                <h5 className="mb-3 form-header">Equipment Specification</h5>
                <div className="preview-body custom-preview-selection-details">
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Inverter Capacity
                    </p>
                    <p className="selection-details-element-value">
                      {
                        landingRecommendedSystem.products["Inverter Package"]
                          .rating
                      }
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Battery Bank Capacity
                    </p>
                    <p className="selection-details-element-value">
                      {
                        landingRecommendedSystem.products["Battery Package"]
                          .rating
                      }
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Thermal Storage Unit (EnVAC)
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.products["AC PACKAGE"].rating}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Solar Panel Sizing
                    </p>
                    <p className="selection-details-element-value">
                      {Number(
                        landingRecommendedSystem.products["Solar Package"]
                          .rating
                      )}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Water Heater Capacity
                    </p>
                    <p className="selection-details-element-value">
                      {landingRecommendedSystem.products["WATER HEATER"].rating}
                    </p>
                  </div>
                  <div className="refine-selection-details-element">
                    <p className="selection-details-element-label">
                      Space Cooling (Nature Breeze)
                    </p>
                    <p className="selection-details-element-value">
                      {
                        landingRecommendedSystem.products[
                          "Nature Breeze Package"
                        ].rating
                      }
                    </p>
                  </div>
                </div>
              </Form>
              {userDetails.userDetails.type === "Lead" && (
                <div className="form-submit-btn">
                  <button type="button" onClick={() => setShowRefine(true)}>
                    Refine Selection
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="preview-description">
              <div className="preview-header">
                <p className="preview-header-text">Description</p>
              </div>
              <div className="preview-body">
                <p className="preview-body-text">
                  {landingRecommendedSystem.description}
                </p>
                <p className="recommended-details-tile-body-text">
                  {landingRecommendedSystem.sub_description}
                </p>
              </div>
            </div>
          )}
          <div className="preview-recommended">
            <div className="preview-header">
              <p className="preview-header-text">Recommended System</p>
            </div>
            <div className="preview-body">
              <p className="preview-body-text">
                {landingRecommendedSystem.make}
              </p>
            </div>
          </div>
          {userDetails.userDetails.plan !== "CUSTOM" && (
            <>
              <div className="preview-recommended-details">
                <div className="preview-header">
                  <p className="preview-header-text">
                    Recommended System Details
                  </p>
                </div>
                <div className="preview-body recommended-details-body">
                  {Object.keys(landingRecommendedSystem.products).map(
                    productKey =>
                      productKey !== "IceVolt Operating Margin" && (
                        <div
                          className="recommended-details-tile"
                          key={productKey}
                        >
                          <div className="recommended-details-tile-header">
                            <p className="recommended-details-tile-header-text">
                              {productKey}
                            </p>
                            <p className="recommended-details-tile-header-text">{`Qty: ${landingRecommendedSystem.products[productKey].quantity}`}</p>
                          </div>
                          <div className="recommended-details-tile-body">
                            {landingRecommendedSystem.products[
                              productKey
                            ].components.map((component, i) => (
                              <p
                                className="recommended-details-tile-body-text"
                                key={`${component}${i}`}
                              >
                                {component}
                              </p>
                            ))}
                          </div>
                        </div>
                      )
                  )}
                  {userDetails.userDetails.type === "Lead" && (
                    <div className="form-submit-btn">
                      {userDetails.userDetails.plan !==
                        "STARTER SOLAR GENERATOR" &&
                        userDetails.userDetails.plan !==
                          "BASIC SOLAR GENERATOR" && (
                          <button
                            type="button"
                            onClick={() => setShowRefine(true)}
                          >
                            Refine Selection
                          </button>
                          // eslint-disable-next-line indent
                        )}
                    </div>
                  )}
                </div>
              </div>
              {userDetails.userDetails.plan !== "STARTER SOLAR GENERATOR" &&
                userDetails.userDetails.plan !== "BASIC SOLAR GENERATOR" && (
                  <div>
                    <div className="preview-body custom-preview-selection-details">
                      <div className="selection-details-element">
                        <p className="selection-details-element-label">
                          Battery Replacement Package
                        </p>
                        <p className="selection-details-element-value">
                          {
                            userDetails.userCustomFeedback
                              .battery_replacement_package
                          }
                        </p>
                      </div>
                      <div className="selection-details-element">
                        <p className="selection-details-element-label">
                          Quaterly Maintenance Package
                        </p>
                        <p className="selection-details-element-value">
                          {
                            userDetails.userCustomFeedback
                              .quarterly_maintenance_package
                          }
                        </p>
                      </div>
                      {(userDetails.userDetails.plan === "PREMIUM SOLAR" ||
                        userDetails.userDetails.plan === "SILVER SOLAR" ||
                        userDetails.userDetails.plan === "GOLD SOLAR" ||
                        userDetails.userDetails.plan === "PLATINUM SOLAR" ||
                        userDetails.userDetails.plan === "PREMIUM BACKUP") && (
                        <div className="selection-details-element">
                          <p className="selection-details-element-label">
                            Extended Warranty Package
                          </p>
                          <p className="selection-details-element-value">
                            {
                              userDetails.userCustomFeedback
                                .extended_warranty_package
                            }
                          </p>
                        </div>
                      )}
                      <div className="selection-details-element" />
                      {userDetails.userDetails.type === "Lead" && (
                        <div className="form-submit-btn">
                          <button type="button" onClick={() => onEditPackage()}>
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  // eslint-disable-next-line indent
                )}
            </>
          )}
          <div className="preview-financing">
            <div className="preview-header">
              <p className="preview-header-text">My Selected Financing</p>
            </div>
            <div className="preview-body custom-preview-selection-details">
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Reservation Fee
                </p>
                <p className="selection-details-element-value">
                  {
                    reservationFeeValue[
                      Number(userDetails.userFinance.down_payment)
                    ]
                  }
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Power Contract Duration in years
                </p>
                <p className="selection-details-element-value">
                  {userDetails.userFinance.loan_period}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Monthly Flat Rate Utility Bill
                </p>
                <p className="selection-details-element-value">
                  {`₦${Number(
                    userDetails.userFinance.monthly_payment
                  ).toLocaleString()}`}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Amount of Reservation Fee
                </p>
                <p className="selection-details-element-value">
                  {`₦${Number(
                    userDetails.userFinance.amount_of_down_payment
                  ).toLocaleString()}`}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Number of Payments
                </p>
                <p className="selection-details-element-value">
                  {userDetails.userFinance.number_of_payments}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Start Date of Power Contract
                </p>
                <p className="selection-details-element-value">
                  {`${new Date(
                    userDetails.userFinance.start_date_of_loan
                  ).toDateString()}`}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  End Date of Power Contract
                </p>
                <p className="selection-details-element-value">
                  {new Date(
                    userDetails.userFinance.finance_table[
                      userDetails.userFinance.finance_table.length - 1
                    ].paymentDate
                  ).toDateString()}
                </p>
              </div>
              {userDetails.userDetails.type === "Lead" && (
                <div className="form-submit-btn">
                  <button type="button" onClick={() => onSelectFinancing()}>
                    Edit Subscription
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LandingDashboard;
