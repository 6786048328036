import React, { useState } from "react";
import { Form, Label, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import close from "../../svg/close.svg";
import getFinancing from "../RefineSelection/financeHandler";
import "./customFeedbackComp.css";

function CustomFeedbackComp({
  onClose,
  userCustomFeedback,
  setUserCustomFeedback,
  setRecommendedSystem,
  selectedFinance,
  setSelectedFinance,
  setAppliances,
  setPricebook,
  setBasicRecommended,
  selectedPlan,
  setLoading
}) {
  const [customFeedback, setCustomFeedback] = useState(
    userCustomFeedback || {
      battery_replacement_package: "Not Included",
      quarterly_maintenance_package: "Not Included",
      extended_warranty_package: "Not Included",
      solar_panels: "No Solar"
    }
  );

  const history = useHistory();

  const planQuantity = {
    "STARTER SOLAR GENERATOR": ["starter_solar_generator_quantity"],
    "BASIC SOLAR GENERATOR": ["basic_solar_generator_quantity"],
    "ESSENTIAL SOLAR": ["essential_solar_quantity"],
    "STARTER SOLAR": ["starter_solar_quantity"],
    "STARTER PLUS SOLAR": ["starter_plus_solar_quantity"],
    "BASIC SOLAR": ["basic_solar_quantity"],
    "BASIC PLUS SOLAR": ["basic_plus_solar_quantity"],
    "PREMIUM SOLAR": ["premium_solar_quantity"],
    "SILVER SOLAR": ["silver_solar_quantity"],
    "GOLD SOLAR": ["gold_solar_quantity"],
    "PLATINUM SOLAR": ["platinum_solar_quantity"],
    "ESSENTIAL BACKUP": ["essential_bakup_quantity"],
    "STARTER BACKUP": ["starter_backup_quantity"],
    "STARTER PLUS BACKUP": ["starter_plus_backup_quantity"],
    "BASIC BACKUP": ["basic_backup_quantity"],
    "BASIC PLUS BACKUP": ["basic_plus_backup_quantity"],
    "PREMIUM BACKUP": ["premium_backup_quantity"],
    CUSTOM: ["custom_quantity"]
  };

  const onSelectBatRepPack = e => {
    setCustomFeedback({
      ...customFeedback,
      battery_replacement_package: e.target.value
    });
  };

  const onSelectQuaMainPack = e => {
    setCustomFeedback({
      ...customFeedback,
      quarterly_maintenance_package: e.target.value
    });
  };

  const onSelectExtWarPack = e => {
    setCustomFeedback({
      ...customFeedback,
      extended_warranty_package: e.target.value
    });
  };

  const onSubmitSolution = () => {
    setLoading(true);
    setUserCustomFeedback(customFeedback);
    fetch(`${process.env.REACT_APP_API_URL}/solution/standardSolution`, {
      method: "post",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({ plan: selectedPlan, customFeedback })
    })
      .then(res => res.json())
      .then(data => {
        setRecommendedSystem(data.responseData.productsRecommendation);
        if (setPricebook) {
          setPricebook(data.responseData.pricebook);
        }

        const selectedAppliances = data.responseData.appliances.map(
          appliance => ({
            ...appliance,
            checked: Boolean(appliance[planQuantity[selectedPlan]]),
            custom_quantity: appliance[planQuantity[selectedPlan]]
          })
        );

        setAppliances(selectedAppliances);

        if (setBasicRecommended) {
          setBasicRecommended(data.responseData.basicRecommendationProducts);
        }

        if (setSelectedFinance) {
          getFinancing(
            selectedFinance,
            data.responseData.productsRecommendation,
            setSelectedFinance
          );
        }

        if (history.location.pathname === "/standard-solution") {
          history.push("/standard-preview");
        } else if (history.location.pathname === "/landing-dashboard") {
          history.push("/standard-preview2");
        } else {
          onClose();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="Refine-selection-wrapper feedback-modal-wrapper">
      <div className="Refine-selection-modal feedback-modal">
        <div className="close-icon-wrapper">
          <img
            className="close-icon"
            src={close}
            alt=""
            onClick={() => onClose()}
          />
        </div>
        <Form className="form-container">
          <h5 className="mb-3 form-header">Package Selection</h5>
          <Row className="form-row">
            <Col>
              <legend>Battery Replacement Package</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="battery_replacement_package"
                        value="Included"
                        onClick={onSelectBatRepPack}
                        defaultChecked={
                          customFeedback.battery_replacement_package ===
                          "Included"
                        }
                      />
                      Included
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="battery_replacement_package"
                        value="Not Included"
                        onClick={onSelectBatRepPack}
                        defaultChecked={
                          customFeedback.battery_replacement_package ===
                          "Not Included"
                        }
                      />
                      Not Included
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <legend>Quarterly Maintenance Package</legend>
              <div className="form-row-radio-section">
                <div className="form-row-radio-wrapper">
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="quarterly_maintenance_package"
                        value="Included"
                        onClick={onSelectQuaMainPack}
                        defaultChecked={
                          customFeedback.quarterly_maintenance_package ===
                          "Included"
                        }
                      />
                      Included
                    </Label>
                  </div>
                  <div className="form-row-radio">
                    <Label>
                      <input
                        className="form-input-radio"
                        type="radio"
                        name="quarterly_maintenance_package"
                        value="Not Included"
                        onClick={onSelectQuaMainPack}
                        defaultChecked={
                          customFeedback.quarterly_maintenance_package ===
                          "Not Included"
                        }
                      />
                      Not Included
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
            {(selectedPlan === "PREMIUM SOLAR" ||
              selectedPlan === "SILVER SOLAR" ||
              selectedPlan === "GOLD SOLAR" ||
              selectedPlan === "PLATINUM SOLAR" ||
              selectedPlan === "PREMIUM BACKUP") && (
              <Col>
                <legend>Extended Warranty Package</legend>
                <div className="form-row-radio-section">
                  <div className="form-row-radio-wrapper">
                    <div className="form-row-radio">
                      <Label>
                        <input
                          className="form-input-radio"
                          type="radio"
                          name="extended_warranty_package"
                          value="Included"
                          onClick={onSelectExtWarPack}
                          defaultChecked={
                            customFeedback.extended_warranty_package ===
                            "Included"
                          }
                        />
                        Included
                      </Label>
                    </div>
                    <div className="form-row-radio">
                      <Label>
                        <input
                          className="form-input-radio"
                          type="radio"
                          name="extended_warranty_package"
                          value="Not Included"
                          onClick={onSelectExtWarPack}
                          defaultChecked={
                            customFeedback.extended_warranty_package ===
                            "Not Included"
                          }
                        />
                        Not Included
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <div
            className="form-submit-btn"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button
              type="button"
              onClick={() => onClose()}
              className="preview-refine-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => onSubmitSolution()}
              className="preview-order-btn"
            >
              Proceed
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CustomFeedbackComp;
