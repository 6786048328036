import React from "react";
// import "./AdvanceCustomer.css";

const DeleteConfirmation = ({
  setDeleteCustomer,
  onDeleteUser,
  userId,
  content
}) => (
  <div className="advance-customer">
    <div
      className="advance-customer-background"
      onClick={() => setDeleteCustomer(false)}
    />
    <div className="content">
      <h5>{content}</h5>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          onDeleteUser(userId);
          setDeleteCustomer(false);
        }}
      >
        Yes
      </button>
      <button
        style={{
          marginRight: "20px"
        }}
        type="button"
        onClick={() => {
          setDeleteCustomer(false);
        }}
      >
        No
      </button>
    </div>
  </div>
);

export default DeleteConfirmation;
