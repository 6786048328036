import React, { useState } from "react";
import "./AdvanceCustomer.css";

const AdvanceCustomer = ({ setAdvanceCustomer, onAdUser, type, userId }) => {
  const [testUser, setTestUser] = useState("No");
  const [message, setMessage] = useState("");
  return (
    <div className="advance-customer">
      <div
        className="advance-customer-background"
        onClick={() => setAdvanceCustomer(false)}
      />
      <div className="content">
        <h4>Is this a test User. Please select the correct option</h4>
        <select
          onChange={e => {
            setTestUser(e.target.value);
          }}
          value={testUser}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <h4>Why are you advancing this customer to the next level?</h4>
        {/* <input type="text" name="" id="" /> */}
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Type your reason here..."
          value={message}
          onChange={e => {
            setMessage(e.target.value);
          }}
        />
        <button
          type="button"
          onClick={() => {
            onAdUser(type, userId, testUser, message);
            setAdvanceCustomer(false);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AdvanceCustomer;
