import React from "react";
import "./Header.css";
import logo from "../../images/logo.jpg";

function Header({ logOut, page }) {
  return (
    <div>
      <div className="header">
        <a href="https://icevolt.ca/" target="_blank" rel="noopener noreferrer">
          <img src={logo} className="logo" alt="logo" />
        </a>
        <div className="header-right-wrapper">
          {page === "Dashboard" && (
            <p onClick={logOut} className="logout-btn">
              Logout
            </p>
          )}
          <a
            href="https://icevolt.ca/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button type="button" className="header-btn">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Header;
