import React, { useEffect, useState } from "react";

const AgentProfile = ({ selectedAgent }) => {
  const [fullImage, setFullImage] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      {fullImage && (
        <div
          className="agent-fullimage-wrapper"
          onClick={() => {
            setFullImage("");
          }}
        >
          <div className="agent-fullimage-content">
            <p>Click image to close</p>
            <img
              className="agent-fullimage"
              src={selectedAgent.valid_id}
              alt=""
            />
          </div>
        </div>
      )}
      <div className="customer-info">
        <div className="landing-dashboard-user-personal">
          <div
            className="landing-dashboard-user-img"
            style={{
              backgroundImage: `url(${selectedAgent.passport})`,
              backgroundSize: "cover"
            }}
          />
          <div className="landing-dashboard-user-info">
            <h3 className="landing-dashboard-user-name">
              {`${selectedAgent.firstname} ${selectedAgent.lastname}`}
            </h3>
            <p className="landing-dashboard-user-email">
              {selectedAgent.email}
            </p>
          </div>
        </div>
        <h4 className="customer-info-setion-header">Agent Info</h4>
        <div className="agent-info-wrapper">
          <p className="agent-info-item">
            <span className="agent-info-item-label">Phone Number: </span>
            {selectedAgent.phone_number}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Address: </span>
            {selectedAgent.address}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              Valid Means of Identification:
            </span>
            <div className="identification-wrapper">
              <div className="identification-wrapper-imgs">
                <img
                  src={selectedAgent.valid_id}
                  alt=""
                  onClick={() => {
                    setFullImage(selectedAgent.valid_id);
                  }}
                />
                {selectedAgent.admin_valid_id && (
                  <img
                    src={selectedAgent.admin_valid_id}
                    alt=""
                    onClick={() => {
                      setFullImage(selectedAgent.admin_valid_id);
                    }}
                  />
                )}
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  margin: "20px 0 0 0"
                }}
              >
                Click Images to Zoom
              </p>
            </div>
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              Identification Number:&nbsp;
            </span>
            {selectedAgent.valid_id_number}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Criminal Record? </span>
            {selectedAgent.criminal_record}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              How did to hear about us:&nbsp;
            </span>
            {selectedAgent.awareness_means}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">
              What is your business plan:&nbsp;
            </span>
            {selectedAgent.business_plan}
          </p>
          <p className="agent-info-item">
            <span className="agent-info-item-label">Registration Date: </span>
            {selectedAgent.date}
          </p>
        </div>
      </div>
    </>
  );
};

export default AgentProfile;
