const getNGN = dollar => {
  const internalFXRate = 850;

  return Number(dollar * internalFXRate);
};

const getProductsRecommendationPrice = async (
  pricebook,
  equipment_specification,
  plan
) => {
  let inverterPackagePrice = 0;
  if (plan === "CUSTOM") {
    inverterPackagePrice = pricebook
      .map(product =>
        product.description ===
          equipment_specification["Inverter Package"].package()[0] ||
        product.description ===
          equipment_specification["Inverter Package"].package()[1]
          ? getNGN(product.price) *
            equipment_specification["Inverter Package"].quantity
          : 0
      )
      .reduce((a, b) => a + b);
  } else {
    inverterPackagePrice = pricebook
      .map(product =>
        product.description === "5 KVA Inverter and Accessories" ||
        product.description === "3 KVA Inverter and Accessories"
          ? getNGN(product.price) *
            equipment_specification["Inverter Package"].quantity
          : 0
      )
      .reduce((a, b) => a + b);
  }

  const batteryPackagePrice = pricebook
    .map(product =>
      product.package === "Battery Package"
        ? getNGN(product.price) *
          equipment_specification["Battery Package"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  const solarPackagePrice = pricebook
    .map(product =>
      product.package === "Solar Package"
        ? getNGN(product.price) *
          equipment_specification["Solar Package"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  const solarAssessoriesPrice = pricebook
    .map(product =>
      product.package === "Solar Assessories"
        ? getNGN(product.price) *
          equipment_specification["Solar Assessories"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  const batteryAssessoriesPrice = pricebook
    .map(product =>
      product.package === "Battery Assessories"
        ? getNGN(product.price) *
          equipment_specification["Battery Assessories"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  const stackingCardPrice = pricebook
    .map(product =>
      product.package === "Stacking Card"
        ? getNGN(product.price) *
          equipment_specification["Stacking Card"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  let envacHeaterPackagePrice = 0;
  if (plan !== "PREMIUM SOLAR") {
    envacHeaterPackagePrice = pricebook
      .map(product =>
        product.package === equipment_specification["WATER HEATER"].package()
          ? getNGN(product.price) *
            equipment_specification["WATER HEATER"].quantity
          : 0
      )
      .reduce((a, b) => a + b);
  }

  const natureBreezePackagePrice = pricebook
    .map(product =>
      product.package === "Nature Breeze Package"
        ? getNGN(product.price) *
          equipment_specification["Nature Breeze Package"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  let envacACPackagPrice = 0;
  if (plan !== "PREMIUM SOLAR") {
    envacACPackagPrice = pricebook
      .map(product =>
        product.package === equipment_specification["AC PACKAGE"].package()
          ? getNGN(product.price) *
            equipment_specification["AC PACKAGE"].quantity
          : 0
      )
      .reduce((a, b) => a + b);
  }

  const meterPackagePrice = pricebook
    .map(product =>
      product.package === "Meter Package"
        ? getNGN(product.price) *
          equipment_specification["Meter Package"].quantity
        : 0
    )
    .reduce((a, b) => a + b);

  return [
    inverterPackagePrice,
    batteryPackagePrice,
    solarPackagePrice,
    solarAssessoriesPrice,
    batteryAssessoriesPrice,
    stackingCardPrice,
    envacHeaterPackagePrice,
    natureBreezePackagePrice,
    envacACPackagPrice,
    meterPackagePrice
  ];
};

const getProductRecommendation = async (
  formData,
  selectedAppliances,
  pricebook,
  basicRecommended
) => {
  // select checked appliances
  const appliances = selectedAppliances.filter(appliance => appliance.checked);

  // basic recommendation products
  const basicRecommendationProducts = basicRecommended;

  const operatingMargin = 0.05;
  const extendedWarrantyMargin = 0.1;

  // country value
  const country_value = {
    southern: 1500,
    northern: 2300,
    middle_belt: 1800,
    lagos: 2593.1,
    rivers: 2424.3
  };
  // I have to implement the regional data vlookup correctly

  // power, startup power, energy list sum
  const power_list = appliances.map(
    appliance => appliance.custom_quantity * appliance.wattage
  );

  const highPowerPowerList = appliances.map(appliance =>
    appliance.appliance_name === "AC1(1hp)" ||
    appliance.appliance_name === "AC2(1.5hp)"
      ? appliance.custom_quantity * appliance.wattage
      : 0
  );

  const highPowerPower = highPowerPowerList.length
    ? highPowerPowerList.reduce((a, b) => a + b)
    : 0;

  const startup_power_list = appliances.map(
    appliance =>
      appliance.custom_quantity * appliance.wattage * appliance.power_point
  );

  const energy_list = appliances.map(
    appliance =>
      appliance.custom_quantity * appliance.wattage * appliance.daily_run_hours
  );

  const highPowerEnergyList = appliances.map(appliance =>
    appliance.appliance_name === "AC1(1hp)" ||
    appliance.appliance_name === "AC2(1.5hp)"
      ? appliance.custom_quantity *
        appliance.wattage *
        appliance.daily_run_hours
      : 0
  );

  const highPowerEnergy = highPowerEnergyList.length
    ? highPowerEnergyList.reduce((a, b) => a + b)
    : 0;

  // Design Load Calculation
  const wA = appliances.find(
    appliance => appliance.appliance_name === "Envac Water Heater 5 Points"
  );
  const water_points = wA ? wA.custom_quantity : 0;
  const country_region = country_value.rivers;
  const solar_utilization = formData.solar_panels;
  // const solar_panel_grade = "Grade A";
  // "Grade C" for STARTER SOLAR GENERATOR and BASIC SOLAR GENERATOR

  // Total Power Requirement
  const total_power = power_list.length
    ? power_list.reduce((a, b) => a + b)
    : 0;

  // Total Start Up Power Requirement
  const total_startup_power = startup_power_list.length
    ? startup_power_list.reduce((a, b) => a + b)
    : 0;

  // Total Energy Use
  const total_energy = energy_list.length
    ? energy_list.reduce((a, b) => a + b)
    : 0;
  const sunlight_adjustment = (total_energy / country_region).toFixed(2);

  // Total Kilowatts
  const total_kilowatts = total_energy - highPowerEnergy;

  // Total Power (Thermal Storage)
  const total_thermal_power = highPowerPower;

  // Total Kilowatts (Thermal Storage)
  const total_thermal_kilowatts = highPowerEnergy;

  // Main Battery Requirement
  let main_battery_requirement;
  if (total_startup_power <= 3000) {
    const watt_division = Math.ceil(total_kilowatts / 1500);
    main_battery_requirement =
      watt_division % 2 === 0
        ? watt_division
        : watt_division + 2 - (watt_division % 2);
  } else {
    const watt_division = Math.ceil(total_kilowatts / 1500);
    main_battery_requirement =
      watt_division % 4 === 0
        ? watt_division
        : watt_division + 4 - (watt_division % 4);
  }

  // Thermal Battery Requirement
  let thermal_battery_requirement;
  if (total_thermal_power <= 4000) {
    thermal_battery_requirement = 0;
  } else if (total_thermal_power <= 8000) {
    thermal_battery_requirement = 4;
  } else {
    thermal_battery_requirement = 8;
  }

  // equipment specification
  function getInverterRating() {
    if (total_startup_power === 0) {
      return ["Add Appliances", 0];
    }
    if (total_startup_power <= 1000) {
      return ["1 KVA Inverter", 1];
    }
    if (total_startup_power <= 1500) {
      return ["1.5 KVA Inverter", 1];
    }
    if (total_startup_power <= 3000) {
      return ["3 KVA Inverter", 1];
    }
    if (total_startup_power <= 4500) {
      return ["4 KVA Inverter", 1];
    }
    if (total_startup_power <= 8000) {
      return ["5 KVA Inverter", 1];
    }
    if (total_startup_power <= 10000) {
      return ["8 KVA Inverter", 1];
    }
    if (total_startup_power <= 14000) {
      return ["10 KVA Inverter", 2];
    }
    if (total_startup_power <= 21000) {
      return ["15 KVA Inverter", 3];
    }
    if (total_startup_power <= 28000) {
      return ["20 KVA Inverter", 4];
    }
    if (total_startup_power <= 35000) {
      return ["25 KVA Inverter", 5];
    }
    if (total_startup_power <= 42000) {
      return ["30 KVA Inverter", 6];
    }
    if (total_startup_power <= 49000) {
      return ["35 KVA Inverter", 7];
    }
    if (total_startup_power <= 56000) {
      return ["40 KVA Inverter", 8];
    }
    if (total_startup_power <= 63000) {
      return ["45 KVA Inverter", 9];
    }
    if (total_startup_power <= 70000) {
      return ["50 KVA Inverter", 10];
    }
    if (total_startup_power <= 77000) {
      return ["60 KVA Inverter", 12];
    }
    return ["email prisca@icevolt.ca for Advise", 12];
  }

  function getBatteryRating() {
    return main_battery_requirement + thermal_battery_requirement;
  }

  function getThermalRating() {
    // const largeAppliance1 = appliances.find(
    //   appliance => appliance.appliance_name === "AC1(1hp)"
    // );
    // const lA1Value = largeAppliance1 ? largeAppliance1.custom_quantity : 0;

    // const largeAppliance2 = appliances.find(
    //   appliance => appliance.appliance_name === "AC2(1.5hp)"
    // );
    // const lA2Value = largeAppliance2 ? largeAppliance2.custom_quantity : 0;

    // const mul = lA1Value + lA2Value;

    if (total_thermal_power === 0) {
      return ["NIL", 0];
    }
    if (total_thermal_power <= 1200) {
      return ["IGLOO 250", 1];
    }
    if (total_thermal_power <= 2400) {
      return ["IGLOO 1000 Lite", 1];
    }
    if (total_thermal_power <= 3600) {
      return ["IGLOO 1000 Lite", 1];
    }
    if (total_thermal_power <= 6000) {
      return ["IGLOO 1000", 1];
    }
    if (total_thermal_power <= 9000) {
      return ["IGLOO 1500", 6];
    }
    if (total_thermal_power <= 12000) {
      return ["IGLOO 2000", 8];
    }
    if (total_thermal_power <= 16000) {
      return ["IGLOO 2500", 10];
    }
    return "Contact IceVolt";
  }

  function getWaterHeaterRating() {
    if (water_points === 0) {
      return ["NIL", 0];
    }
    if (water_points <= 6) {
      return ["300L", 1];
    }
    if (water_points <= 12) {
      return ["500L", 1];
    }
    if (water_points <= 18) {
      return ["1000L", 2];
    }
    if (water_points <= 30) {
      return ["1500L", 3];
    }
    if (water_points <= 45) {
      return ["2000L", 4];
    }
    return null;
  }

  function getNatureBreezeRating() {
    const natureBreezeAppliance = appliances.find(
      appliance => appliance.appliance_name === "Nature Breeze"
    );
    if (natureBreezeAppliance === undefined) {
      return 0;
    }

    return natureBreezeAppliance.custom_quantity === 0
      ? 0
      : natureBreezeAppliance.custom_quantity;
  }

  function getSolarPanelRating() {
    if (solar_utilization === "Off-Grid 100% Solar") {
      return Number(sunlight_adjustment).toFixed(2);
    }
    if (solar_utilization === "75% Solar") {
      return (sunlight_adjustment * 0.75).toFixed(2);
    }
    if (solar_utilization === "50% Solar") {
      return (sunlight_adjustment * 0.5).toFixed(2);
    }
    if (solar_utilization === "25% Solar") {
      return (sunlight_adjustment * 0.25).toFixed(2);
    }
    if (solar_utilization === "No Solar") {
      return sunlight_adjustment * 0;
    }
    return 0;
  }

  const equipment_specification = {
    "Inverter Package": {
      package: () => {
        if (total_startup_power <= 1000) {
          return ["1 KVA Inverter and Accessories/installation"];
        }

        if (total_startup_power <= 1500) {
          return ["1.5 KVA Inverter and Accessories"];
        }

        if (total_startup_power <= 3000) {
          return ["3 KVA Inverter and Accessories"];
        }

        if (total_startup_power <= 4500) {
          return ["4 KVA Inverter and Accessories/installation"];
        }

        if (total_startup_power <= 8000) {
          return ["5 KVA Inverter and Accessories"];
        }

        if (total_startup_power <= 10000) {
          return [
            "5 KVA Inverter and Accessories",
            "3 KVA Inverter and Accessories"
          ];
        }

        return ["5 KVA Inverter and Accessories"];
      },
      rating: getInverterRating()[0],
      quantity: getInverterRating()[1]
    },
    "Battery Package": {
      rating: getBatteryRating(),
      quantity: getBatteryRating()
    },
    "Solar Package": {
      rating: getSolarPanelRating(),
      quantity: Math.ceil((getSolarPanelRating() * 1000) / 365)
    },
    "Solar Assessories": {
      rating: formData.solar_panels === "No Solar" ? 0 : 1,
      quantity: formData.solar_panels === "No Solar" ? 0 : 1
    },
    "Battery Assessories": {
      rating: Math.ceil(getBatteryRating() / 8),
      quantity: Math.ceil(getBatteryRating() / 8)
    },
    "Stacking Card": {
      rating: getSolarPanelRating() > 5 ? 2 : 0,
      quantity: getSolarPanelRating() > 5 ? 2 : 0
    },
    "WATER HEATER": {
      package: () => {
        if (getWaterHeaterRating()[0] === "300L") {
          return "5 Pts Envac Heater Package (300L)";
        }
        if (getWaterHeaterRating()[0] === "500L") return "UNKNOWN1";
        if (getWaterHeaterRating()[0] === "1000L") return "UNKNOWN1";
        if (getWaterHeaterRating()[0] === "1500L") return "UNKNOWN1";
        if (getWaterHeaterRating()[0] === "2000L") return "UNKNOWN1";
        return "UNKNOWN1";
      },
      rating: getWaterHeaterRating()[0],
      quantity: getWaterHeaterRating()[1]
    },
    "Nature Breeze Package": {
      rating: getNatureBreezeRating(),
      quantity: getNatureBreezeRating()
    },
    "AC PACKAGE": {
      package: () => {
        if (getThermalRating()[0] === "IGLOO 250") {
          return "Envc AC Package (Igloo 250)";
        }
        if (getThermalRating()[0] === "IGLOO 500") return "UNKNOWN4";
        if (getThermalRating()[0] === "IGLOO 1000 Lite") {
          return "Envc AC Package (Igloo 1000 Lite)";
        }
        if (getThermalRating()[0] === "IGLOO 1000") return "UNKNOWN3";
        if (getThermalRating()[0] === "IGLOO 1500") {
          return "Envc AC Package (Igloo 250)";
        }
        if (getThermalRating()[0] === "IGLOO 2000") {
          return "Envc AC Package (Igloo 250)";
        }
        if (getThermalRating()[0] === "IGLOO 2500") {
          return "Envc AC Package (Igloo 250)";
        }
        return "Envc AC Package (Igloo 250)";
      },
      rating: getThermalRating()[0],
      quantity: getThermalRating()[1]
    },
    "Meter Package": {
      rating:
        formData.solar_panels === "No Solar" || total_startup_power < 1000
          ? 0
          : 1,
      quantity:
        formData.solar_panels === "No Solar" || total_startup_power < 1000
          ? 0
          : 1
    },
    "Battery Replacement Package": {
      rating: formData.battery_replacement_package,
      quantity: getBatteryRating()
    },
    "Quarterly Maintenance Package": {
      rating: formData.quarterly_maintenance_package,
      quantity: 1
    },
    "Extended Warranty Package": {
      rating: formData.extended_warranty_package,
      quantity: 1
    },
    "IceVolt Utility Box": {
      rating: "Included",
      quantity: 1
    },
    "IceVolt Operating Margin": {
      rating: "Included",
      quantity: 1
    }
  };

  // price
  const [
    inverterPackagePrice,
    batteryPackagePrice,
    solarPackagePrice,
    solarAssessoriesPrice,
    batteryAssessoriesPrice,
    stackingCardPrice,
    envacHeaterPackagePrice,
    natureBreezePackagePrice,
    envacACPackagPrice,
    meterPackagePrice
  ] = await getProductsRecommendationPrice(
    pricebook,
    equipment_specification,
    "CUSTOM"
  );

  const basicRecomProductPrices = await getProductsRecommendationPrice(
    pricebook,
    basicRecommendationProducts[0].products,
    "PREMIUM SOLAR"
  );

  const numerator =
    Number(inverterPackagePrice) +
    Number(batteryPackagePrice) +
    Number(solarPackagePrice) +
    Number(solarAssessoriesPrice) +
    Number(batteryAssessoriesPrice) +
    Number(stackingCardPrice) +
    Number(envacHeaterPackagePrice) +
    Number(natureBreezePackagePrice) +
    Number(envacACPackagPrice) +
    Number(meterPackagePrice);

  const denominator =
    basicRecomProductPrices[0] +
    basicRecomProductPrices[1] +
    basicRecomProductPrices[2] +
    basicRecomProductPrices[3] +
    basicRecomProductPrices[4] +
    basicRecomProductPrices[5] +
    basicRecomProductPrices[7] +
    basicRecomProductPrices[9];

  const denominator2 =
    Number(natureBreezePackagePrice) +
    Number(envacACPackagPrice) +
    Number(envacHeaterPackagePrice);

  const batteryReplacementPackagePrice =
    formData.battery_replacement_package === "Included"
      ? getBatteryRating() * Number(getNGN(pricebook[122].price))
      : 0;

  const quarterlyMaintenancePackagePrice =
    formData.quarterly_maintenance_package === "Included"
      ? Number(getNGN(pricebook[123].price)) * Number(numerator / denominator)
      : 0;

  const extendedWarrantyPackagePrice =
    formData.extended_warranty_package === "Included"
      ? Number(getNGN(pricebook[124].price)) +
        denominator2 * extendedWarrantyMargin
      : 0;

  const icevoltUtilityBoxPrice =
    formData.solar_panels === "No Solar"
      ? Number(getNGN(pricebook[126].price))
      : Number(getNGN(pricebook[125].price));

  const productsRecommendationPrice =
    Number(inverterPackagePrice) +
    Number(batteryPackagePrice) +
    Number(solarPackagePrice) +
    Number(solarAssessoriesPrice) +
    Number(batteryAssessoriesPrice) +
    Number(stackingCardPrice) +
    Number(envacHeaterPackagePrice) +
    Number(natureBreezePackagePrice) +
    Number(envacACPackagPrice) +
    Number(meterPackagePrice) +
    Number(batteryReplacementPackagePrice) +
    Number(quarterlyMaintenancePackagePrice) +
    Number(extendedWarrantyPackagePrice) +
    Number(icevoltUtilityBoxPrice);

  const fullRecommendationPrice =
    productsRecommendationPrice + productsRecommendationPrice * operatingMargin;

  const productsRecommendation = {
    plan: "CUSTOM",
    description: "custom selection recommendation",
    make: `${formData.solar_panels} Custom package`,
    products: equipment_specification,
    price: fullRecommendationPrice,
    totalStartUpPower: total_startup_power,
    thermalBatteryRequirement: thermal_battery_requirement,
    totalEnergy: total_energy,
    totalPower: total_power,
    sunlightAdjustment: sunlight_adjustment,
    totalKilowatts: total_kilowatts,
    totalThermalPower: total_thermal_power,
    totalThermalKilowatts: total_thermal_kilowatts,
    mainBatteryRequirement: main_battery_requirement
  };

  return productsRecommendation;
};

export default getProductRecommendation;
