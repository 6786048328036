/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../Components/Header/Header";
import RefineSelection from "../../Components/RefineSelection/RefineSelection";
import CustomFeedbackComp from "../../Components/customFeedbackComp/customFeedbackComp";
import "./StandardPreview.css";

function StandardPreview({
  loggedIn,
  selectedPlan,
  setSelectedPlan,
  recommendedSystem,
  setRecommendedSystem,
  selectedFinance,
  setSelectedFinance,
  pricebook,
  setPricebook,
  appliances,
  setAppliances,
  customFeedback,
  setCustomFeedback,
  basicRecommended,
  setBasicRecommended,
  page,
  notify,
  setLoading
}) {
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [showRefine, setShowRefine] = useState(false);

  const history = useHistory();

  const reservationFeeValue = {
    0.05: "Low",
    0.1: "Medium",
    0.15: "High"
  };

  const onSelectFinancing = () => {
    history.push("/select-financing");
  };

  const onPlaceOrder = () => {
    history.push("/register");
  };

  const onSaveChanges = () => {
    setLoading(true);
    const userId = sessionStorage.getItem("userId");

    fetch(`${process.env.REACT_APP_API_URL}/user/updateSelection`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        plan: selectedPlan,
        customFeedback,
        recommendedSystem,
        appliances,
        selectedFinance,
        userId
      })
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          notify("success", data.message);
          setTimeout(() => {
            history.push({ pathname: "/landing-dashboard", type: "update" });
          }, 1000);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "Error Updating Selection");
      });
  };

  const onRefineSolution = () => {
    setShowRefine(true);
  };

  const onCloseRefine = path => {
    setShowRefine(false);
    if (path) {
      setSelectedPlan("CUSTOM");
      history.push("/custom-preview2");
    }
  };

  const onEditPackage = () => {
    setShowCustomFeedback(true);
  };

  const onCloseCustomFeedback = () => {
    setShowCustomFeedback(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="StandardPreview">
      {showCustomFeedback && (
        <CustomFeedbackComp
          onClose={onCloseCustomFeedback}
          userCustomFeedback={customFeedback}
          setUserCustomFeedback={setCustomFeedback}
          setRecommendedSystem={setRecommendedSystem}
          setAppliances={setAppliances}
          setPricebook={setPricebook}
          selectedPlan={selectedPlan}
          setBasicRecommended={setBasicRecommended}
          selectedFinance={selectedFinance}
          setSelectedFinance={setSelectedFinance}
          setLoading={setLoading}
        />
      )}
      {showRefine && (
        <RefineSelection
          onClose={onCloseRefine}
          userCustomFeedback={customFeedback}
          setUserCustomFeedback={setCustomFeedback}
          setRecommendedSystem={setRecommendedSystem}
          appliances={appliances}
          setAppliances={setAppliances}
          pricebook={pricebook}
          basicRecommended={basicRecommended}
          recommendedSystem={recommendedSystem}
          selectedFinance={selectedFinance}
          setSelectedFinance={setSelectedFinance}
          selectedPlan={selectedPlan}
        />
      )}
      <Header />
      <h1 className="preview-header-label">Review My Solution</h1>
      <div className="preview-selection">
        <div className="preview-header">
          <p className="preview-header-text">My Selection</p>
        </div>
        <div className="preview-body">
          <p className="preview-plan-text">{selectedPlan}</p>
        </div>
      </div>
      <div className="preview-description">
        <div className="preview-header">
          <p className="preview-header-text">Description</p>
        </div>
        <div className="preview-body">
          <p className="preview-body-text">{recommendedSystem.description}</p>
          <p className="recommended-details-tile-body-text">
            {recommendedSystem.sub_description}
          </p>
        </div>
      </div>
      <div className="preview-recommended">
        <div className="preview-header">
          <p className="preview-header-text">Recommended System</p>
        </div>
        <div className="preview-body">
          <p className="preview-body-text">{recommendedSystem.make}</p>
        </div>
      </div>
      <div className="preview-recommended-details">
        <div className="preview-header">
          <p className="preview-header-text">Recommended System Details</p>
        </div>
        <div className="preview-body recommended-details-body">
          {Object.keys(recommendedSystem.products).map(
            productKey =>
              productKey !== "IceVolt Operating Margin" && (
                <div className="recommended-details-tile" key={productKey}>
                  <div className="recommended-details-tile-header">
                    <p className="recommended-details-tile-header-text">
                      {productKey}
                    </p>
                    <p className="recommended-details-tile-header-text">{`Qty: ${recommendedSystem.products[productKey].quantity}`}</p>
                  </div>
                  <div className="recommended-details-tile-body">
                    {recommendedSystem.products[productKey].components.map(
                      (component, i) => (
                        <p
                          className="recommended-details-tile-body-text"
                          key={`${component}${i}`}
                        >
                          {component}
                        </p>
                      )
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {selectedPlan !== "STARTER SOLAR GENERATOR" &&
        selectedPlan !== "BASIC SOLAR GENERATOR" && (
          <div>
            <div className="preview-body custom-preview-selection-details">
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Battery Replacement Package
                </p>
                <p className="selection-details-element-value">
                  {customFeedback.battery_replacement_package}
                </p>
              </div>
              <div className="selection-details-element">
                <p className="selection-details-element-label">
                  Quaterly Maintenance Package
                </p>
                <p className="selection-details-element-value">
                  {customFeedback.quarterly_maintenance_package}
                </p>
              </div>
              {(selectedPlan === "PREMIUM SOLAR" ||
                selectedPlan === "SILVER SOLAR" ||
                selectedPlan === "GOLD SOLAR" ||
                selectedPlan === "PLATINUM SOLAR" ||
                selectedPlan === "PREMIUM BACKUP") && (
                <div className="selection-details-element">
                  <p className="selection-details-element-label">
                    <a
                      href="http://icevolt.ca/warranty/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Extended Warranty Package
                    </a>
                  </p>
                  <p className="selection-details-element-value">
                    {customFeedback.extended_warranty_package}
                  </p>
                </div>
              )}
              <div className="selection-details-element" />
              <div className="form-submit-btn">
                <button type="button" onClick={() => onEditPackage()}>
                  Edit
                </button>
              </div>
            </div>
          </div>
          // eslint-disable-next-line indent
        )}
      {page === 2 && (
        <div className="preview-financing">
          <div className="preview-header">
            <p className="preview-header-text">My Selected Financing</p>
          </div>
          <div className="preview-body custom-preview-selection-details">
            <div className="selection-details-element">
              <p className="selection-details-element-label">Reservation Fee</p>
              <p className="selection-details-element-value">
                {reservationFeeValue[Number(selectedFinance.downPayment)]}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Power Contract Duration in years
              </p>
              <p className="selection-details-element-value">
                {selectedFinance.loanPeriod}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Monthly Flat Rate Utility Bill
              </p>
              <p className="selection-details-element-value">
                {`₦${selectedFinance.monthlyPayment.toLocaleString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Amount of Reservation Fee
              </p>
              <p className="selection-details-element-value">
                {`₦${selectedFinance.amountOfDownPayment.toLocaleString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Number of Payments
              </p>
              <p className="selection-details-element-value">
                {selectedFinance.numberOfPayments}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                Start Date of Power Contract
              </p>
              <p className="selection-details-element-value">
                {`${new Date(selectedFinance.startDateOfLoan).toDateString()}`}
              </p>
            </div>
            <div className="selection-details-element">
              <p className="selection-details-element-label">
                End Date of Power Contract
              </p>
              <p className="selection-details-element-value">
                {new Date(
                  selectedFinance.selectedFinanceTable[
                    selectedFinance.selectedFinanceTable.length - 1
                  ].paymentDate
                ).toDateString()}
              </p>
            </div>
            <div className="form-submit-btn">
              <button type="button" onClick={() => onSelectFinancing()}>
                Edit Subscription
              </button>
            </div>
          </div>
        </div>
      )}
      {page === 1 ? (
        <div
          className="form-submit-btn"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loggedIn ? (
            <button
              type="button"
              onClick={() => history.push("/landing-dashboard")}
              style={{ marginRight: "50px" }}
            >
              cancel
            </button>
          ) : (
            <button
              type="button"
              onClick={() => history.push("/standard-solution")}
            >
              Change Selection
            </button>
          )}
          <button type="button" onClick={() => onSelectFinancing()}>
            Select Subscription
          </button>
        </div>
      ) : (
        <div
          className="form-submit-btn"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loggedIn ? (
            <>
              <button
                type="button"
                onClick={() => history.push("/landing-dashboard")}
                style={{ marginRight: "50px" }}
              >
                cancel
              </button>
              <button type="button" onClick={() => onSaveChanges()}>
                Save Changes
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => history.push("/standard-solution")}
              >
                Change Selection
              </button>
              {selectedPlan !== "STARTER SOLAR GENERATOR" &&
                selectedPlan !== "BASIC SOLAR GENERATOR" && (
                  <button
                    type="button"
                    onClick={() => onRefineSolution()}
                    className="preview-refine-btn"
                  >
                    Refine Selection
                  </button>
                  // eslint-disable-next-line indent
                )}
              <button
                type="button"
                onClick={() => onPlaceOrder()}
                className="preview-order-btn"
              >
                Place Order
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default StandardPreview;
