import React, { useState } from "react";
import "./CustomerDashboard.css";
import logo from "../../images/logo.png";
import vector1 from "../../images/Vector.png";
import vector2 from "../../images/Group.png";
import vector3 from "../../images/Vector3.png";
import vector4 from "../../images/Group2.png";
import vector5 from "../../images/Group3.png";
import vector6 from "../../images/Vector5.png";
import vector7 from "../../images/Vector6.png";
import vector8 from "../../images/Group4.png";
import vector9 from "../../images/Vector7.png";
import vector10 from "../../images/active.png";
import vector12 from "../../images/Vector10.png";
import vector13 from "../../images/dropdown.png";
import vector15 from "../../images/Ellipse2.png";
import CustomerDashboardComp from "../../Components/CustomerDashboardComp/CustomerDashboardComp";
import CustomerInformation from "../../Components/CustomerInformation/CustomerInformation";
import UserWallet from "../../Components/UserWallet/UserWallet";
import Billing from "../../Components/Billing/Billing";
import CustomerSettings from "../../Components/CustomerSettings/CustomerSettings";
import Support from "../../Components/Support/Support";
import MobileNav from "./MobileNav";

import {
  dash,
  customer,
  wallet,
  install,
  bill,
  maintain,
  equip,
  support,
  settings
} from "./states";

const items = [
  { id: 1, text: "Dashboard", pict: vector1 },
  { id: 2, text: "Customer Information", pict: vector2 },
  { id: 3, text: "Wallet", pict: vector5 },
  { id: 4, text: "Installation Details", pict: vector3 },
  { id: 5, text: "Billing and Payment History", pict: vector4 },
  { id: 6, text: "Maintenance Records", pict: vector6 },
  { id: 7, text: "Equipment Health Check", pict: vector7 },
  { id: 8, text: "Support", pict: vector8 },
  { id: 9, text: "Settings", pict: vector9 },
  { id: 10, text: "Logout", pict: vector12 }
];

const CustomerDashboard = ({
  userDetails,
  logOut,
  notify,
  refreshPage,
  setLoading
}) => {
  const [state, setState] = useState(dash);
  const [sideBar, setSideBar] = useState(false);
  const handleClick = id => {
    if (id === 1) {
      setState(dash);
    } else if (id === 2) {
      setState(customer);
    } else if (id === 3) {
      setState(wallet);
    } else if (id === 4) {
      setState(install);
    } else if (id === 5) {
      setState(bill);
    } else if (id === 6) {
      setState(maintain);
    } else if (id === 7) {
      setState(equip);
    } else if (id === 8) {
      setState(support);
    } else if (id === 9) {
      setState(settings);
    } else if (id === 10) {
      logOut();
    }
    setSideBar(true);
  };
  return (
    <div className="customer-dashboard-page">
      <MobileNav sideBar={sideBar} setSideBar={setSideBar} />
      {/* <div className="customer-dashboard-sidebar translate untranslate"> */}
      <div
        className={`customer-dashboard-sidebar ${
          sideBar ? "translate" : "untranslate"
        }`}
      >
        <img src={logo} alt="" className="customer-dashboard-sidebar-logo" />
        <div className="customer-dashboard-sidebar-items">
          {items
            .filter(item => item.id < 9)
            .map(item => (
              <li
                key={item.id}
                className={`${state[item.id - 1] ? "active" : ""}`}
                onClick={() => handleClick(item.id)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
        <div
          style={{
            borderTop: "2px solid #fff",
            width: "251px",
            margin: "0 auto"
          }}
        />
        <div className="customer-dashboard-settings-logout">
          {items
            .filter(item => item.id > 8)
            .map(item => (
              <li
                key={item.id}
                className={`${state[item.id - 1] ? "active" : ""}`}
                onClick={() => handleClick(item.id)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
      </div>
      <div className="customer-dashboard-mainpage">
        <div className="profile">
          <div
            style={{
              marginTop: "10px",
              marginBottom: "13px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <span className="bell">
              <img src={vector10} alt="" />
              <img src={vector15} alt="" className="active" />
            </span>
            <div
              className="image"
              style={{
                backgroundImage: `url(${userDetails.userDetails.img_url})`,
                backgroundSize: "cover"
              }}
            />
            <span className="name">{userDetails.userDetails.full_name}</span>
            <span className="dropdown">
              <img src={vector13} alt="" />
            </span>
          </div>
        </div>
        <div className="account-number-address">
          <span className="account-number">
            <span style={{ color: "#616061" }}>
              {` ${userDetails.userDetails.user_account_id.split("-")[0]}`}
            </span>
          </span>
          <span className="account-number">
            Account ID:
            <span style={{ color: "#616061" }}>
              {` ${userDetails.userDetails.user_account_number}`}
            </span>
          </span>
          <span className="address">
            Installation Address:
            <span style={{ color: "#616061" }}>
              {` ${userDetails.userDetails.installation_address}`}
            </span>
          </span>
        </div>
        <div className="component-area">
          {state[0] ? (
            <CustomerDashboardComp userDetails={userDetails} />
          ) : null}
          {state[1] ? <CustomerInformation userDetails={userDetails} /> : null}
          {state[2] ? (
            <UserWallet
              userDetails={userDetails}
              notify={notify}
              refreshPage={refreshPage}
              setLoading={setLoading}
            />
          ) : null}
          {state[3] ? <h1>Installation Details</h1> : null}
          {state[4] ? <Billing userDetails={userDetails} /> : null}
          {state[5] ? <h1>Maintenance Records</h1> : null}
          {state[6] ? <h1>Equipment Health Check</h1> : null}
          {state[7] ? <Support /> : null}
          {state[8] ? (
            <CustomerSettings
              userDetails={userDetails}
              notify={notify}
              setLoading={setLoading}
            />
          ) : null}
          {state[9] ? <h1>Logout</h1> : null}
        </div>
      </div>
    </div>
  );
};
export default CustomerDashboard;
