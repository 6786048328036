import React, { useEffect, useState } from "react";
import vector1 from "../../images/Ellipse36.png";
import UserDetails from "../UserDetails/UserDetails";
import {
  leads,
  prospects,
  audits,
  paying
} from "../../Pages/AdminDashboard/states";
import AssignUser from "../UserNote/AssignUser";
import noteIcon from "../../images/note.png";
import UserNote from "../UserNote/UserNote";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";

const SalesRepDetails = ({
  setPage,
  selectedAgent,
  notify,
  setLoading,
  refreshPage
}) => {
  const [state, setState] = useState(leads);
  const [clients, setClients] = useState([]);
  const [detailsPage, setDetailsPage] = useState("ANALYTICS");
  const [selectedClient, setSelectedClient] = useState({});
  const [assignUser, setAssignUser] = useState(false);
  const [removeClient, setRemoveClient] = useState(false);
  const [userNote, setUserNote] = useState("");

  const statusIndicator = {
    NONE: "#CCCCCC",
    LOW: "#00FF00",
    MEDIUM: "#DAF034",
    FAIR: "#FFCA42",
    HIGH: "#FF0000",
    UNKNOWN: "#0000CC"
  };

  const handleLeads = () => {
    setState(leads);
  };
  const handleProspects = () => {
    setState(prospects);
  };
  const handleAudits = () => {
    setState(audits);
  };
  const handlePaying = () => {
    setState(paying);
  };

  const onSelectClient = user => {
    setSelectedClient(user);
    setDetailsPage("USERDETAILS");
  };

  const onAssignClient = email => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/salesRep/assignClient`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({ salesRepId: selectedAgent.id, email })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  };

  const onRemoveClient = userId => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/salesRep/unAssignClient`, {
      method: "delete",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({ salesRepId: selectedAgent.id, userId })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  };

  const onClickNote = user => {
    setSelectedClient(user);
    setUserNote(user.user_note || "Enter a note about this user");
  };

  const onSaveUserNote = (userId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/addUserNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/salesRep/clients/${selectedAgent.id}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setClients(data.responseData);
          setLoading(false);
        } else {
          notify("error", data.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("warn", "network errors");
        setLoading(false);
      });
  }, []);

  return detailsPage === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedClient}
      notify={notify}
      setPage={setDetailsPage}
      setAgentPage={setPage}
      basePage="Sales Rep"
    />
  ) : (
    <>
      {assignUser && (
        <AssignUser
          onAssignClient={onAssignClient}
          setAssignUser={setAssignUser}
        />
      )}
      {removeClient && (
        <DeleteConfirmation
          setDeleteCustomer={setRemoveClient}
          onDeleteUser={onRemoveClient}
          userId={selectedClient.user_id}
          content="Are you sure you want to remove this client from this sales Rep?"
        />
      )}
      {userNote && (
        <UserNote
          setUserNote={setUserNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedClient.id}
          defaultNote={userNote}
        />
      )}
      <div className="userDetails-page-header">
        <p>
          <span
            onClick={() => {
              setPage("AGENTS");
            }}
          >
            Sales Rep
          </span>
          &nbsp;&gt; Sales Rep Details
        </p>
      </div>
      <div className="customer-info">
        <div className="landing-dashboard-user-personal">
          <div
            className="landing-dashboard-user-img"
            style={{
              backgroundImage: `url(${selectedAgent.passport})`,
              backgroundSize: "cover"
            }}
          />
          <div className="landing-dashboard-user-info">
            <h3 className="landing-dashboard-user-name">
              {`${selectedAgent.firstname} ${selectedAgent.lastname}`}
            </h3>
            <p className="landing-dashboard-user-email">
              {selectedAgent.email}
            </p>
          </div>
        </div>
        <h4 className="customer-info-setion-header">Sales Rep Info</h4>
        <div className="agent-info-wrapper">
          <p className="agent-info-item">
            <span className="agent-info-item-label">Registration Date: </span>
            {selectedAgent.date}
          </p>
        </div>
        <h4 className="customer-info-setion-header">Clients</h4>
        <div className="analytics-visitors">
          <div className="single">
            <img src={vector1} alt="" />
            <div style={{ marginLeft: "20px" }}>
              <span className="title">
                {clients.length}
                &nbsp;Total Clients
              </span>
            </div>
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "500",
              fontSize: "14px",
              background: "#4C9DE8",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              height: "40px",
              width: "120px",
              cursor: "pointer"
            }}
            onClick={() => {
              setAssignUser(true);
            }}
          >
            Assign Client
          </div>
        </div>
        <div className="leads-prospects" style={{ padding: "10px" }}>
          <span
            onClick={handleLeads}
            className={`${state[0] ? "active" : "disabled"}`}
          >
            Leads
          </span>
          <span
            onClick={handleProspects}
            className={`${state[1] ? "active" : "disabled"}`}
          >
            Prospects
          </span>
          <span
            onClick={handleAudits}
            className={`${state[2] ? "active" : "disabled"}`}
          >
            Prospects for Site Audit
          </span>
          <span
            onClick={handlePaying}
            className={`${state[3] ? "active" : "disabled"}`}
          >
            Paying Customers
          </span>
        </div>
        <div className="agent-info-wrapper">
          <div className="analytics">
            {state[0] && (
              <div className="analytics-table-card">
                <div className="analytics-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>status</th>
                        <th>Delete</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients.map(
                        client =>
                          client.type === "Lead" && (
                            <tr key={client.user_id}>
                              <td
                                style={{ cursor: "pointer", color: "gray" }}
                                onClick={() => onSelectClient(client)}
                              >
                                {`${client.firstname} ${client.lastname}`}
                              </td>
                              <td>{client.email}</td>
                              <td>{client.phone_number}</td>
                              <td>
                                {new Date(client.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    background: `${
                                      statusIndicator[client.credit_status]
                                    }`,
                                    width: "50px",
                                    height: "30px",
                                    borderRadius: "5px"
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    background: "#D20000",
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setSelectedClient(client);
                                    setRemoveClient(true);
                                  }}
                                >
                                  Remove Client
                                </span>
                              </td>
                              <td>
                                <img
                                  onClick={() => onClickNote(client)}
                                  className="noteIcon"
                                  src={noteIcon}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {state[1] && (
              <div className="analytics-table-card">
                <div className="analytics-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>status</th>
                        <th>Delete</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients.map(
                        client =>
                          client.type === "Prospect" && (
                            <tr key={client.user_id}>
                              <td
                                style={{ cursor: "pointer", color: "gray" }}
                                onClick={() => onSelectClient(client)}
                              >
                                {`${client.firstname} ${client.lastname}`}
                              </td>
                              <td>{client.email}</td>
                              <td>{client.phone_number}</td>
                              <td>
                                {new Date(client.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    background: `${
                                      statusIndicator[client.credit_status]
                                    }`,
                                    width: "50px",
                                    height: "30px",
                                    borderRadius: "5px"
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    background: "#D20000",
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setSelectedClient(client);
                                    setRemoveClient(true);
                                  }}
                                >
                                  Remove Client
                                </span>
                              </td>
                              <td>
                                <img
                                  onClick={() => onClickNote(client)}
                                  className="noteIcon"
                                  src={noteIcon}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {state[2] && (
              <div className="analytics-table-card">
                <div className="analytics-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>status</th>
                        <th>Delete</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients.map(
                        client =>
                          client.type === "Audit" && (
                            <tr key={client.user_id}>
                              <td
                                style={{ cursor: "pointer", color: "gray" }}
                                onClick={() => onSelectClient(client)}
                              >
                                {`${client.firstname} ${client.lastname}`}
                              </td>
                              <td>{client.email}</td>
                              <td>{client.phone_number}</td>
                              <td>
                                {new Date(client.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    background: `${
                                      statusIndicator[client.credit_status]
                                    }`,
                                    width: "50px",
                                    height: "30px",
                                    borderRadius: "5px"
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    background: "#D20000",
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setSelectedClient(client);
                                    setRemoveClient(true);
                                  }}
                                >
                                  Remove Client
                                </span>
                              </td>
                              <td>
                                <img
                                  onClick={() => onClickNote(client)}
                                  className="noteIcon"
                                  src={noteIcon}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {state[3] && (
              <div className="analytics-table-card">
                <div className="analytics-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>status</th>
                        <th>Delete</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients.map(
                        client =>
                          client.type === "Paying" && (
                            <tr key={client.user_id}>
                              <td
                                style={{ cursor: "pointer", color: "gray" }}
                                onClick={() => onSelectClient(client)}
                              >
                                {`${client.firstname} ${client.lastname}`}
                              </td>
                              <td>{client.email}</td>
                              <td>{client.phone_number}</td>
                              <td>
                                {new Date(client.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    background: `${
                                      statusIndicator[client.credit_status]
                                    }`,
                                    width: "50px",
                                    height: "30px",
                                    borderRadius: "5px"
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    background: `${
                                      statusIndicator[client.credit_status]
                                    }`,
                                    width: "50px",
                                    height: "30px",
                                    borderRadius: "5px"
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    background: "#D20000",
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setSelectedClient(client);
                                    setRemoveClient(true);
                                  }}
                                >
                                  Remove Client
                                </span>
                              </td>
                              <td>
                                <img
                                  onClick={() => onClickNote(client)}
                                  className="noteIcon"
                                  src={noteIcon}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesRepDetails;
