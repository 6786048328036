import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./AdminDashboard.css";
import logo from "../../images/logo.png";
import vector1 from "../../images/Vector.png";
import vector2 from "../../images/Group.png";
import vector4 from "../../images/priceBook.png";
import vector5 from "../../images/plan.png";
import vector6 from "../../images/report.png";
import vector9 from "../../images/Vector7.png";
import vector12 from "../../images/Vector10.png";
import agentsIcon from "../../images/agents.png";
import trash from "../../images/trash.png";
import Agents from "../../Components/Agents/Agents";
import SalesRep from "../../Components/SalesRep/SalesRep";
import Shell from "../../Components/Shell/Shell";
import Bin from "../../Components/Bin/Bin";
import Pricebook from "../../Components/Pricebook/Pricebook";
import ComponentPricing from "../../Components/ComponentPricing/ComponentPricing";
import AdminSettings from "../../Components/AdminSettings/AdminSettings";
import MobileNav from "./MobileNav";
import Analytics from "./Analytics";

const items = [
  { id: 1, text: "Dashboard", url: "dashboard", pict: vector1 },
  { id: 2, text: "Agents", url: "agents", pict: agentsIcon },
  { id: 3, text: "Sales Representative", url: "salesrep", pict: agentsIcon },
  { id: 4, text: "Shell", url: "shell", pict: agentsIcon },
  { id: 5, text: "Deactivated Customers", url: "bin", pict: trash },
  { id: 6, text: "Component Pricing", url: "componentpricing", pict: vector2 },
  { id: 7, text: "Price Book", url: "pricebook", pict: vector4 },
  { id: 8, text: "Plans", url: "plans", pict: vector5 },
  { id: 9, text: "Report", url: "report", pict: vector6 },
  { id: 10, text: "Settings", url: "settings", pict: vector9 },
  { id: 11, text: "Logout", url: "logout", pict: vector12 }
];

const AdminDashboard = ({
  setLoading,
  notify,
  refreshPage,
  logOut,
  adminDetails
}) => {
  const [sideBar, setSideBar] = useState(true);

  const history = useHistory();
  const { adminPage } = useParams();
  const adminPageObj = items.find(item => item.url === adminPage);

  const handleClick = url => {
    history.push(`/admin/${url}`);
    if (url === "logout") {
      logOut();
    }
    setSideBar(true);
  };

  const displayComponent = () => {
    switch (adminPage) {
      case "dashboard":
        return (
          <Analytics
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "agents":
        return (
          <Agents
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "salesrep":
        return (
          <SalesRep
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "shell":
        return (
          <Shell
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "bin":
        return (
          <Bin
            setLoading={setLoading}
            notify={notify}
            refreshPage={refreshPage}
          />
        );
      case "componentpricing":
        return <ComponentPricing setLoading={setLoading} notify={notify} />;
      case "pricebook":
        return <Pricebook setLoading={setLoading} notify={notify} />;
      case "plans":
        return <h1>Plans</h1>;
      case "report":
        return <h1>Report</h1>;
      case "settings":
        return (
          <AdminSettings
            adminDetails={adminDetails}
            notify={notify}
            setLoading={setLoading}
            refreshPage={refreshPage}
          />
        );
      case "logout":
        return <h1>Logout</h1>;
      default:
        return history.push("/admin/dashboard");
    }
  };

  return (
    <div className="admin-dashboard-page">
      <MobileNav sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`admin-dashboard-sidebar ${
          sideBar ? "translate" : "untranslate"
        }`}
      >
        <img src={logo} alt="" className="admin-dashboard-sidebar-logo" />
        <div className="admin-dashboard-sidebar-items">
          {items
            .filter(item => item.id < 10)
            .map(item => (
              <li
                key={item.id}
                className={`${adminPage === item.url ? "active" : ""}`}
                onClick={() => handleClick(item.url)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
        <div
          style={{
            borderTop: "2px solid #fff",
            width: "251px",
            margin: "0 auto"
          }}
        />
        <div className="admin-dashboard-settings-logout">
          {items
            .filter(item => item.id > 9)
            .map(item => (
              <li
                key={item.id}
                className={`${adminPage === item.url ? "active" : ""}`}
                onClick={() => handleClick(item.url)}
              >
                <span>
                  <img src={item.pict} alt="" />
                </span>
                {item.text}
              </li>
            ))}
        </div>
      </div>
      <div className="admin-dashboard-mainpage">
        <div className="header-area">
          <h4>{adminPageObj.text}</h4>
        </div>
        <div className="component-area">{displayComponent()}</div>
      </div>
    </div>
  );
};
export default AdminDashboard;
