import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Pages/DesignMySolution/DesignMySolution.css";
import RootLoading from "./Components/RootLoading/RootLoading";
import Loading from "./Components/Loading/Loading";
import FirstPage from "./Pages/FirstPage/FirstPage";
import StandardSolution from "./Pages/StandardSolution/StandardSolution";
import SelectFinancing from "./Pages/SelectFinancing/SelectFinancing";
import StandardPreview from "./Pages/StandardPreview/StandardPreview";
import CustomPreview from "./Pages/CustomPreview/CustomPreview";
import Register from "./Pages/Register/Register";
import AccountVerification from "./Pages/AccountVerification/AccountVerification";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import LandingDashboard from "./Pages/LandingDashboard/LandingDashboard";
import CustomerDashboard from "./Pages/CustomerDashboard/CustomerDashboard";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AgentSignUp from "./Pages/AgentSignUp/AgentSignUp";
import AgentDashboard from "./Pages/AgentDashboard/AgentDashboard";
import SalesRepDashboard from "./Pages/SalesRepDashboard/SalesRepDashboard";

const ProtectedRoute = ({ path, exact, auth, Comp, redirectUrl, ...props }) => {
  if (auth) {
    return (
      <Route
        path={path}
        exact={exact}
        render={otherProps => <Comp {...otherProps} {...props} />}
      />
    );
  }
  return <Redirect to={redirectUrl || "/"} />;
};

function App() {
  const [rootLoading, setRootLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [customFeedback, setCustomFeedback] = useState("");
  const [appliances, setAppliances] = useState();
  const [recommendedSystem, setRecommendedSystem] = useState();
  const [basicRecommended, setBasicRecommended] = useState();
  const [selectedFinance, setSelectedFinance] = useState({});
  const [pricebook, setPricebook] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [agentLoggedIn, setAgentLoggedIn] = useState(false);
  const [salesRepLoggedIn, setSalesRepAgentLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [adminObj, setAdminObj] = useState({});

  const logUserIn = user => {
    if (user.remember_me) {
      localStorage.setItem("userId", user.userDetails.user_id);
    }
    // trigger deploy
    sessionStorage.setItem("userId", user.userDetails.user_id);
    setUserObj(user);
    setSelectedPlan(user.userDetails.plan);
    setCustomFeedback(user.userCustomFeedback || {});
    setRecommendedSystem(user.userRecommendedSystem);
    if (user.userFinance) {
      setSelectedFinance({
        plan: user.userFinance.plan,
        downPayment: user.userFinance.down_payment,
        loanPeriod: user.userFinance.loan_period,
        startDateOfLoan: user.userFinance.start_date_of_loan,
        monthlyPayment: user.userFinance.monthly_payment,
        amountOfDownPayment: user.userFinance.amount_of_down_payment,
        numberOfPayments: user.userFinance.number_of_payments,
        loanAmount: user.userFinance.loan_amount,
        annualInterestRate: user.userFinance.annual_interest_rate,
        totalInterest: user.userFinance.total_interest,
        totalCostOfLoan: user.userFinance.total_cost_of_loan,
        selectedFinanceTable: user.userFinance.finance_table,
        selectedGraphTable: user.userFinance.graph_table
      });
    }
    if (user.userRecommendedSystem) {
      setAppliances(user.userRecommendedSystem.appliances);
    }
    setPricebook(user.pricebook);
    setBasicRecommended(user.basicRecommendationProducts);
    setLoggedIn(true);
  };

  const logOut = () => {
    localStorage.removeItem("userId");
    sessionStorage.removeItem("userId");
    setLoggedIn(false);
    setUserObj("");
  };

  const logAdminIn = admin => {
    sessionStorage.setItem("adminId", admin.admin_id);
    setAdminObj(admin);
    setAdminLoggedIn(true);
  };

  const logAdminOut = () => {
    sessionStorage.removeItem("adminId");
    setAdminLoggedIn(false);
    setAdminObj({});
  };

  const logAgentIn = agentId => {
    sessionStorage.setItem("agentId", agentId);
    setAgentLoggedIn(true);
  };

  const logAgentOut = () => {
    sessionStorage.removeItem("agentId");
    setAgentLoggedIn(false);
  };

  const logSalesRepIn = salesRepId => {
    sessionStorage.setItem("salesRepId", salesRepId);
    setSalesRepAgentLoggedIn(true);
  };

  const logSalesRepOut = () => {
    sessionStorage.removeItem("salesRepId");
    setSalesRepAgentLoggedIn(false);
  };

  const refreshPage = () => {
    window.location.reload(true);
  };

  const notify = (type, message) => {
    if (type === "info") {
      toast.info(message);
    }
    if (type === "success") {
      toast.success(message);
    }
    if (type === "warn") {
      toast.warn(message);
    }
    if (type === "error") {
      toast.error(message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const userId = localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : sessionStorage.getItem("userId");
    const adminId = sessionStorage.getItem("adminId");
    const agentId = sessionStorage.getItem("agentId");
    const salesRepId = sessionStorage.getItem("salesRepId");
    if (userId) {
      fetch(`${process.env.REACT_APP_API_URL}/user/userDetails/${userId}`)
        .then(res => res.json())
        .then(data => {
          logUserIn(data.responseData);
          sessionStorage.setItem("noIntroPage", true);
          setRootLoading(false);
        })
        .catch(() => {
          setRootLoading(false);
          notify("warn", "something went wrong with the connection");
        });
    } else if (adminId) {
      fetch(`${process.env.REACT_APP_API_URL}/admin/profile/${adminId}`)
        .then(res => res.json())
        .then(data => {
          logAdminIn(data.responseData);
          setRootLoading(false);
        })
        .catch(() => {
          setRootLoading(false);
          notify("warn", "something went wrong with the connection");
        });
    } else if (agentId) {
      setRootLoading(false);
      logAgentIn(agentId);
    } else if (salesRepId) {
      setRootLoading(false);
      logSalesRepIn(salesRepId);
    }

    if (!userId && !adminId && !agentId && !salesRepId) {
      setRootLoading(false);
    }

    // eslint-disable-next-line
  }, []);
  return rootLoading ? (
    <RootLoading />
  ) : (
    <>
      {loading && <Loading />}
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <FirstPage
                loggedIn={loggedIn}
                setLoading={setLoading}
                pricebook={pricebook}
                setPricebook={setPricebook}
                appliances={appliances}
                setAppliances={setAppliances}
                basicRecommended={basicRecommended}
                setBasicRecommended={setBasicRecommended}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                customFeedback={customFeedback}
                setCustomFeedback={setCustomFeedback}
                setRecommendedSystem={setRecommendedSystem}
              />
            )}
          />
          <Route
            path="/standard-solution"
            exact
            render={() => (
              <StandardSolution
                loggedIn={loggedIn}
                setSelectedPlan={setSelectedPlan}
                setAppliances={setAppliances}
                setRecommendedSystem={setRecommendedSystem}
                setPricebook={setPricebook}
                setBasicRecommended={setBasicRecommended}
                setLoading={setLoading}
                customFeedback={customFeedback}
                setCustomFeedback={setCustomFeedback}
              />
            )}
          />
          <ProtectedRoute
            path="/custom-preview"
            exact
            auth={selectedPlan}
            Comp={CustomPreview}
            redirectUrl="/"
            recommendedSystem={recommendedSystem}
            setRecommendedSystem={setRecommendedSystem}
            customFeedback={customFeedback}
            setCustomFeedback={setCustomFeedback}
            appliances={appliances}
            setAppliances={setAppliances}
            pricebook={pricebook}
            selectedPlan={selectedPlan}
            basicRecommended={basicRecommended}
            page={1}
            loggedIn={loggedIn}
          />
          <ProtectedRoute
            path="/standard-preview"
            exact
            auth={
              selectedPlan && selectedPlan !== "CUSTOM" && recommendedSystem
            }
            Comp={StandardPreview}
            redirectUrl="/standard-solution"
            selectedPlan={selectedPlan}
            recommendedSystem={recommendedSystem}
            setRecommendedSystem={setRecommendedSystem}
            setCustomFeedback={setCustomFeedback}
            setPricebook={setPricebook}
            pricebook={pricebook}
            appliances={appliances}
            setAppliances={setAppliances}
            customFeedback={customFeedback}
            basicRecommended={basicRecommended}
            setBasicRecommended={setBasicRecommended}
            page={1}
            loggedIn={loggedIn}
            setLoading={setLoading}
          />
          <ProtectedRoute
            path="/select-financing"
            exact
            auth={selectedPlan && recommendedSystem}
            Comp={SelectFinancing}
            redirectUrl="/"
            selectedPlan={selectedPlan}
            recommendedSystem={recommendedSystem}
            selectedFinance={selectedFinance}
            setSelectedFinance={setSelectedFinance}
            setLoading={setLoading}
            notify={notify}
            loggedIn={loggedIn}
          />
          <ProtectedRoute
            path="/custom-preview2"
            exact
            auth={selectedPlan && selectedFinance}
            Comp={CustomPreview}
            redirectUrl="/"
            loggedIn={loggedIn}
            selectedPlan={selectedPlan}
            customFeedback={customFeedback}
            setCustomFeedback={setCustomFeedback}
            recommendedSystem={recommendedSystem}
            setRecommendedSystem={setRecommendedSystem}
            selectedFinance={selectedFinance}
            setSelectedFinance={setSelectedFinance}
            appliances={appliances}
            setAppliances={setAppliances}
            pricebook={pricebook}
            basicRecommended={basicRecommended}
            page={2}
            notify={notify}
            setLoading={setLoading}
            refreshPage={refreshPage}
          />
          <ProtectedRoute
            path="/standard-preview2"
            exact
            auth={selectedPlan && selectedPlan !== "CUSTOM" && selectedFinance}
            Comp={StandardPreview}
            redirectUrl="/standard-solution"
            loggedIn={loggedIn}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            recommendedSystem={recommendedSystem}
            setRecommendedSystem={setRecommendedSystem}
            selectedFinance={selectedFinance}
            setSelectedFinance={setSelectedFinance}
            pricebook={pricebook}
            setPricebook={setPricebook}
            appliances={appliances}
            setAppliances={setAppliances}
            customFeedback={customFeedback}
            setCustomFeedback={setCustomFeedback}
            basicRecommended={basicRecommended}
            setBasicRecommended={setBasicRecommended}
            page={2}
            notify={notify}
            setLoading={setLoading}
            refreshPage={refreshPage}
          />
          <ProtectedRoute
            path="/register"
            exact
            auth={selectedPlan && recommendedSystem && selectedFinance}
            Comp={Register}
            redirectUrl="/"
            selectedPlan={selectedPlan}
            selectedFinance={selectedFinance}
            customFeedback={customFeedback}
            recommendedSystem={recommendedSystem}
            appliances={appliances}
            setLoading={setLoading}
            notify={notify}
          />
          <Route
            path="/account-verification/:token"
            exact
            render={props => (
              <AccountVerification
                {...props}
                notify={notify}
                setLoading={setLoading}
                logUserIn={logUserIn}
                logSalesRepIn={logSalesRepIn}
              />
            )}
          />
          <Route
            path="/login"
            exact
            render={props => (
              <Login
                {...props}
                role="Client"
                logUserIn={logUserIn}
                logAdminIn={logAdminIn}
                logSalesRepIn={logSalesRepIn}
                logOut={logOut}
                setLoading={setLoading}
                notify={notify}
              />
            )}
          />
          <Route
            path="/forgot-password"
            exact
            render={props => (
              <ForgotPassword
                {...props}
                role="Client"
                notify={notify}
                setLoading={setLoading}
              />
            )}
          />
          <Route
            path="/reset-password/:token"
            exact
            render={props => (
              <ResetPassword
                {...props}
                role="Client"
                notify={notify}
                setLoading={setLoading}
              />
            )}
          />
          <ProtectedRoute
            path="/landing-dashboard"
            exact
            auth={loggedIn}
            Comp={LandingDashboard}
            redirectUrl="/login"
            userDetails={userObj}
            setUserDetails={setUserObj}
            logOut={logOut}
            setSelectedPlan={setSelectedPlan}
            setCustomFeedback={setCustomFeedback}
            setRecommendedSystem={setRecommendedSystem}
            setSelectedFinance={setSelectedFinance}
            setAppliances={setAppliances}
            refreshPage={refreshPage}
            notify={notify}
            setLoading={setLoading}
          />
          <ProtectedRoute
            path="/dashboard"
            exact
            auth={loggedIn}
            Comp={CustomerDashboard}
            redirectUrl="/login"
            userDetails={userObj}
            setUserDetails={setUserObj}
            logOut={logOut}
            setRecommendedSystem={setRecommendedSystem}
            setSelectedFinance={setSelectedFinance}
            setAppliances={setAppliances}
            refreshPage={refreshPage}
            notify={notify}
            setLoading={setLoading}
          />
          <Route
            path="/admin"
            exact
            render={props => (
              <Login
                {...props}
                role="Admin"
                logAdminIn={logAdminIn}
                setLoading={setLoading}
                notify={notify}
              />
            )}
          />
          <ProtectedRoute
            path="/admin/:adminPage"
            exact
            auth={adminLoggedIn}
            Comp={AdminDashboard}
            redirectUrl="/admin"
            adminDetails={adminObj}
            logOut={logAdminOut}
            setLoading={setLoading}
            refreshPage={refreshPage}
            notify={notify}
          />
          <Route
            path="/agent"
            exact
            render={props => (
              <Login
                {...props}
                role="Agent"
                logAgentIn={logAgentIn}
                setLoading={setLoading}
                notify={notify}
              />
            )}
          />
          <Route
            path="/agent/forgot-password"
            exact
            render={props => (
              <ForgotPassword
                {...props}
                role="Agent"
                notify={notify}
                setLoading={setLoading}
              />
            )}
          />
          <Route
            path="/agent/reset-password/:token"
            exact
            render={props => (
              <ResetPassword
                {...props}
                role="Agent"
                notify={notify}
                setLoading={setLoading}
              />
            )}
          />
          <Route
            path="/agent/signup"
            exact
            render={props => (
              <AgentSignUp
                {...props}
                exact
                auth={selectedPlan && recommendedSystem && selectedFinance}
                Comp={Register}
                redirectUrl="/"
                selectedPlan={selectedPlan}
                selectedFinance={selectedFinance}
                customFeedback={customFeedback}
                recommendedSystem={recommendedSystem}
                appliances={appliances}
                setLoading={setLoading}
                notify={notify}
              />
            )}
          />
          <Route
            path="/agent/account-verification/:token"
            exact
            render={props => (
              <AccountVerification
                {...props}
                notify={notify}
                setLoading={setLoading}
                logAgentIn={logAgentIn}
                role="Agent"
              />
            )}
          />
          <ProtectedRoute
            path="/agent/:agentPage"
            exact
            auth={agentLoggedIn}
            Comp={AgentDashboard}
            redirectUrl="/agent"
            logOut={logAgentOut}
            setLoading={setLoading}
            refreshPage={refreshPage}
            notify={notify}
          />
          <ProtectedRoute
            path="/sales/:salesRepPage"
            exact
            auth={salesRepLoggedIn}
            Comp={SalesRepDashboard}
            redirectUrl="/login"
            logOut={logSalesRepOut}
            setLoading={setLoading}
            refreshPage={refreshPage}
            notify={notify}
          />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
