import React, { useEffect } from "react";

const SalesRepProfile = ({ selectedSalesRep }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className="customer-info">
        <div className="landing-dashboard-user-personal">
          <div
            className="landing-dashboard-user-img"
            style={{
              backgroundImage: `url(${selectedSalesRep.passport})`,
              backgroundSize: "cover"
            }}
          />
          <div className="landing-dashboard-user-info">
            <h3 className="landing-dashboard-user-name">
              {`${selectedSalesRep.firstname} ${selectedSalesRep.lastname}`}
            </h3>
            <p className="landing-dashboard-user-email">
              {selectedSalesRep.email}
            </p>
          </div>
        </div>
        <h4 className="customer-info-setion-header">Sales Rep Info</h4>
        <div className="agent-info-wrapper">
          <p className="agent-info-item">
            <span className="agent-info-item-label">Registration Date: </span>
            {selectedSalesRep.date}
          </p>
        </div>
      </div>
    </>
  );
};

export default SalesRepProfile;
