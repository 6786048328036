import React, { useState } from "react";
import lock from "../../images/lock.png";
import loadingGif from "../../images/loading.gif";

const CreditCheck = ({ setConfirmationOpen, notify, userId }) => {
  const [inputvalue, setInputValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("DEFAULT");

  const onCreditCheck = () => {
    setLoading(true);
    if (Number.isNaN(Number(inputvalue)) || inputvalue === "") {
      notify("error", "Enter a bvn valid format");
      setLoading(false);
    } else if (dateValue === "") {
      notify("error", "Enter your date of birth");
      setLoading(false);
    } else {
      fetch(" https://icevolt-credit-check.herokuapp.com/dashboard", {
        method: "post",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify({
          bvn: inputvalue
        })
      })
        .then(res => res.json())
        .then(data => {
          let status = "NONE";
          if (data.Score) {
            notify("success", "status found");
            if (data.Score >= 300 && data.Score < 630) {
              status = "HIGH";
            } else if (data.Score >= 630 && data.Score < 690) {
              status = "FAIR";
            } else if (data.Score >= 690 && data.Score < 720) {
              status = "MEDIUM";
            } else if (data.Score > 720) {
              status = "LOW";
            }
          } else {
            notify("warn", "No credit record found");
            status = "UNKNOWN";
          }

          fetch(`${process.env.REACT_APP_API_URL}/user/updateCreditStatus`, {
            method: "put",
            headers: { "content-Type": "application/json" },
            body: JSON.stringify({
              userId,
              status,
              dob: dateValue,
              data
            })
          })
            .then(res => res.json())
            .then(resData => {
              setLoading(false);
              if (resData.success) {
                notify("success", resData.message);
                setPage(status);

                window.gtag("event", "completed_credit_check", {
                  event_category: "credit_check"
                });
              } else {
                notify("error", resData.message);
              }
            })
            .catch(() => {
              setLoading(false);
              notify("error", "Unable to update Credit Status.");
            });
        })
        .catch(() => {
          setLoading(false);
          notify("error", "Unable to run credit check");
        });
    }
  };

  return (
    <div className="advance-customer">
      <div
        className="advance-customer-background"
        onClick={() => setConfirmationOpen(false)}
      />
      {page === "DEFAULT" && (
        <div className="content">
          <h4>Please Input your details to run a credit check</h4>
          <h4>Date Of Birth</h4>
          <input
            type="date"
            value={dateValue}
            onChange={e => {
              setDateValue(e.target.value);
            }}
          />
          <h4>BVN</h4>
          <input
            type="text"
            value={inputvalue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
          />
          <img src={lock} alt="" />
          <span>Your details are secured and encrypted</span>
          <br />
          <br />
          <button
            type="button"
            onClick={() => {
              onCreditCheck(inputvalue);
            }}
          >
            {loading ? (
              <img style={{ width: "20px" }} src={loadingGif} alt="" />
            ) : (
              "Run Credit Check"
            )}
          </button>
        </div>
      )}
      {page === "LOW" && (
        <div className="content">
          <h2>Congratulations!</h2>
          <p>
            You qualify to get our financing solution. Please book a meeting
            with one of our correspondents for further guidance.
          </p>
          <button
            type="button"
            style={{ background: "#00FF00" }}
            onClick={() => setConfirmationOpen(false)}
          >
            Continue
          </button>
        </div>
      )}

      {page === "MEDIUM" && (
        <div className="content">
          <h2>You're almost there!</h2>
          <p>
            Book a meeting with one of our correspondents to gain more insights
            on how to qualify for our financing solutions.
          </p>
          <a
            href="http://icevolt.ca/book-free-consultation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              style={{ background: "#DAF034" }}
              onClick={() => setConfirmationOpen(false)}
            >
              Continue
            </button>
          </a>
        </div>
      )}

      {page === "FAIR" && (
        <div className="content">
          <h2>You're almost there!</h2>
          <p>
            Book a meeting with one of our correspondents to gain more insights
            on how to qualify for our financing solutions.
          </p>
          <a
            href="http://icevolt.ca/book-free-consultation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              style={{ background: "#FFCA42" }}
              onClick={() => setConfirmationOpen(false)}
            >
              Continue
            </button>
          </a>
        </div>
      )}

      {page === "HIGH" && (
        <div className="content">
          <h2>We're Sorry!</h2>
          <p>
            You do not qualify to get our financing solution right now. Please
            book a meeting to get more insights on how to qualify.
          </p>
          <a
            href="http://icevolt.ca/book-free-consultation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              style={{ background: "#FF0000" }}
              onClick={() => setConfirmationOpen(false)}
            >
              Continue
            </button>
          </a>
        </div>
      )}

      {page === "UNKNOWN" && (
        <div className="content">
          <h2>We're Sorry!</h2>
          <p>
            You do not have any credit record. Please book a meeting to get more
            insights on how to qualify to get our financing solution.
          </p>
          <a
            href="http://icevolt.ca/book-free-consultation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              style={{ background: "#0000CC" }}
              onClick={() => setConfirmationOpen(false)}
            >
              Continue
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default CreditCheck;
