import React, { useState, useEffect } from "react";
import vector1 from "../../images/Ellipse36.png";
import noteIcon from "../../images/note.png";
import UserDetails from "../../Components/UserDetails/UserDetails";
import { leads, prospects, audits, paying } from "../AdminDashboard/states";
import UserNote from "../../Components/UserNote/UserNote";

const Overview = ({
  salesRepDetails,
  allClients,
  setLoading,
  notify,
  refreshPage
}) => {
  const [state, setState] = useState(leads);
  const [page, setPage] = useState("ANALYTICS");
  const [selectedUser, setSelectedUser] = useState({});
  const [userNote, setUserNote] = useState("");

  const statusIndicator = {
    NONE: "#CCCCCC",
    LOW: "#00FF00",
    MEDIUM: "#DAF034",
    FAIR: "#FFCA42",
    HIGH: "#FF0000",
    UNKNOWN: "#0000CC"
  };

  const handleLeads = () => {
    setState(leads);
  };
  const handleProspects = () => {
    setState(prospects);
  };
  const handleAudits = () => {
    setState(audits);
  };
  const handlePaying = () => {
    setState(paying);
  };

  const onSelectUser = user => {
    setSelectedUser(user);
    setPage("USERDETAILS");
  };

  const onClickNote = user => {
    setSelectedUser(user);
    setUserNote(user.user_note || "Enter a note about this user");
  };

  const onSaveUserNote = (userId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/addUserNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return page === "USERDETAILS" ? (
    <UserDetails
      setLoading={setLoading}
      selectedUser={selectedUser}
      notify={notify}
      setPage={setPage}
      refreshPage={refreshPage}
      basePage="Dashboard"
      role="Agent"
    />
  ) : (
    <div className="analytics">
      {userNote && (
        <UserNote
          setUserNote={setUserNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedUser.user_id}
          defaultNote={userNote}
        />
      )}
      <h4>Analytics</h4>
      <div className="analytics-visitors">
        <div className="single">
          <img src={vector1} alt="" />
          <div style={{ marginLeft: "20px" }}>
            <span className="title">
              {allClients.length}
              &nbsp;Total Clients
            </span>
          </div>
        </div>
        <div className="single">
          <div>
            Sales Rep Code:&nbsp;
            <span className="title">{salesRepDetails.id.split("-")[0]}</span>
          </div>
        </div>
      </div>
      <div className="leads-prospects" style={{ padding: "10px" }}>
        <span
          onClick={handleLeads}
          className={`${state[0] ? "active" : "disabled"}`}
        >
          Leads
        </span>
        <span
          onClick={handleProspects}
          className={`${state[1] ? "active" : "disabled"}`}
        >
          Prospects
        </span>
        <span
          onClick={handleAudits}
          className={`${state[2] ? "active" : "disabled"}`}
        >
          Prospects for Site Audit
        </span>
        <span
          onClick={handlePaying}
          className={`${state[3] ? "active" : "disabled"}`}
        >
          Paying Customers
        </span>
      </div>
      {state[0] && (
        <div className="analytics-table-card">
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allClients.map(
                  user =>
                    user.type === "Lead" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {state[1] && (
        <div className="analytics-table-card">
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allClients.map(
                  user =>
                    user.type === "Prospect" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {state[2] && (
        <div className="analytics-table-card">
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Advance</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allClients.map(
                  user =>
                    user.type === "Audit" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {state[3] && (
        <div className="analytics-table-card">
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Credit Status</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allClients.map(
                  user =>
                    user.type === "Paying" && (
                      <tr key={user.user_id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectUser(user)}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          {new Date(user.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              display: "inline-block",
                              background: `${
                                statusIndicator[user.credit_status]
                              }`,
                              width: "50px",
                              height: "30px",
                              borderRadius: "5px"
                            }}
                          />
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(user)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
