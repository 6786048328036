/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import logo from "../../images/logo.jpg";

const AgentProfileContract = ({ selectedAgent }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="AccountVerificationPage">
      <img
        src={logo}
        alt=""
        style={{ height: "150px", width: "100%", objectFit: "contain" }}
      />
      <hr />
      <h2>
        <b>
          Independent Agent Terms and Conditions for IceVolt Standard and Custom
          Plans
        </b>
      </h2>
      <h4 style={{ color: "darkblue" }}>
        <b>IceVolt Green Energy Services</b>
      </h4>
      <p>
        1 Mife Road, Eliada Estate, Rumuogba Estate,
        <br />
        Rumuogba, Port Harcourt, Rivers State
      </p>
      <p>
        <b>Current Version</b>
        : V1
        <br />
        <b>Date of Version</b>
        :&nbsp;26th June, 2022
      </p>
      <hr />
      <h4 style={{ color: "darkblue" }}>Executive Summary</h4>
      <p>
        IceVolt is a Clean Energy Services company dedicated to providing
        reliable, clean, affordable residential and light commercial use power
        solutions in Africa. Our mission is to innovate, design, manufacture,
        install, service and finance any scale of solar/renewable energy mix for
        households and businesses in our drive towards a decentralized clean
        energy Africa.
        <br />
        <br />
        We are excited to learn about your interest in marketing our products
        and services to your customers. The Product package comprises a Solar
        Panel array, Inverter Units, Power Control Equipment, Energy Storage
        Devices, Thermal Storage Air Conditioning and Water Heating systems. Our
        power contracts offer customers payment plans from 1 to 10 years.
      </p>
      <h4 style={{ color: "darkblue" }}>Agent Relationship Summary</h4>
      <p>
        The Agent &nbsp;
        <b>{`${selectedAgent.firstname} ${selectedAgent.lastname}`} </b>
        is an independent contractor wishing to enter into a business
        relationship with IceVolt as a sales agent, referred to as “
        <b>the Agent”</b> in the remainder of this agreement. The agent will
        market IceVolt Standard and Custom plans to their client base and have
        access to an Agent Portal provided by IceVolt. The compensation model
        for the contract relationship is commission based with a bonus schedule
        detailed below. The target bonus rate is 5% of sales value. Below is the
        anticipated customer journey with IceVolt.
        <br />
        <br />
        <b>Customer Journey</b>: The customer is an IceVolt client and signs up
        for a power contact directly with IceVolt. The agent is earning
        commission for customer acquisition and onboarding. No contractual
        relationship exists between the agent and customer beyond the initial
        onboarding. Through the Agent Portal on the IceVolt platform the
        verified agent will be able to execute the following workflow.
      </p>
      <ul>
        <li>
          Initiate and send an introductory email to interested homeowners and
          small business entrepreneurs within their sales network. The
          introductory email will invite the sale leads to log into the IceVolt
          Customer Portal to provide us their contact details and sign up for a
          product. The agent is expected to remain available to guide their
          sales leads through the product discovery process.
        </li>
        <li>
          The sales leads will access our load selector web application and
          choose their desired solution (we will be available to assist any who
          need help navigating the web application). Our Green Energy
          representative will go over each subscriber selection with them.
        </li>
        <li>
          Each subscriber will initiate a credit check on their personalized
          dashboard (created when they placed order on the web application). The
          application will be reviewed by IceVolt and <b>leads</b> advanced
          to&nbsp;
          <b>prospects</b>
          &nbsp; on approval of the application. The sales agent will be
          notified.
        </li>
        <li>
          We will then schedule a site visit following which we will clarify
          their requirements, finalize the quote with them, and sign the
          necessary paperwork / contract.
        </li>
        <li>
          Each subscriber may then pay a reservation fee to confirm their order
          and book an installation date.
        </li>
        <li>
          An installation date will be agreed and the green energy equipment
          will be installed
        </li>
        <li>
          At the end of every month, we will generate an invoice for each
          subscriber. Once payment is made, we will credit each subscriber
          account. The agent bonus schedule is tied to the subscriber being up
          to date on their payments.
        </li>
      </ul>
      <p>
        <b>Contract Duration and Extension</b>: This contract is valid for an
        initial duration of five years. The contract will be reviewed yearly and
        at the end of your initial contract period you will be eligible for a
        contract extension based on a review of your performance. The contract
        extension will not exceed an initial period of five years. The key
        performance criteria will be your contract closure rate and bonus
        achieved.
      </p>
      <ul>
        <li>
          <b>Contract Start Date</b>:
          ............................................
        </li>
        <br />
        <li>
          <b>Contract End Date</b>:
          ................................................
        </li>
      </ul>
      <p>
        <b>Discretionary Bonus and Commission</b>: There is no discretionary
        annual performance bonus payment tied to this role. The bonus structure
        is an
        <b>Agent Sales Bonus Scheme</b> that is specified above at a target of
        Power Contract sales. A high performing Agent can be expected to close
        ten (10) deals per week.
      </p>
      <p>
        <b>Bonus Schedule</b>: The 5% target bonus rate attached to this role is
        paid following the schedule below
      </p>
      <ul>
        <li>
          50% on Installation of Services and customer payment of Reservation
          Fee and first month of Power Bill.
        </li>
        <li>
          25% after the customer has completed 50% of the term of their power
          contract, and bills up to date.
        </li>
        <li>
          25% at the end of the power contract and transfer of full equity in
          the green energy equipment and installation to the customer.
        </li>
      </ul>
      <p>
        <b>At-Will Engagement</b>: Your agent status with the Company is "at
        will," and thus you or the Company may terminate our relationship with
        advance notice, with or without cause. The required notice period is one
        month. The Company reserves the right, in its sole discretion, to change
        your compensation and/or agent benefits at any time on a prospective
        basis.
      </p>
      <p>
        <b>Additional Agreements</b>: As a condition of your agent designation,
        you agree to execute any additional agreements required by the Company
        at the start of your agent contract. This includes any agreements that
        relate to your confidentiality or intellectual property assignment
        obligations to the Company.
      </p>
      <p>
        <b>Contingencies</b>: This offer is contingent upon the successful
        completion of any background or reference checks requested by the
        Company. You will be required to provide to the Company documentary
        evidence of your identity and eligibility for work in Nigeria.
      </p>
      <p>
        <b>Entire Agreement</b>: Endorsing this Agent agreement, along with the
        Non Non Disclosure Agreement, sets forth the terms and conditions of
        your engagement with the Company, and supersedes any prior
        representations or agreements concerning your engagement with the
        Company, whether written or oral. You acknowledge and agree that you are
        not relying on any statements or representations concerning the Company
        or your Agent Agreement with the Company except those made in this
        agreement. This Agent agreement may not be modified or amended except by
        a written agreement signed by you and an authorized officer of the
        Company.
      </p>
      <br />
      <br />
      <h4 style={{ color: "darkblue" }}>Non Disclosure Agreement</h4>
      <p>
        This Non-Disclosure Agreement is entered into on the …… day of ……..,
        20….. between <b>IceVolt</b> Green Energy Services Limited, a Limited
        Liability company incorporated under the Federal laws of Nigeria, and
        also the governing laws of Alberta Province of Canada, and
        <b>the Agent</b>, an independent contractor identified by name on page 1
        of this agreement. Disclosing Party possesses certain ideas and
        information relating to its business activities that is confidential and
        proprietary to the Disclosing Party (hereinafter "Confidential
        Information"); and WHEREAS both parties are willing to receive and
        disclose Confidential Information pursuant to the terms of this
        Non-Disclosure Agreement for the purpose of exploring new opportunities
        and examining how to work together for a mutual benefit; NOW THEREFORE,
        in consideration for the mutual undertakings of both parties under this
        agreement which becomes effective as of the later two signature dates
        set forth below, and the parties agree to the below terms as follows :
      </p>
      <h5>1. Definitions </h5>
      <ol type="a">
        <li>
          Confidential Information” means all non-public confidential
          information related to the business of the Disclosing Party designated
          as confidential at the time of disclosure or that, based on the nature
          of the information or circumstances surrounding its disclosure, the
          Receiving Party should in good faith treat it as confidential.
          Confidential information includes without limitation, description of
          new business idea(s), technical and business information relating to
          proprietary ideas and inventions, ideas(patentable or not), trade
          secrets, drawings and/or illustrations, existing and/or contemplated
          products and services, research and development, costs, strategies and
          processes, profit and margin information, finances and financial
          projections, customers, clients, marketing, and current or future
          business plans and models, regardless of whether such information is
          designated as “Confidential Information” at the time of its
          disclosure. For the avoidance of doubt, ICEVOLT Confidential
          Information includes, without limitation, ICEVOLT consulting
          procedures as well as client specific information provided with
          permission.
        </li>
        <li>
          "Disclosing Party" means (i)Icevolt when Icevolt's Confidential
          Information is disclosed to …….. and/or (ii) the Agent when the
          Agent's Confidential Information is disclosed to Icevolt.
        </li>
        <li>
          “Receiving Party” means (i) the Agent (when the Agent is receiving
          IceVolt's Confidential Information), and/or (ii) Icevolt (when IceVolt
          is receiving the Agent's Confidential Information).
        </li>
      </ol>
      <h5>2. Exclusions from Confidential Information</h5>
      <p>
        Confidential Information does not include information that: (a) was
        generally known to the public at the time disclosed to the Receiving
        Party (b) became generally known to the public other than through a
        breach of this Non-Disclosure Agreement by the Receiving Party after the
        time of disclosure to the Receiving Party; (c) was in the Receiving
        Party possession free of any obligation of confidentiality at the time
        of disclosure to the Receiving Party; (d) was rightfully received by the
        Receiving Party from a third party that was free of any obligation of
        confidentiality after disclosure by or for the Receiving Party to the
        Receiving Party; or (e) was independently developed by the Receiving
        Party without reference to or use of Confidential Information.{" "}
      </p>
      <h5>3. Receiving Party Obligations</h5>
      <ol type="a">
        <li>
          <p>
            Use and Disclosure of Confidential Information: The Receiving Party
          </p>
          <ol type="i">
            <li>
              will not use any of the Disclosing Party's confidential
              information except in evaluating and negotiating a possible
              business arrangement with the Disclosing Party, or in furtherance
              of the Receiving Party's obligations
            </li>
            <li>
              will not disclose, give access to , or distribute any of the
              Disclosing Party's Confidential Information to any third party
              except to the extent expressly authorized in a separate written
              agreement signed by both parties and
            </li>
            <li>
              will take reasonable security precautions (and at least as
              protective as the precautions it takes to preserve its own
              Confidential Information of a similar nature) to keep the
              Disclosing Party's information confidential.
            </li>
          </ol>
        </li>
        <li>
          <p>
            Return of Materials: Immediately upon request by the Disclosing
            Party at any time, and when it is no longer needed for a purpose
            described in Section 3(a)(i), the Receiving Party will return to the
            Disclosing Party all documents or media containing the Disclosing
            Party’s Confidential Information. The Receiving Party understands
            that nothing in this Non-Disclosure Agreement
          </p>
          <ol type="i">
            <li>requires the disclosure of any Confidential Information or </li>
            <li>
              requires the Disclosing Party to proceed with any transaction or
              relationship.
            </li>
          </ol>
        </li>
      </ol>
      <h5>4. Intellectual Property</h5>
      <p>
        The Disclosing Party retains all of its rights, title, and interest in
        and to its Confidential Information. The disclosure of Confidential
        Information to the Receiving Party under this Non-Disclosure Agreement
        does not constitute a grant by the Disclosing Party of any rights or
        license under any of the Disclosing Party's (or any third party's)
        patents, copyrights, or trademarks, including without limitation by
        implication, estoppel, or otherwise.
      </p>
      <h5>5. Disclosures Required by Law or Rule </h5>
      <p>
        The Receiving Party may disclose Confidential Information as required to
        comply with binding orders of governmental entities that have
        jurisdiction over it or as otherwise required by law. In such cases,
        however, the Receiving Party will:
      </p>
      <ol type="a">
        <li>
          give the Disclosing Party prior notice of such disclosure so as to
          afford the Disclosing Party a reasonable opportunity to appear,
          object, and obtain a protective order or other appropriate relief
          regarding such disclosure (if such notice is not prohibited by
          applicable law);
        </li>
        <li>
          use diligent efforts to limit disclosure and to obtain confidential
          treatment or a protective order; and
        </li>
        <li>allow the Disclosing Party to participate in the proceeding.</li>
      </ol>
      <h5>6. Injunctive and Equitable Relief </h5>
      <p>
        The Receiving Party acknowledges and agrees that monetary damages would
        not be a sufficient remedy for unauthorized disclosure or use of
        Disclosing Party's Confidential Information, and such unauthorized
        disclosure or use would cause the Disclosing Party immediate and
        irreparable injury. In such cases, the Disclosing Party will be
        entitled, without waiving or prejudicing any other rights or remedies,
        to injunctive or equitable relief.
      </p>
      <h5>7. General</h5>
      <ol type="a">
        <li>
          The rights and remedies under this Non-Disclosure Agreement are
          cumulative and not exclusive of any rights and remedies available at
          law in equity or relating to any other agreement between the parties.
        </li>
        <li>
          No waiver of any provision of this Non-Disclosure Agreement will be
          effective unless it is in writing and signed by the party making that
          waiver, and no such waiver will constitute a waiver of any other
          provisions or of the same provision on another occasion.
        </li>
        <li>
          This Non-Disclosure Agreement will be governed by and construed in
          accordance with the laws of the Federal Republic of Nigeria. In any
          action to enforce any right or remedy under this Non-Disclosure
          Agreement or to interpret any provision of this Non-Disclosure
          Agreement, the prevailing party will be entitled to recover its costs,
          including attorneys' fees. The courts located in Nigeria, will have
          jurisdiction to adjudicate disputes arising under, in connection with,
          or incident to this Non-Disclosure Agreement or concerning its
          interpretation, and each party irrevocably consents to the exercise of
          jurisdiction by said courts.
        </li>
        <li>
          All notices, request and consents under or authorized by this
          Non-Disclosure Agreement shall be in writing and sent to the
          applicable address set forth in the first paragraph above, and will be
          effective when received by personal delivery, by next-business-day
          delivery service with delivery tracking, or by registered or certified
          mail with return receipt requested. Either party may change its notice
          address by providing notice of that change pursuant to this Section
          7(e).
        </li>
        <li>
          If a court finds any of the provisions of this Non-Disclosure
          Agreement to be unenforceable, the remainder shall be enforced as
          fully as possible and the unenforceable provision(s) shall be deemed
          modified to the limited extent required to permit enforcement of the
          Non-Disclosure Agreement as a whole.
        </li>
        <li>
          The Parties agree that it shall process all data received in
          furtherance of this Agreement in accordance with applicable Data
          Protection Legislations, including the Nigeria Data Protection Act.
        </li>
        <li>
          Nothing contained in this Agreement shall be construed as committing
          either of the Parties to negotiate or conclude any other agreement in
          connection with their collaboration.
        </li>
        <li>
          Neither of the Party shall assign, license nor otherwise transfer its
          rights or obligations under this Agreement to any third party without
          the other Party's written consent, which is not to be unreasonably
          withheld.
        </li>
        <li>
          This Non-Disclosure Agreement is not effective until signed by both
          parties. This Non-Disclosure Agreement constitutes the entire
          agreement between the parties with respect to Confidential Information
          and its other subject matter and supersedes all prior and
          contemporaneous communications and proposals, whether electronic, oral
          or written, between the parties with respect to all such subject
          matter. This Non-Disclosure Agreement may not be modified except by a
          separate written agreement dated after the date of this Non-Disclosure
          Agreement and signed by duly authorized representatives of both
          parties.
        </li>
      </ol>
      <p>
        WHEREFORE, both parties acknowledge that they have read and understand
        this Non-Disclosure Agreement and voluntarily accept the duties and
        obligations set forth herein.
      </p>
    </div>
  );
};

export default AgentProfileContract;
