import React from "react";

function RightGrid({ setIntroPage }) {
  return (
    <div className="grid2">
      <div className="fp-reliable">
        <h1 className="fp-energy">Clean Reliable Energy</h1>
        <p
          onClick={() => {
            setIntroPage(false);
            sessionStorage.setItem("noIntroPage", true);
          }}
          className="intro-get-started"
        >
          Get Started
        </p>
      </div>
    </div>
  );
}

export default RightGrid;
