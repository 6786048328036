import React from "react";
import "./ThankYou.css";

const ThankYou = () => (
  <div className="thankyou-wrapper">
    <div className="thankyou-content">
      <h1>Thank You</h1>
      <p>Please check your email for confirmation details</p>
      <div>
        <a
          href="https://icevolt.ca/feedback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Drop a feedback/review
        </a>
        <a
          href="http://icevolt.ca/book-free-consultation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a meeting with our consultants
        </a>
      </div>
      <div className="login-link-wrapper">
        <a href="/login">Go to Login</a>
      </div>
    </div>
  </div>
);

export default ThankYou;
