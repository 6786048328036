import React, { useEffect, useState } from "react";

const Pricebook = ({ setLoading, notify }) => {
  const [basicPackage, setBasicPackage] = useState([]);
  const [silverPackage, setSilverPackage] = useState([]);
  const [goldPackage, setGoldPackage] = useState([]);
  const [platinumPackage, setPlatinumPackage] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/products/plansPackages`)
      .then(res => res.json())
      .then(data => {
        setBasicPackage(data.responseData[0].BASIC);
        setSilverPackage(data.responseData[1].SILVER);
        setGoldPackage(data.responseData[2].GOLD);
        setPlatinumPackage(data.responseData[3].PLATINUM);
        setLoading(false);
      })
      .catch(() => {
        notify("error", "Unable to fetch plan prices");
        setLoading(false);
      });
  }, []);

  return (
    <div className="ComponentPricing">
      <h3 className="productlist-text">Plan Prices</h3>
      <div style={{ overflowX: "auto" }}>
        <table className="productlist-table">
          <thead className="productlist-table-head">
            <tr>
              <th className="productlist-description-head">Description</th>
              <th>Product detail</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th className="productlist-totalprice-head">Total Price(NGN)</th>
            </tr>
          </thead>
          <tbody>
            {basicPackage.map((item, i) => (
              <tr key={item.name}>
                {i === 0 && (
                  <td className="productlist-description" rowSpan="12">
                    Basic Plan
                  </td>
                )}
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{Number(item.unit_price.toFixed(2)).toLocaleString()}</td>
                <td>{Number(item.total_price.toFixed(2)).toLocaleString()}</td>
              </tr>
            ))}
            {silverPackage.map((item, i) => (
              <tr key={item.name}>
                {i === 0 && (
                  <td className="productlist-description" rowSpan="15">
                    Silver Plan
                  </td>
                )}
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{Number(item.unit_price.toFixed(2)).toLocaleString()}</td>
                <td>{Number(item.total_price.toFixed(2)).toLocaleString()}</td>
              </tr>
            ))}
            {goldPackage.map((item, i) => (
              <tr key={item.name}>
                {i === 0 && (
                  <td className="productlist-description" rowSpan="15">
                    Gold Plan
                  </td>
                )}
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{Number(item.unit_price.toFixed(2)).toLocaleString()}</td>
                <td>{Number(item.total_price.toFixed(2)).toLocaleString()}</td>
              </tr>
            ))}
            {platinumPackage.map((item, i) => (
              <tr key={item.name}>
                {i === 0 && (
                  <td className="productlist-description" rowSpan="15">
                    platinumPackage Plan
                  </td>
                )}
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{Number(item.unit_price.toFixed(2)).toLocaleString()}</td>
                <td>{Number(item.total_price.toFixed(2)).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pricebook;
