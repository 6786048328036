import React, { useState, useEffect } from "react";
import vector1 from "../../images/Ellipse36.png";
import noteIcon from "../../images/note.png";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import UserNote from "../UserNote/UserNote";
import AddSalesRep from "./AddSalesRep";
import SalesRepDetails from "./SalesRepDetails";

const SalesRep = ({ setLoading, notify, refreshPage }) => {
  const [page, setPage] = useState("ANALYTICS");
  const [allUsers, setAllusers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [addSalesRep, setAddSalesRep] = useState(false);
  const [userNote, setUserNote] = useState("");

  const onSelectUser = user => {
    setSelectedUser(user);
    setPage("USERDETAILS");
  };

  const onClickDelete = user => {
    setSelectedUser(user);
    setDeleteCustomer(true);
  };

  const onClickNote = user => {
    setSelectedUser(user);
    setUserNote(user.note || "Enter a note about this Sales Rep");
  };

  const onDeleteUser = salesRepId => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/salesRep/delteSalesRep/${salesRepId}`,
      {
        method: "delete",
        headers: { "content-Type": "application/json" }
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);
          setTimeout(() => {
            refreshPage();
          }, 1500);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  const onSaveUserNote = (salesRepId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/salesRep/addSalesRepNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        salesRepId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/salesRep/salesReps`)
      .then(res => res.json())
      .then(data => {
        setPage("ANALYTICS");
        setAllusers(data.responseData);
        setLoading(false);
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  return page === "USERDETAILS" ? (
    <SalesRepDetails
      setLoading={setLoading}
      selectedAgent={selectedUser}
      notify={notify}
      setPage={setPage}
      refreshPage={refreshPage}
    />
  ) : (
    <div className="analytics">
      {deleteCustomer && (
        <DeleteConfirmation
          setDeleteCustomer={setDeleteCustomer}
          onDeleteUser={onDeleteUser}
          content="Are you sure you want to delete this sales rep"
          userId={selectedUser.id}
        />
      )}
      {userNote && (
        <UserNote
          setUserNote={setUserNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedUser.id}
          defaultNote={userNote}
        />
      )}
      <h4>Analytics</h4>
      <div className="analytics-visitors">
        <div className="single">
          <img src={vector1} alt="" />
          <div style={{ marginLeft: "20px" }}>
            <span className="title">
              {allUsers.length}
              &nbsp;Total Sales Rep
            </span>
          </div>
        </div>
        <div
          style={{
            color: "white",
            fontWeight: "500",
            fontSize: "14px",
            background: "#4C9DE8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "40px",
            width: "120px",
            cursor: "pointer"
          }}
          onClick={() => {
            setAddSalesRep(!addSalesRep);
          }}
        >
          {addSalesRep ? "Close" : " Add Sales Rep"}
        </div>
      </div>
      {addSalesRep && (
        <AddSalesRep
          setLoading={setLoading}
          notify={notify}
          refreshPage={refreshPage}
        />
      )}
      <div className="analytics-table-card">
        <div className="analytics-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Date</th>
                <th>Delete</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map(user => (
                <tr key={user.id}>
                  <td
                    style={{ cursor: "pointer", color: "gray" }}
                    onClick={() => onSelectUser(user)}
                  >
                    {`${user.firstname} ${user.lastname}`}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>{new Date(user.date).toLocaleDateString("en-GB")}</td>
                  <td>
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                        background: "#D20000",
                        display: "inline-block",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickDelete(user)}
                    >
                      Delete Sales Rep
                    </span>
                  </td>
                  <td>
                    <img
                      onClick={() => onClickNote(user)}
                      className="noteIcon"
                      src={noteIcon}
                      alt=""
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesRep;
