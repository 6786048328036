import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import AgentDetails from "../AgentDetails/AgentDetails";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import UserNote from "../UserNote/UserNote";
import noteIcon from "../../images/note.png";

const Agents = ({ setLoading, notify, refreshPage }) => {
  const [tab, setTab] = useState("unverified");
  const [page, setPage] = useState("AGENTS");
  const [allAgents, setAllAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [agentNote, setAgentNote] = useState("");

  const onChangeTab = selectedTab => {
    setTab(selectedTab);
  };

  const onSelectAgent = agent => {
    setSelectedAgent(agent);
    setPage("AGENTDETAILS");
  };

  const unverifiedCount = allAgents.filter(agent => !agent.verified).length;
  const verifiedCount = allAgents.filter(agent => agent.verified).length;

  const onClickDelete = agent => {
    setSelectedAgent(agent);
    setDeleteAgent(true);
  };

  const onDeleteAgent = id => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/agents/${id}`, {
      method: "delete",
      headers: { "content-Type": "application/json" }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          refreshPage();
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occurred");
      });
  };

  const onClickNote = agent => {
    setSelectedAgent(agent);
    setAgentNote(agent.agent_note || "Enter a note about this agent");
  };

  const onSaveUserNote = (agentId, note) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/addAgentNote`, {
      method: "put",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        agentId,
        note
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setLoading(false);
          notify("success", data.message);

          setTimeout(() => {
            refreshPage();
          }, 2000);
        } else {
          setLoading(false);
          notify("error", data.message);
        }
      })
      .catch(() => {
        notify("error", "An error occurred");
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/agent/agents`)
      .then(res => res.json())
      .then(data => {
        setAllAgents(data.responseData || []);
        setLoading(false);
      })
      .catch(() => {
        notify("warn", "network errors");
      });
  }, []);

  return page === "AGENTDETAILS" ? (
    <AgentDetails
      setLoading={setLoading}
      selectedAgent={selectedAgent}
      notify={notify}
      setPage={setPage}
      refreshPage={refreshPage}
    />
  ) : (
    <div className="analytics">
      {deleteAgent && (
        <DeleteConfirmation
          setDeleteCustomer={setDeleteAgent}
          onDeleteUser={onDeleteAgent}
          userId={selectedAgent.id}
          content="Are you sure you want to delete this agent?"
        />
      )}
      {agentNote && (
        <UserNote
          setUserNote={setAgentNote}
          onSaveUserNote={onSaveUserNote}
          userId={selectedAgent.id}
          defaultNote={agentNote}
        />
      )}
      <div className="barchart-container">
        <div style={{ position: "relative" }} className="bar-chart">
          <Chart
            width="100%"
            height="300px"
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={[
              ["category", "agents"],
              ["unverified", unverifiedCount],
              ["verified", verifiedCount]
            ]}
            options={{
              // Material design options
              chart: {
                title: "Icevolt Agents Category Model"
              }
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
        <div style={{ position: "relative" }} className="bar-chart">
          <Chart
            width="100%"
            height="300px"
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Task", "Hours per Day"],
              ["unverified", unverifiedCount],
              ["verified", verifiedCount],
              ["Middle Percentile", 0],
              ["Top Percentile", 0],
              ["Sleep", 0]
            ]}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>
      <div
        className="leads-prospects"
        style={{ padding: "10px", justifyContent: "space-around" }}
      >
        <span
          onClick={() => onChangeTab("unverified")}
          className={`${tab === "unverified" ? "active" : "disabled"}`}
        >
          Unverified
        </span>
        <span
          onClick={() => onChangeTab("verified")}
          className={`${tab === "verified" ? "active" : "disabled"}`}
        >
          Verified
        </span>
      </div>
      {tab === "unverified" && (
        <div className="analytics-table-card">
          <div className="analytics-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>Date</th>
                  <th>Delete</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {allAgents.map(
                  agent =>
                    !agent.verified && (
                      <tr key={agent.id}>
                        <td
                          style={{ cursor: "pointer", color: "gray" }}
                          onClick={() => onSelectAgent(agent)}
                        >
                          {`${agent.firstname} ${agent.lastname}`}
                        </td>
                        <td>{agent.email}</td>
                        <td>{agent.phone_number}</td>
                        <td>
                          {new Date(agent.date).toLocaleDateString("en-GB")}
                        </td>
                        <td>
                          <span
                            style={{
                              color: "white",
                              fontSize: "12px",
                              background: "#D20000",
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer"
                            }}
                            onClick={() => onClickDelete(agent)}
                          >
                            Delete Agent
                          </span>
                        </td>
                        <td>
                          <img
                            onClick={() => onClickNote(agent)}
                            className="noteIcon"
                            src={noteIcon}
                            alt=""
                          />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {tab === "verified" && (
        <div style={{ marginTop: "20px" }}>
          <div className="analytics-table-card">
            <div className="analytics-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Delete</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {allAgents.map(
                    agent =>
                      agent.verified && (
                        <tr key={agent.id}>
                          <td
                            style={{ cursor: "pointer", color: "gray" }}
                            onClick={() => onSelectAgent(agent)}
                          >
                            {`${agent.firstname} ${agent.lastname}`}
                          </td>
                          <td>{agent.email}</td>
                          <td>{agent.phone_number}</td>
                          <td>
                            {new Date(agent.date).toLocaleDateString("en-GB")}
                          </td>
                          <td>
                            <span
                              style={{
                                color: "white",
                                fontSize: "12px",
                                background: "#D20000",
                                display: "inline-block",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}
                              onClick={() => onClickDelete(agent)}
                            >
                              Delete Agent
                            </span>
                          </td>
                          <td>
                            <img
                              onClick={() => onClickNote(agent)}
                              className="noteIcon"
                              src={noteIcon}
                              alt=""
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Agents;
